import { useEffect, useState, useContext, forwardRef } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TableRow as MuiTableRow,
  Box,
} from "@mui/material";
import { AmqpContext } from "../../../../context/AmqpContext";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import TableHeader from "../TableHeader/TableHeader";
import TableRow from "../TableRow/TableRow";
import { rowFromMessage, handleFilter } from "../utils/tableUtils";
import { tableContainer, wrapperBox } from "./messageTableStyles";
import FilterOptions from "../FilterOptions/FilterOptions";
import { MAX_ENTRIES } from "../constants/tableConstants";
import { FilterCondition, FilterOption, RowData } from "../../../../types/MessageType";

type MessageTableProps = {
  onRowClick: (data: RowData) => void;
};

const VirtuosoTableComponents: TableComponents<RowData> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: props => (
    <Table {...props} size="small" />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <MuiTableRow {...props} />,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MessageTable = ({ onRowClick }: MessageTableProps) => {
  const [filterConditions, setFilterConditions] = useState<FilterCondition[]>([]);
  const [filteredCol, setFilteredCol] = useState("");
  const [filterOption, setFilterOption] = useState<FilterOption | string>(FilterOption.CONTAINS);
  const [filterText, setFilterText] = useState("");
  const { client, rows, setRows, pinned, setPinned } = useContext(AmqpContext);

  const handlePinClick = (row: RowData) => {
    if (pinned.find(v => v.msgId === row.msgId) === undefined) {
      setPinned([...pinned, row]);
      setRows(rows.filter(v => v.msgId !== row.msgId));
    } else {
      setPinned(pinned.filter(v => v.msgId !== row.msgId));
      const neighbor = rows.findIndex(v => v.msgId === row.msgId + 1);
      if (neighbor >= 0) {
        setRows([...rows.slice(0, neighbor), row, ...rows.slice(neighbor)]);
      }
    }
  };

  useEffect(() => {
    if (client !== undefined) {
      const listenerId = client.addListener((message) => {
        setRows((rows) => {
          const fromIndex = rows.length > MAX_ENTRIES ? 1 : 0;
          return [
            ...rows.slice(fromIndex),
            rowFromMessage(message, message._id, new Date(Date.now())),
          ];
        });
      });
      return () => {
        client?.removeListener(listenerId);
      };
    }
  }, [client, setRows]);

  return (
    <Box sx={wrapperBox}>
      <FilterOptions
        filterConditions={filterConditions}
        setFilterConditions={setFilterConditions}
        filteredCol={filteredCol}
        setFilteredCol={setFilteredCol}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        filterText={filterText}
        setFilterText={setFilterText}
      />
      <TableVirtuoso
        data={rows.filter(row => handleFilter(row, filterConditions)) ?? rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={TableHeader(pinned, handlePinClick, onRowClick)}
        itemContent={TableRow(handlePinClick, onRowClick)}
        style={tableContainer}
      />
    </Box>
  );
};

export default MessageTable;
