import React, { useState } from "react";
import { FormControl, IconButton, InputLabel, Select, MenuItem, Input, Box, useTheme, Tooltip, Button, Typography } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { COLUMN_INPUT_LABEL, FILTER_OPTIONS_LABEL, TABLE_COLUMNS } from "../constants/tableConstants";
import { selectInput, wrapperBox } from "./filterOptionStyles";
import { FilterCondition, FilterOption, LogicalOperations } from "../../../../types/MessageType";
import AddIcon from "@mui/icons-material/Add";
import Chips from "./Chips";

type FilterOptionsProps = {
    filterConditions: FilterCondition[];
    setFilterConditions: React.Dispatch<React.SetStateAction<FilterCondition[]>>;
    filteredCol: string;
    setFilteredCol: React.Dispatch<React.SetStateAction<string>>;
    filterOption: FilterOption | string;
    setFilterOption: React.Dispatch<React.SetStateAction<FilterOption | string>>;
    filterText: string;
    setFilterText: React.Dispatch<React.SetStateAction<string>>;
};

const FilterOptions = ({
    filterConditions,
    setFilterConditions,
    filteredCol,
    setFilteredCol,
    filterOption,
    setFilterOption,
    filterText,
    setFilterText,
}: FilterOptionsProps) => {
    const theme = useTheme();
    const [openFilters, setOpenFilters] = useState(false);
    const [logicalOperator, setLogicalOperator] = useState(LogicalOperations.AND);
    const [showLogicalOperators, setShowLogicalOperators] = useState(false);

    const addFilterCondition = () => {
        const isEmptyOption = filterOption === FilterOption.IS_EMPTY || filterOption === FilterOption.IS_NOT_EMPTY;

        if (filteredCol && filterOption && (isEmptyOption || filterText)) {
            setFilterConditions([
                ...filterConditions,
                {
                    column: filteredCol,
                    option: filterOption,
                    text: isEmptyOption ? "" : filterText,
                    logicalOperator
                },
            ]);

            setFilteredCol("");
            setFilterOption("");
            setFilterText("");
            setShowLogicalOperators(true);
        }
    };

    const removeFilterCondition = (index: number) => {
        const updatedConditions = [...filterConditions];
        updatedConditions.splice(index, 1);
        setFilterConditions(updatedConditions);
    };

    return (
        <React.Fragment>
            <Box sx={wrapperBox}>
                {showLogicalOperators && openFilters && filterConditions.length > 0 &&
                    <React.Fragment>
                        <Button
                            variant="outlined"
                            disabled={logicalOperator === LogicalOperations.AND}
                            onClick={() => setLogicalOperator(LogicalOperations.AND)}
                        >
                            <Tooltip title={"Combine two fiters with a logical AND operation"} arrow placement="top">
                                <Typography>{LogicalOperations.AND}</Typography>
                            </Tooltip>
                        </Button>

                        <Button
                            variant="outlined"
                            disabled={logicalOperator === LogicalOperations.OR}
                            onClick={() => setLogicalOperator(LogicalOperations.OR)}
                        >
                            <Tooltip title={"Combine two fiters with a logical OR operation"} arrow placement="top">
                                <Typography>{LogicalOperations.OR}</Typography>
                            </Tooltip>
                        </Button>
                    </React.Fragment>
                }

                <IconButton onClick={() => setOpenFilters(!openFilters)}>
                    <FilterListIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>

                {openFilters && (
                    <Box>
                        <FormControl sx={selectInput} size="small">
                            <InputLabel>{COLUMN_INPUT_LABEL}</InputLabel>
                            <Select
                                size="small"
                                label={COLUMN_INPUT_LABEL}
                                value={filteredCol}
                                onChange={e => setFilteredCol(e.target.value)}
                            >
                                {TABLE_COLUMNS?.map(column => (
                                    <MenuItem key={column.key} value={column.key}>
                                        {column.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={selectInput} size="small">
                            <InputLabel>{FILTER_OPTIONS_LABEL}</InputLabel>
                            <Select
                                size="small"
                                label={FILTER_OPTIONS_LABEL}
                                value={filterOption}
                                onChange={e => setFilterOption(e.target.value as FilterOption)}
                            >
                                {Object.values(FilterOption).map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {filteredCol && filterOption && (
                            <React.Fragment>
                                <Input
                                    sx={selectInput}
                                    placeholder="Filter value"
                                    disabled={
                                        filterOption === FilterOption.IS_EMPTY ||
                                        filterOption === FilterOption.IS_NOT_EMPTY
                                    }
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                />

                                <IconButton onClick={addFilterCondition} size="small">
                                    <AddIcon sx={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            </React.Fragment>
                        )}
                    </Box>
                )}
            </Box>

            {filterConditions.length > 0 && (
                <Chips
                    filterConditions={filterConditions}
                    onRemoveFilter={removeFilterCondition}
                    onClear={() => setFilterConditions([])}
                />
            )}
        </React.Fragment>
    );
};

export default FilterOptions;