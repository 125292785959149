import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { getAmqpClient } from "../../../services/AmqpClient";
import { AmqpContext } from "../../../context/AmqpContext";

const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [connectionError, setConnectionError] = useState<string>();
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const { client, setClient } = useContext(AmqpContext);

    const handleLoginClick = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!username || !password) {
            return;
        }

        try {
            const client = await getAmqpClient(username, password);
            setClient(client);
            setConnectionError(undefined);
            setWrongCredentials(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            setConnectionError(e.message);
            setWrongCredentials(true);
        }
    };

    const resetForm = async () => {
        setClient(undefined);
        setConnectionError(undefined);
        setWrongCredentials(false);
    };

    return (
        <>
            {connectionError === undefined ?
                <Dialog open={client === undefined} disableEscapeKeyDown>
                    <form onSubmit={handleLoginClick}>
                        <DialogTitle>Log In</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please log in with your AustriaTech C-Roads AMQP Broker credentials.
                            </DialogContentText>
                            <TextField
                                id="username"
                                label="User Name"
                                variant="standard"
                                fullWidth
                                onChange={e => setUsername(e.target.value)}
                            />
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                variant="standard"
                                fullWidth
                                onChange={e => setPassword(e.target.value)}
                            />
                            {wrongCredentials &&
                                <Typography variant="subtitle2" component="p" color={"#FF0000"} marginTop={.5}>
                                    Wrong credentials... Please try again.
                                </Typography>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleLoginClick} type="submit">Log In</Button>
                        </DialogActions>
                    </form>
                </Dialog>
                :
                <Dialog open={connectionError !== undefined} disableEscapeKeyDown>
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The web client encountered an error while connecting to the AustriaTech AMQP broker:
                            {connectionError}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLoginClick}>Retry</Button>
                        <Button onClick={resetForm}>Edit Credentials</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};


export default LoginForm;