import { createContext } from "react";
import { AmqpClient } from "../services/AmqpClient";
import { RowData } from "../types/MessageType";

export const AmqpContext = createContext<AMQPContext>({
    client: undefined,
    setClient: () => { },
    rows: [],
    setRows: () => { },
    pinned: [],
    setPinned: () => { }
});

type AMQPContext = {
    client: AmqpClient | undefined,
    setClient: React.Dispatch<React.SetStateAction<AmqpClient | undefined>>,
    rows: RowData[],
    setRows: React.Dispatch<React.SetStateAction<RowData[]>>,
    pinned: RowData[],
    setPinned: React.Dispatch<React.SetStateAction<RowData[]>>,
}