import { createTheme } from "@mui/material/styles";

export const croadsTheme = createTheme({
    palette: {
        primary: {
            main: "#507380",
        },
        secondary: {
            main: "#71A2B6"
        },
        caution: "#FF0000",
        paper: "#FAFAFA",
        text: {
            primary: "#507380",
            secondary: "#777",
        },
    },
});