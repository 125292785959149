import { Theme, SxProps } from "@mui/material";

export const clientBox: SxProps<Theme> = {
    minHeight: "100vh",
};

export const navbarWrapper: SxProps<Theme> = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const navbarLabel: SxProps<Theme> = {
    color: (theme: Theme) => theme.palette.secondary.main,
    fontSize: "1.8rem",
    fontWeight: "bold",
    p: 2,
};


export const croadsLogo: SxProps = {
    width: 250,
    p: 2,
};

export const clientTabs: SxProps = {
    mt: 1,
    mb: 2,
};