import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { checkboxLabelStyle, checkboxPadding } from "./optionalCheckboxStyles";
import { useId } from "react";

type OptionalCheckboxProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format: MessageFormat<any>;
    value: boolean;
    onUpdate: (update: boolean) => void;
};

const OptionalCheckbox = ({
    format,
    onUpdate,
    value,
}: OptionalCheckboxProps) => {
    const id = useId();
    return (
        <>
            {format.optional === true && (
                <FormGroup sx={checkboxPadding}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value}
                                id={`optional-${format.fieldName}-${id}`}
                                color="primary"
                                onChange={(_, v) => onUpdate(v)}
                            />
                        }
                        label={
                            value ? undefined : (
                                <Typography
                                    variant="caption"
                                    sx={checkboxLabelStyle}
                                >{`Fill optional ${format.fieldName}`}</Typography>
                            )
                        }
                    />
                </FormGroup>
            )}
        </>
    );
};

export default OptionalCheckbox;
