import { SxProps } from "@mui/system";

export const fileInput: SxProps = {
    display: "none",
};

export const fileInputLabel: SxProps = {
    display: "inline-flex",
    alignItems: "center",
    gap: "5px",
};

export const uploadedFileBox: SxProps = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: 1,
};

export const deleteBtnIcon: SxProps = {
    color: "#FF0011",
};