import { SxProps } from "@mui/material";
import { CSSProperties } from "react";

export const arrayElementTableWrapper: SxProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const arrayElementTable: SxProps = {
    minWidth: 650
};

export const arrayElementTableHeader: SxProps = {
    paddingLeft: 2,
    paddingTop: 1,
};

export const itemInputContainer: CSSProperties = {
    flex: 1
};