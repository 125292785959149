import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export const boxContainer: SxProps = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    m: "32px 16px",
};

export const closeIconBtn: SxProps<Theme> = {
    color: (theme: Theme) => theme.palette.primary.main
};

export const jsonTreeView: React.CSSProperties = {
    marginTop: "12px",
    maxHeight: "75vh",
    width: "30vw",
    overflow: "auto",
};