import { Box } from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { useEffect, useState } from "react";
import {
  flexAlignCenter,
} from "../ObjectInput/objectInputStyles";
import OptionalCheckbox from "../OptionalCheckbox/OptionalCheckbox";

type NullInputProps = {
  nullFormat: MessageFormat<null | undefined>;
  onChange: (instance: null | undefined) => void;
};

const NullInput = ({ nullFormat, onChange }: NullInputProps) => {
  const [isOptionalAndFilled, setIsOptionalAndFilled] = useState(
    nullFormat.optional !== true
  );

  useEffect(() => {
    if (!isOptionalAndFilled) {
      onChange(nullFormat.defaultValue);
    }
  }, [isOptionalAndFilled]);

  useEffect(() => {
    if (nullFormat.defaultValue !== undefined) {
      onChange(nullFormat.defaultValue);
    }
  });

  return (
    <Box sx={flexAlignCenter}>
      <OptionalCheckbox value={isOptionalAndFilled} onUpdate={setIsOptionalAndFilled} format={nullFormat} />
    </Box>
  );
};

export default NullInput;
