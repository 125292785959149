import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export const tableHeader: SxProps = {
    background: "#fff",
    zIndex: 999999,
    whiteSpace: "nowrap",
    cursor: "default",
};

export const pinnedRow: SxProps<Theme> = {
    backgroundColor: (theme: Theme) => theme.palette.primary.main,
    color: "#fff",
    cursor: "pointer",
};

export const unpinnedRow: SxProps<Theme> = {
    ...pinnedRow,
    textAlign: "right",
    color: "#777"
};

export const pinIconBtn: SxProps = {
    color: "#fff"
};