/* eslint @typescript-eslint/no-explicit-any: 0 */
import { MessageFormat } from "./MessageForm/MessageForm";

export const AccidentSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Accident Sub Cause Code",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const AdverseWeatherConditionAdhesionSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Adverse Weather Condition AdhesionSubCauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const AdverseWeatherConditionExtremeWeatherConditionSubCauseCode: MessageFormat<any> =
    {
        value: 0,
        fieldName:
            "Adverse Weather Condition ExtremeWeatherConditionSubCauseCode",
        ty: ["number", null],
        constraints: { min: 0, max: 255 },
    };
export const AdverseWeatherConditionPrecipitationSubCauseCode: MessageFormat<any> =
    {
        value: 0,
        fieldName: "Adverse Weather Condition PrecipitationSubCauseCode",
        ty: ["number", null],
        constraints: { min: 0, max: 255 },
    };
export const AdverseWeatherConditionVisibilitySubCauseCode: MessageFormat<any> =
    {
        value: 0,
        fieldName: "Adverse Weather Condition VisibilitySubCauseCode",
        ty: ["number", null],
        constraints: { min: 0, max: 255 },
    };
export const AdvertiserIdentifier: MessageFormat<any> = {
    value: "",
    fieldName: "Advertiser Identifier",
    ty: ["string", null],
    constraints: { min: 1, max: 32 },
};
export const ChannelAccess80211: MessageFormat<any> = {
    value: 0,
    fieldName: "Channel Access80211",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const DSRCmsgID: MessageFormat<any> = {
    value: 0,
    fieldName: "D S R CmsgID",
    ty: ["number", null],
    constraints: { min: 0, max: 32767 },
};
export const CollisionRiskSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Collision Risk Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const DangerousEndOfQueueSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Dangerous End Of QueueSubCauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const DangerousSituationSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Dangerous Situation Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const DataRate80211: MessageFormat<any> = {
    value: 0,
    fieldName: "Data Rate80211",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const EmergencyVehicleApproachingSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Emergency Vehicle Approaching SubCauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const HashAlgorithm: MessageFormat<any> = {
    value: 0,
    fieldName: "Hash Algorithm",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const HashedId10: MessageFormat<any> = {
    value: "",
    fieldName: "Hashed Id10",
    ty: ["hexstring", null],
    constraints: { min: 10, max: 10 },
};
export const HashedId8: MessageFormat<any> = {
    value: "",
    fieldName: "Hashed Id8",
    ty: ["hexstring", null],
    constraints: { min: 8, max: 8 },
};
export const HazardousLocationAnimalOnTheRoadSubCauseCode: MessageFormat<any> =
    {
        value: 0,
        fieldName: "Hazardous Location Animal OnTheRoadSubCauseCode",
        ty: ["number", null],
        constraints: { min: 0, max: 255 },
    };
export const HazardousLocationDangerousCurveSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Hazardous Location Dangerous CurveSubCauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const HazardousLocationObstacleOnTheRoadSubCauseCode: MessageFormat<any> =
    {
        value: 0,
        fieldName: "Hazardous Location Obstacle OnTheRoadSubCauseCode",
        ty: ["number", null],
        constraints: { min: 0, max: 255 },
    };
export const HazardousLocationSurfaceConditionSubCauseCode: MessageFormat<any> =
    {
        value: 0,
        fieldName: "Hazardous Location Surface ConditionSubCauseCode",
        ty: ["number", null],
        constraints: { min: 0, max: 255 },
    };
export const Hostname: MessageFormat<any> = {
    value: "",
    fieldName: "Hostname",
    ty: ["string", null],
    constraints: { min: 0, max: 255 },
};
export const HumanPresenceOnTheRoadSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Human Presence On TheRoadSubCauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const HumanProblemSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Human Problem Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const Angle: MessageFormat<any> = {
    value: 0,
    fieldName: "Angle",
    ty: ["number", null],
    constraints: { min: 0, max: 28800 },
};
export const LMchannelBusyRatio: MessageFormat<any> = {
    value: 0,
    fieldName: "L Mchannel Busy Ratio",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const LaId: MessageFormat<any> = {
    value: "",
    fieldName: "La Id",
    ty: ["hexstring", null],
    constraints: { min: 2, max: 2 },
};
export const LinkageSeed: MessageFormat<any> = {
    value: "",
    fieldName: "Linkage Seed",
    ty: ["hexstring", null],
    constraints: { min: 16, max: 16 },
};
export const InformationQuality: MessageFormat<any> = {
    value: 0,
    fieldName: "Information Quality",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const LayerID: MessageFormat<any> = {
    value: 0,
    fieldName: "Layer ID",
    ty: ["number", null],
    constraints: { min: 0, max: 100 },
};
export const LayerType: MessageFormat<any> = {
    value: 0,
    fieldName: "Layer Type",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const MinuteOfTheYear: MessageFormat<any> = {
    value: 0,
    fieldName: "Minute Of The Year",
    ty: ["number", null],
    constraints: { min: 0, max: 527040 },
};
export const MsgCount: MessageFormat<any> = {
    value: 0,
    fieldName: "Msg Count",
    ty: ["number", null],
    constraints: { min: 0, max: 127 },
};
export const PostCrashSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Post Crash Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const MACaddress: MessageFormat<any> = {
    value: "",
    fieldName: "M A Caddress",
    ty: ["hexstring", null],
    constraints: { min: 6, max: 6 },
};
export const RcpiThreshold: MessageFormat<any> = {
    value: 0,
    fieldName: "Rcpi Threshold",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const RepeatRate: MessageFormat<any> = {
    value: 0,
    fieldName: "Repeat Rate",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const RescueAndRecoveryWorkInProgressSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Rescue And Recovery WorkInProgressSubCauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SAMapplicationData: MessageFormat<any> = {
    value: "",
    fieldName: "S A Mapplication Data",
    ty: ["hexstring", null],
};
export const IPv6Address: MessageFormat<any> = {
    value: "",
    fieldName: "I Pv6Address",
    ty: ["hexstring", null],
    constraints: { min: 16, max: 16 },
};
export const ServicePort: MessageFormat<any> = {
    value: 0,
    fieldName: "Service Port",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const SignalViolationSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Signal Violation Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SlowVehicleSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Slow Vehicle Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const StationaryVehicleSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Stationary Vehicle Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SubjectAssurance: MessageFormat<any> = {
    value: "",
    fieldName: "Subject Assurance",
    ty: ["hexstring", null],
    constraints: { min: 1, max: 1 },
};
export const TrafficConditionSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Traffic Condition Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const VehicleBreakdownSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Breakdown Sub CauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const WrongWayDrivingSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Wrong Way Driving SubCauseCode",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const OneEightyDegreeInt: MessageFormat<any> = {
    value: 0,
    fieldName: "One Eighty Degree Int",
    ty: ["number", null],
    constraints: { min: -1799999999, max: 1800000001 },
};
export const NinetyDegreeInt: MessageFormat<any> = {
    value: 0,
    fieldName: "Ninety Degree Int",
    ty: ["number", null],
    constraints: { min: -900000000, max: 900000001 },
};
export const ExceptionalCondition: MessageFormat<any> = {
    value: 0,
    fieldName: "Exceptional Condition",
    ty: ["number", null],
    constraints: { min: 0, max: 12 },
};
export const BatteryStatus: MessageFormat<any> = {
    value: 0,
    fieldName: "Battery Status",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const FuelType: MessageFormat<any> = {
    value: 0,
    fieldName: "Fuel Type",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const EmissionType: MessageFormat<any> = {
    value: 0,
    fieldName: "Emission Type",
    ty: ["number", null],
    constraints: { min: 0, max: 5 },
};
export const RejectedReason: MessageFormat<any> = {
    value: 0,
    fieldName: "Rejected Reason",
    ty: ["number", null],
    constraints: { min: 0, max: 5 },
};
export const WsaCountThresholdInterval: MessageFormat<any> = {
    value: 0,
    fieldName: "Wsa Count Threshold Interval",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const StationType: MessageFormat<any> = {
    value: 0,
    fieldName: "Station Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const HeightLonCarr: MessageFormat<any> = {
    value: 0,
    fieldName: "Height Lon Carr",
    ty: ["number", null],
    constraints: { min: 1, max: 100 },
};
export const LanePosition: MessageFormat<any> = {
    value: 0,
    fieldName: "Lane Position",
    ty: ["number", null],
    constraints: { min: -1, max: 14 },
};
export const PositioningSolutionType: MessageFormat<any> = {
    value: 0,
    fieldName: "Positioning Solution Type",
    ty: ["number", null],
    constraints: { min: 0, max: 5 },
};
export const ImpactSection: MessageFormat<any> = {
    value: 0,
    fieldName: "Impact Section",
    ty: ["number", null],
    constraints: { min: 0, max: 6 },
};
export const StationID: MessageFormat<any> = {
    value: 0,
    fieldName: "Station ID",
    ty: ["number", null],
    constraints: { min: 0, max: 4294967295 },
};
export const DeltaAltitude: MessageFormat<any> = {
    value: 0,
    fieldName: "Delta Altitude",
    ty: ["number", null],
    constraints: { min: -12700, max: 12800 },
};
export const DeltaLatitude: MessageFormat<any> = {
    value: 0,
    fieldName: "Delta Latitude",
    ty: ["number", null],
    constraints: { min: -131071, max: 131072 },
};
export const DeltaLongitude: MessageFormat<any> = {
    value: 0,
    fieldName: "Delta Longitude",
    ty: ["number", null],
    constraints: { min: -131071, max: 131072 },
};
export const LightBarSirenInUse: MessageFormat<any> = {
    value: "",
    fieldName: "Light Bar Siren InUse",
    ty: ["bitstring", null],
    constraints: { min: 2, max: 2 },
};
export const SequenceNumber: MessageFormat<any> = {
    value: 0,
    fieldName: "Sequence Number",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const DangerousGoodsBasic: MessageFormat<any> = {
    value: 0,
    fieldName: "Dangerous Goods Basic",
    ty: ["number", null],
    constraints: { min: 0, max: 19 },
};
export const Temperature: MessageFormat<any> = {
    value: 0,
    fieldName: "Temperature",
    ty: ["number", null],
    constraints: { min: -60, max: 67 },
};
export const EnergyStorageType: MessageFormat<any> = {
    value: "",
    fieldName: "Energy Storage Type",
    ty: ["bitstring", null],
    constraints: { min: 7, max: 7 },
};
export const NumberOfOccupants: MessageFormat<any> = {
    value: 0,
    fieldName: "Number Of Occupants",
    ty: ["number", null],
    constraints: { min: 0, max: 127 },
};
export const StationarySince: MessageFormat<any> = {
    value: 0,
    fieldName: "Stationary Since",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const ActionDeltaTime: MessageFormat<any> = {
    value: 0,
    fieldName: "Action Delta Time",
    ty: ["number", null],
    constraints: { min: 0, max: 127 },
};
export const VruSizeClass: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Size Class",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const VruSubProfileMotorcyclist: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Sub Profile Motorcyclist",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const DescriptiveName: MessageFormat<any> = {
    value: "",
    fieldName: "Descriptive Name",
    ty: ["string", null],
    constraints: { min: 1, max: 63 },
};
export const IntersectionID: MessageFormat<any> = {
    value: 0,
    fieldName: "Intersection ID",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const Uint64: MessageFormat<any> = {
    value: 0,
    fieldName: "Uint64",
    ty: ["number", null],
    constraints: { min: 0, max: Number.MAX_VALUE },
};
export const AccelerationControl: MessageFormat<any> = {
    value: "",
    fieldName: "Acceleration Control",
    ty: ["bitstring", null],
    constraints: { min: 7, max: 7 },
};
export const CurvatureCalculationMode: MessageFormat<any> = {
    value: 0,
    fieldName: "Curvature Calculation Mode",
    ty: ["number", null],
    constraints: { min: 0, max: 2 },
};
export const DriveDirection: MessageFormat<any> = {
    value: 0,
    fieldName: "Drive Direction",
    ty: ["number", null],
    constraints: { min: 0, max: 2 },
};
export const PerformanceClass: MessageFormat<any> = {
    value: 0,
    fieldName: "Performance Class",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const VehicleWidth: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Width",
    ty: ["number", null],
    constraints: { min: 1, max: 62 },
};
export const ExteriorLights: MessageFormat<any> = {
    value: "",
    fieldName: "Exterior Lights",
    ty: ["bitstring", null],
    constraints: { min: 8, max: 8 },
};
export const GenerationDeltaTime: MessageFormat<any> = {
    value: 0,
    fieldName: "Generation Delta Time",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const Uint32: MessageFormat<any> = {
    value: 0,
    fieldName: "Uint32",
    ty: ["number", null],
    constraints: { min: 0, max: 4294967295 },
};
export const PtvRequestType: MessageFormat<any> = {
    value: 0,
    fieldName: "Ptv Request Type",
    ty: ["number", null],
    constraints: { min: 0, max: 4 },
};
export const EmbarkationStatus: MessageFormat<any> = {
    value: "",
    fieldName: "Embarkation Status",
    ty: ["boolean", null],
};
export const TrafficRule: MessageFormat<any> = {
    value: 0,
    fieldName: "Traffic Rule",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const GoodsType: MessageFormat<any> = {
    value: 0,
    fieldName: "Goods Type",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const PhoneNumber: MessageFormat<any> = {
    value: "",
    fieldName: "Phone Number",
    ty: ["string", null],
    constraints: { min: 1, max: 16 },
};
export const VDS: MessageFormat<any> = {
    value: "",
    fieldName: "V D S",
    ty: ["string", null],
    constraints: { min: 6, max: 6 },
};
export const DrivingLaneStatus: MessageFormat<any> = {
    value: "",
    fieldName: "Driving Lane Status",
    ty: ["bitstring", null],
    constraints: { min: 1, max: 13 },
};
export const HardShoulderStatus: MessageFormat<any> = {
    value: 0,
    fieldName: "Hard Shoulder Status",
    ty: ["number", null],
    constraints: { min: 0, max: 2 },
};
export const SegmentCount: MessageFormat<any> = {
    value: 0,
    fieldName: "Segment Count",
    ty: ["number", null],
    constraints: { min: 1, max: 127 },
};
export const TransmissionInterval: MessageFormat<any> = {
    value: 0,
    fieldName: "Transmission Interval",
    ty: ["number", null],
    constraints: { min: 1, max: 10000 },
};
export const PosCentMass: MessageFormat<any> = {
    value: 0,
    fieldName: "Pos Cent Mass",
    ty: ["number", null],
    constraints: { min: 1, max: 63 },
};
export const PosFrontAx: MessageFormat<any> = {
    value: 0,
    fieldName: "Pos Front Ax",
    ty: ["number", null],
    constraints: { min: 1, max: 20 },
};
export const PosLonCarr: MessageFormat<any> = {
    value: 0,
    fieldName: "Pos Lon Carr",
    ty: ["number", null],
    constraints: { min: 1, max: 127 },
};
export const PositionOfOccupants: MessageFormat<any> = {
    value: "",
    fieldName: "Position Of Occupants",
    ty: ["bitstring", null],
    constraints: { min: 20, max: 20 },
};
export const PosPillar: MessageFormat<any> = {
    value: 0,
    fieldName: "Pos Pillar",
    ty: ["number", null],
    constraints: { min: 1, max: 30 },
};
export const RequestResponseIndication: MessageFormat<any> = {
    value: 0,
    fieldName: "Request Response Indication",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const VehicleMass: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Mass",
    ty: ["number", null],
    constraints: { min: 1, max: 1024 },
};
export const WheelBaseVehicle: MessageFormat<any> = {
    value: 0,
    fieldName: "Wheel Base Vehicle",
    ty: ["number", null],
    constraints: { min: 1, max: 127 },
};
export const TurningRadius: MessageFormat<any> = {
    value: 0,
    fieldName: "Turning Radius",
    ty: ["number", null],
    constraints: { min: 1, max: 255 },
};
export const POIType: MessageFormat<any> = {
    value: 0,
    fieldName: "P O I Type",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const TimestampIts: MessageFormat<any> = {
    value: 0,
    fieldName: "Timestamp Its",
    ty: ["number", null],
    constraints: { min: 0, max: 4398046511103 },
};
export const Ext3: MessageFormat<any> = {
    value: 0,
    fieldName: "Ext3",
    ty: ["number", null],
    constraints: { min: 2113664, max: 270549119 },
};
export const StabilityLossProbability: MessageFormat<any> = {
    value: 0,
    fieldName: "Stability Loss Probability",
    ty: ["number", null],
    constraints: { min: 0, max: 63 },
};
export const PathDeltaTime: MessageFormat<any> = {
    value: 0,
    fieldName: "Path Delta Time",
    ty: ["number", null],
    constraints: { min: 1, max: 65535 },
};
export const RoadRegulatorID: MessageFormat<any> = {
    value: 0,
    fieldName: "Road Regulator ID",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const SemiRangeLength: MessageFormat<any> = {
    value: 0,
    fieldName: "Semi Range Length",
    ty: ["number", null],
    constraints: { min: 0, max: 10000 },
};
export const WGS84AngleValue: MessageFormat<any> = {
    value: 0,
    fieldName: "W G S84AngleValue",
    ty: ["number", null],
    constraints: { min: 0, max: 3601 },
};
export const ClusterProfiles: MessageFormat<any> = {
    value: "",
    fieldName: "Cluster Profiles",
    ty: ["bitstring", null],
    constraints: { min: 4, max: 4 },
};
export const ClusterId: MessageFormat<any> = {
    value: 0,
    fieldName: "Cluster Id",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const ClusterBreakupReason: MessageFormat<any> = {
    value: 0,
    fieldName: "Cluster Breakup Reason",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const VruClusterOpTimestamp: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Cluster Op Timestamp",
    ty: ["number", null],
    constraints: { min: 1, max: 255 },
};
export const ClusterLeaveReason: MessageFormat<any> = {
    value: 0,
    fieldName: "Cluster Leave Reason",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const VruDeviceUsage: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Device Usage",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const VruEnvironment: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Environment",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const AccelOrDecel: MessageFormat<any> = {
    value: 0,
    fieldName: "Accel Or Decel",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const LeftOrRight: MessageFormat<any> = {
    value: 0,
    fieldName: "Left Or Right",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const SpecialTransportType: MessageFormat<any> = {
    value: "",
    fieldName: "Special Transport Type",
    ty: ["bitstring", null],
    constraints: { min: 4, max: 4 },
};
export const RoadworksSubCauseCode: MessageFormat<any> = {
    value: 0,
    fieldName: "Roadworks Sub Cause Code",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const EmergencyPriority: MessageFormat<any> = {
    value: "",
    fieldName: "Emergency Priority",
    ty: ["bitstring", null],
    constraints: { min: 2, max: 2 },
};
export const NumberOfPerceivedObjects: MessageFormat<any> = {
    value: 0,
    fieldName: "Number Of Perceived Objects",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const WMInumber: MessageFormat<any> = {
    value: "",
    fieldName: "W M Inumber",
    ty: ["string", null],
    constraints: { min: 1, max: 3 },
};
export const VruSpecificExteriorLights: MessageFormat<any> = {
    value: "",
    fieldName: "Vru Specific Exterior Lights",
    ty: ["bitstring", null],
    constraints: { min: 8, max: 8 },
};
export const VruSubProfileAnimal: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Sub Profile Animal",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const VruSubProfileBicyclist: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Sub Profile Bicyclist",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const VruSubProfilePedestrian: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Sub Profile Pedestrian",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const LaneID: MessageFormat<any> = {
    value: 0,
    fieldName: "Lane ID",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const OffRoadLanePosition: MessageFormat<any> = {
    value: 0,
    fieldName: "Off Road Lane Position",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const VruMovementControl: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Movement Control",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const VehicleHeight: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Height",
    ty: ["number", null],
    constraints: { min: 0, max: 127 },
};
export const RoadSegmentID: MessageFormat<any> = {
    value: 0,
    fieldName: "Road Segment ID",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const RegionId: MessageFormat<any> = {
    value: 0,
    fieldName: "Region Id",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const AdvisorySpeedType: MessageFormat<any> = {
    value: 0,
    fieldName: "Advisory Speed Type",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const RestrictionClassID: MessageFormat<any> = {
    value: 0,
    fieldName: "Restriction Class ID",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SAESpeedConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "S A E SpeedConfidence",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const SpeedAdvice: MessageFormat<any> = {
    value: 0,
    fieldName: "Speed Advice",
    ty: ["number", null],
    constraints: { min: 0, max: 500 },
};
export const ZoneLength: MessageFormat<any> = {
    value: 0,
    fieldName: "Zone Length",
    ty: ["number", null],
    constraints: { min: 0, max: 10000 },
};
export const AltitudeConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Altitude Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const AltitudeValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Altitude Value",
    ty: ["number", null],
    constraints: { min: -100000, max: 800001 },
};
export const AnimalSubclassType: MessageFormat<any> = {
    value: 0,
    fieldName: "Animal Subclass Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const ClassConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Class Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 101 },
};
export const AnonymousAttributeIdList: MessageFormat<any> = {
    value: 0,
    fieldName: "Anonymous Attribute Id List",
    ty: ["number", null],
    constraints: { min: 0, max: 127 },
};
export const AngleConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Angle Confidence",
    ty: ["number", null],
    constraints: { min: 1, max: 127 },
};
export const CartesianAngleValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Cartesian Angle Value",
    ty: ["number", null],
    constraints: { min: 0, max: 3601 },
};
export const Altitude: MessageFormat<any> = {
    value: {
        altitude_value: { ...AltitudeValue, optional: false },
        altitude_confidence: { ...AltitudeConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Altitude",
};
export const SubCauseCodeType: MessageFormat<any> = {
    value: 0,
    fieldName: "Sub Cause Code Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const CauseCodeType: MessageFormat<any> = {
    value: 0,
    fieldName: "Cause Code Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const ProtectedZoneID: MessageFormat<any> = {
    value: 0,
    fieldName: "Protected Zone ID",
    ty: ["number", null],
    constraints: { min: 0, max: 134217727 },
};
export const ChannelNumber80211: MessageFormat<any> = {
    value: 0,
    fieldName: "Channel Number80211",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const OperatingClass80211: MessageFormat<any> = {
    value: 0,
    fieldName: "Operating Class80211",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const TXpower80211: MessageFormat<any> = {
    value: 0,
    fieldName: "T Xpower80211",
    ty: ["number", null],
    constraints: { min: -128, max: 127 },
};
export const RSCUnit: MessageFormat<any> = {
    value: 0,
    fieldName: "R S C Unit",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const AmbientOrRoadConditionPictogram: MessageFormat<any> = {
    value: 0,
    fieldName: "Ambient Or Road ConditionPictogram",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const PublicFacilitiesPictogram: MessageFormat<any> = {
    value: 0,
    fieldName: "Public Facilities Pictogram",
    ty: ["number", null],
    constraints: { min: 0, max: 0 },
};
export const TrafficSignPictogram: MessageFormat<any> = {
    value: 0,
    fieldName: "Traffic Sign Pictogram",
    ty: ["number", null],
    constraints: { min: 0, max: 2 },
};
export const CountryCode: MessageFormat<any> = {
    value: "",
    fieldName: "Country Code",
    ty: ["bitstring", null],
    constraints: { min: 10, max: 10 },
};
export const DrivenLineOffsetLg: MessageFormat<any> = {
    value: 0,
    fieldName: "Driven Line Offset Lg",
    ty: ["number", null],
    constraints: { min: -32767, max: 32767 },
};
export const DrivenLineOffsetSm: MessageFormat<any> = {
    value: 0,
    fieldName: "Driven Line Offset Sm",
    ty: ["number", null],
    constraints: { min: -2047, max: 2047 },
};
export const ScaleB12: MessageFormat<any> = {
    value: 0,
    fieldName: "Scale B12",
    ty: ["number", null],
    constraints: { min: -2048, max: 2047 },
};
export const LaneConnectionID: MessageFormat<any> = {
    value: 0,
    fieldName: "Lane Connection ID",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const AllowedManeuvers: MessageFormat<any> = {
    value: "",
    fieldName: "Allowed Maneuvers",
    ty: ["bitstring", null],
    constraints: { min: 12, max: 12 },
};
export const SignalGroupID: MessageFormat<any> = {
    value: 0,
    fieldName: "Signal Group ID",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const PedestrianBicycleDetect: MessageFormat<any> = {
    value: "",
    fieldName: "Pedestrian Bicycle Detect",
    ty: ["boolean", null],
};
export const WaitOnStopline: MessageFormat<any> = {
    value: false,
    fieldName: "Wait On Stopline",
    ty: ["boolean", null],
};
export const CtxRef: MessageFormat<any> = {
    value: 0,
    fieldName: "Ctx Ref",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const CurvatureConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Curvature Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const CurvatureValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Curvature Value",
    ty: ["number", null],
    constraints: { min: -1023, max: 1023 },
};
export const DDDDEP: MessageFormat<any> = {
    value: 0,
    fieldName: "DDDDEP",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const DDDDER: MessageFormat<any> = {
    value: 0,
    fieldName: "DDDDER",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const DeltaTime: MessageFormat<any> = {
    value: 0,
    fieldName: "Delta Time",
    ty: ["number", null],
    constraints: { min: -122, max: 121 },
};
export const Direction: MessageFormat<any> = {
    value: 0,
    fieldName: "Direction",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const EnumeratedEdcaIdentifier: MessageFormat<any> = {
    value: 0,
    fieldName: "Enumerated Edca Identifier",
    ty: ["number", null],
    constraints: { min: 0, max: 0 },
};
export const Elevation: MessageFormat<any> = {
    value: 0,
    fieldName: "Elevation",
    ty: ["number", null],
    constraints: { min: -4096, max: 61439 },
};
export const MedType: MessageFormat<any> = {
    value: 0,
    fieldName: "Med Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const RefExt: MessageFormat<any> = {
    value: 0,
    fieldName: "Ref Ext",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const FreeSpaceConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Free Space Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 101 },
};
export const ShadowingApplies: MessageFormat<any> = {
    value: "",
    fieldName: "Shadowing Applies",
    ty: ["boolean", null],
};
export const ApproachID: MessageFormat<any> = {
    value: 0,
    fieldName: "Approach ID",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const HeadingValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Heading Value",
    ty: ["number", null],
    constraints: { min: 0, max: 3601 },
};
export const Zid: MessageFormat<any> = {
    value: 0,
    fieldName: "Zid",
    ty: ["number", null],
    constraints: { min: 1, max: 32 },
};
export const DriverCharacteristics: MessageFormat<any> = {
    value: 0,
    fieldName: "Driver Characteristics",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const IviPurpose: MessageFormat<any> = {
    value: 0,
    fieldName: "Ivi Purpose",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const IviType: MessageFormat<any> = {
    value: 0,
    fieldName: "Ivi Type",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const HashedId3: MessageFormat<any> = {
    value: "",
    fieldName: "Hashed Id3",
    ty: ["hexstring", null],
    constraints: { min: 3, max: 3 },
};
export const HeadingConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Heading Confidence",
    ty: ["number", null],
    constraints: { min: 1, max: 127 },
};
export const DFL: MessageFormat<any> = {
    value: 0,
    fieldName: "D F L",
    ty: ["number", null],
    constraints: { min: 1, max: 8 },
};
export const DayOfWeek: MessageFormat<any> = {
    value: "",
    fieldName: "Day Of Week",
    ty: ["bitstring", null],
    constraints: { min: 8, max: 8 },
};
export const PMD: MessageFormat<any> = {
    value: "",
    fieldName: "PMD",
    ty: ["bitstring", null],
    constraints: { min: 4, max: 4 },
};
export const ROI: MessageFormat<any> = {
    value: 0,
    fieldName: "ROI",
    ty: ["number", null],
    constraints: { min: 1, max: 32 },
};
export const IviIdentificationNumber: MessageFormat<any> = {
    value: 0,
    fieldName: "Ivi Identification Number",
    ty: ["number", null],
    constraints: { min: 1, max: 32767 },
};
export const IviStatus: MessageFormat<any> = {
    value: 0,
    fieldName: "Ivi Status",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const Identifier: MessageFormat<any> = {
    value: 0,
    fieldName: "Identifier",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SpeedLimitType: MessageFormat<any> = {
    value: 0,
    fieldName: "Speed Limit Type",
    ty: ["number", null],
    constraints: { min: 0, max: 12 },
};
export const Velocity: MessageFormat<any> = {
    value: 0,
    fieldName: "Velocity",
    ty: ["number", null],
    constraints: { min: 0, max: 8191 },
};
export const LaneWidth: MessageFormat<any> = {
    value: 0,
    fieldName: "Lane Width",
    ty: ["number", null],
    constraints: { min: 0, max: 32767 },
};
export const IntersectionStatusObject: MessageFormat<any> = {
    value: "",
    fieldName: "Intersection Status Object",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const DSecond: MessageFormat<any> = {
    value: 0,
    fieldName: "D Second",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const ChargingSpotType: MessageFormat<any> = {
    value: "",
    fieldName: "Charging Spot Type",
    ty: ["bitstring", null],
};
export const TypeOfReceptacle: MessageFormat<any> = {
    value: "",
    fieldName: "Type Of Receptacle",
    ty: ["bitstring", null],
};
export const OpeningDaysHours: MessageFormat<any> = {
    value: "",
    fieldName: "Opening Days Hours",
    ty: ["string", null],
};
export const NumberStations: MessageFormat<any> = {
    value: 0,
    fieldName: "Number Stations",
    ty: ["number", null],
    constraints: { min: 1, max: 256 },
};
export const OffsetB10: MessageFormat<any> = {
    value: 0,
    fieldName: "Offset B10",
    ty: ["number", null],
    constraints: { min: -512, max: 511 },
};
export const OffsetB11: MessageFormat<any> = {
    value: 0,
    fieldName: "Offset B11",
    ty: ["number", null],
    constraints: { min: -1024, max: 1023 },
};
export const OffsetB12: MessageFormat<any> = {
    value: 0,
    fieldName: "Offset B12",
    ty: ["number", null],
    constraints: { min: -2048, max: 2047 },
};
export const OffsetB13: MessageFormat<any> = {
    value: 0,
    fieldName: "Offset B13",
    ty: ["number", null],
    constraints: { min: -4096, max: 4095 },
};
export const OffsetB14: MessageFormat<any> = {
    value: 0,
    fieldName: "Offset B14",
    ty: ["number", null],
    constraints: { min: -8192, max: 8191 },
};
export const OffsetB16: MessageFormat<any> = {
    value: 0,
    fieldName: "Offset B16",
    ty: ["number", null],
    constraints: { min: -32768, max: 32767 },
};
export const TimeReference: MessageFormat<any> = {
    value: 0,
    fieldName: "Time Reference",
    ty: ["number", null],
    constraints: { min: 0, max: 60000 },
};
export const LaneDirection: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Direction",
    ty: ["bitstring", null],
    constraints: { min: 2, max: 2 },
};
export const LaneSharing: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Sharing",
    ty: ["bitstring", null],
    constraints: { min: 10, max: 10 },
};
export const LaneAttributesBarrier: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Barrier",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const LaneAttributesBike: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Bike",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const LaneAttributesCrosswalk: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Crosswalk",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const LaneAttributesParking: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Parking",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const LaneAttributesSidewalk: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Sidewalk",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const LaneAttributesStriping: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Striping",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const LaneAttributesTrackedVehicle: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Tracked Vehicle",
    ty: ["bitstring", null],
    constraints: { min: 16, max: 16 },
};
export const LaneAttributesVehicle: MessageFormat<any> = {
    value: "",
    fieldName: "Lane Attributes Vehicle",
    ty: ["bitstring", null],
    constraints: { min: 8, max: 8 },
};
export const DeltaAngle: MessageFormat<any> = {
    value: 0,
    fieldName: "Delta Angle",
    ty: ["number", null],
    constraints: { min: -150, max: 150 },
};
export const MergeDivergeNodeAngle: MessageFormat<any> = {
    value: 0,
    fieldName: "Merge Diverge Node Angle",
    ty: ["number", null],
    constraints: { min: -180, max: 180 },
};
export const RoadwayCrownAngle: MessageFormat<any> = {
    value: 0,
    fieldName: "Roadway Crown Angle",
    ty: ["number", null],
    constraints: { min: -128, max: 127 },
};
export const LaneStatus: MessageFormat<any> = {
    value: 0,
    fieldName: "Lane Status",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const LaneType: MessageFormat<any> = {
    value: 0,
    fieldName: "Lane Type",
    ty: ["number", null],
    constraints: { min: 0, max: 31 },
};
export const AccelerationConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Acceleration Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 102 },
};
export const LateralAccelerationValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Lateral Acceleration Value",
    ty: ["number", null],
    constraints: { min: -160, max: 161 },
};
export const Latitude: MessageFormat<any> = {
    value: 0,
    fieldName: "Latitude",
    ty: ["number", null],
    constraints: { min: -900000000, max: 900000001 },
};
export const LinkageValue: MessageFormat<any> = {
    value: "",
    fieldName: "Linkage Value",
    ty: ["hexstring", null],
    constraints: { min: 9, max: 9 },
};
export const RoadType: MessageFormat<any> = {
    value: 0,
    fieldName: "Road Type",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const Longitude: MessageFormat<any> = {
    value: 0,
    fieldName: "Longitude",
    ty: ["number", null],
    constraints: { min: -1800000000, max: 1800000001 },
};
export const LongitudinalAccelerationValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Longitudinal Acceleration Value",
    ty: ["number", null],
    constraints: { min: -160, max: 161 },
};
export const LongitudinalLanePositionConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Longitudinal Lane Position Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 102 },
};
export const LongitudinalLanePositionValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Longitudinal Lane Position Value",
    ty: ["number", null],
    constraints: { min: 0, max: 32767 },
};
export const AckNackIndication: MessageFormat<any> = {
    value: 0,
    fieldName: "Ack Nack Indication",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const ValidityDuration: MessageFormat<any> = {
    value: 0,
    fieldName: "Validity Duration",
    ty: ["number", null],
    constraints: { min: 0, max: 86400 },
};
export const RelevanceDistance: MessageFormat<any> = {
    value: 0,
    fieldName: "Relevance Distance",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const RelevanceTrafficDirection: MessageFormat<any> = {
    value: 0,
    fieldName: "Relevance Traffic Direction",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const Termination: MessageFormat<any> = {
    value: 0,
    fieldName: "Termination",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const MovementPhaseState: MessageFormat<any> = {
    value: 0,
    fieldName: "Movement Phase State",
    ty: ["number", null],
    constraints: { min: 0, max: 9 },
};
export const TimeIntervalConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Time Interval Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const TimeMark: MessageFormat<any> = {
    value: 0,
    fieldName: "Time Mark",
    ty: ["number", null],
    constraints: { min: 0, max: 36001 },
};
export const NodeAttributeXY: MessageFormat<any> = {
    value: 0,
    fieldName: "Node Attribute X Y",
    ty: ["number", null],
    constraints: { min: 0, max: 11 },
};
export const OtherSublassType: MessageFormat<any> = {
    value: 0,
    fieldName: "Other Sublass Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const ObjectDimensionConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Object Dimension Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 102 },
};
export const ObjectDimensionValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Object Dimension Value",
    ty: ["number", null],
    constraints: { min: 0, max: 1023 },
};
export const DynamicStatus: MessageFormat<any> = {
    value: 0,
    fieldName: "Dynamic Status",
    ty: ["number", null],
    constraints: { min: 0, max: 2 },
};
export const ObjectAge: MessageFormat<any> = {
    value: 0,
    fieldName: "Object Age",
    ty: ["number", null],
    constraints: { min: 0, max: 1500 },
};
export const ObjectConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Object Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 101 },
};
export const DistanceConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Distance Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 102 },
};
export const DistanceValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Distance Value",
    ty: ["number", null],
    constraints: { min: -132768, max: 132767 },
};
export const ObjectRefPoint: MessageFormat<any> = {
    value: 0,
    fieldName: "Object Ref Point",
    ty: ["number", null],
    constraints: { min: 0, max: 8 },
};
export const SpeedValueExtended: MessageFormat<any> = {
    value: 0,
    fieldName: "Speed Value Extended",
    ty: ["number", null],
    constraints: { min: -16383, max: 16383 },
};
export const TimeOfMeasurement: MessageFormat<any> = {
    value: 0,
    fieldName: "Time Of Measurement",
    ty: ["number", null],
    constraints: { min: -1500, max: 1500 },
};
export const PersonSubclassType: MessageFormat<any> = {
    value: 0,
    fieldName: "Person Subclass Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const PositionConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Position Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const PrioritizationResponseStatus: MessageFormat<any> = {
    value: 0,
    fieldName: "Prioritization Response Status",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const ProtectedZoneRadius: MessageFormat<any> = {
    value: 0,
    fieldName: "Protected Zone Radius",
    ty: ["number", null],
    constraints: { min: 1, max: 255 },
};
export const ProtectedZoneType: MessageFormat<any> = {
    value: 0,
    fieldName: "Protected Zone Type",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const IssuerIdentifier: MessageFormat<any> = {
    value: 0,
    fieldName: "Issuer Identifier",
    ty: ["number", null],
    constraints: { min: 0, max: 16383 },
};
export const Psid: MessageFormat<any> = {
    value: 0,
    fieldName: "Psid",
    ty: ["number", null],
};
export const PtActivationData: MessageFormat<any> = {
    value: "",
    fieldName: "Pt Activation Data",
    ty: ["hexstring", null],
    constraints: { min: 1, max: 20 },
};
export const PtActivationType: MessageFormat<any> = {
    value: 0,
    fieldName: "Pt Activation Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SymmAlgorithm: MessageFormat<any> = {
    value: 0,
    fieldName: "Symm Algorithm",
    ty: ["number", null],
    constraints: { min: 0, max: 0 },
};
export const DDay: MessageFormat<any> = {
    value: 0,
    fieldName: "D Day",
    ty: ["number", null],
    constraints: { min: 0, max: 31 },
};
export const DHour: MessageFormat<any> = {
    value: 0,
    fieldName: "D Hour",
    ty: ["number", null],
    constraints: { min: 0, max: 31 },
};
export const DMinute: MessageFormat<any> = {
    value: 0,
    fieldName: "D Minute",
    ty: ["number", null],
    constraints: { min: 0, max: 60 },
};
export const DMonth: MessageFormat<any> = {
    value: 0,
    fieldName: "D Month",
    ty: ["number", null],
    constraints: { min: 0, max: 12 },
};
export const DOffset: MessageFormat<any> = {
    value: 0,
    fieldName: "D Offset",
    ty: ["number", null],
    constraints: { min: -840, max: 840 },
};
export const DYear: MessageFormat<any> = {
    value: 0,
    fieldName: "D Year",
    ty: ["number", null],
    constraints: { min: 0, max: 4095 },
};
export const ElevationConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Elevation Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const SemiMajorAxisAccuracy: MessageFormat<any> = {
    value: 0,
    fieldName: "Semi Major Axis Accuracy",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SemiMajorAxisOrientation: MessageFormat<any> = {
    value: 0,
    fieldName: "Semi Major Axis Orientation",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const SemiMinorAxisAccuracy: MessageFormat<any> = {
    value: 0,
    fieldName: "Semi Minor Axis Accuracy",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SAEThrottleConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "S A E ThrottleConfidence",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const SAEHeadingConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "S A E HeadingConfidence",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const TimeConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Time Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 39 },
};
export const TransmissionState: MessageFormat<any> = {
    value: 0,
    fieldName: "Transmission State",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const RTCMRevision: MessageFormat<any> = {
    value: 0,
    fieldName: "R T C MRevision",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const OffsetB09: MessageFormat<any> = {
    value: 0,
    fieldName: "Offset B09",
    ty: ["number", null],
    constraints: { min: -256, max: 255 },
};
export const GNSSstatus: MessageFormat<any> = {
    value: "",
    fieldName: "G N S Sstatus",
    ty: ["bitstring", null],
    constraints: { min: 8, max: 8 },
};
export const RTCMmessage: MessageFormat<any> = {
    value: "",
    fieldName: "R T C Mmessage",
    ty: ["hexstring", null],
    constraints: { min: 1, max: 1023 },
};
export const Radius: MessageFormat<any> = {
    value: 0,
    fieldName: "Radius",
    ty: ["number", null],
    constraints: { min: 0, max: 10000 },
};
export const Range: MessageFormat<any> = {
    value: 0,
    fieldName: "Range",
    ty: ["number", null],
    constraints: { min: 0, max: 10000 },
};
export const IVILaneWidth: MessageFormat<any> = {
    value: 0,
    fieldName: "IVI LaneWidth",
    ty: ["number", null],
    constraints: { min: 0, max: 1023 },
};
export const SemiAxisLength: MessageFormat<any> = {
    value: 0,
    fieldName: "Semi Axis Length",
    ty: ["number", null],
    constraints: { min: 0, max: 4095 },
};
export const BasicVehicleRole: MessageFormat<any> = {
    value: 0,
    fieldName: "Basic Vehicle Role",
    ty: ["number", null],
    constraints: { min: 0, max: 22 },
};
export const Iso3833VehicleType: MessageFormat<any> = {
    value: 0,
    fieldName: "Iso3833VehicleType",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const RequestImportanceLevel: MessageFormat<any> = {
    value: 0,
    fieldName: "Request Importance Level",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const RequestSubRole: MessageFormat<any> = {
    value: 0,
    fieldName: "Request Sub Role",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const TransitVehicleOccupancy: MessageFormat<any> = {
    value: 0,
    fieldName: "Transit Vehicle Occupancy",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const TransitVehicleStatus: MessageFormat<any> = {
    value: "",
    fieldName: "Transit Vehicle Status",
    ty: ["bitstring", null],
    constraints: { min: 8, max: 8 },
};
export const TemporaryID: MessageFormat<any> = {
    value: "",
    fieldName: "Temporary ID",
    ty: ["hexstring", null],
    constraints: { min: 4, max: 4 },
};
export const RestrictionAppliesTo: MessageFormat<any> = {
    value: 0,
    fieldName: "Restriction Applies To",
    ty: ["number", null],
    constraints: { min: 0, max: 13 },
};
export const SAEHeading: MessageFormat<any> = {
    value: 0,
    fieldName: "S A E Heading",
    ty: ["number", null],
    constraints: { min: 0, max: 28800 },
};
export const RsvAdvPrtVersion: MessageFormat<any> = {
    value: 0,
    fieldName: "Rsv Adv Prt Version",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const SrvAdvContentCount: MessageFormat<any> = {
    value: 0,
    fieldName: "Srv Adv Content Count",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const SrvAdvID: MessageFormat<any> = {
    value: 0,
    fieldName: "Srv Adv ID",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const IpV6Prefix: MessageFormat<any> = {
    value: "",
    fieldName: "Ip V6Prefix",
    ty: ["hexstring", null],
    constraints: { min: 16, max: 16 },
};
export const IpV6PrefixLength: MessageFormat<any> = {
    value: 0,
    fieldName: "Ip V6PrefixLength",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const RouterLifetime: MessageFormat<any> = {
    value: 0,
    fieldName: "Router Lifetime",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const SegmentAttributeXY: MessageFormat<any> = {
    value: 0,
    fieldName: "Segment Attribute X Y",
    ty: ["number", null],
    constraints: { min: 0, max: 37 },
};
export const SensorHeight: MessageFormat<any> = {
    value: 0,
    fieldName: "Sensor Height",
    ty: ["number", null],
    constraints: { min: -5000, max: 5000 },
};
export const SensorType: MessageFormat<any> = {
    value: 0,
    fieldName: "Sensor Type",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const AnonymousSequenceOfOctetString: MessageFormat<any> = {
    value: "",
    fieldName: "Anonymous Sequence Of OctetString",
    ty: ["hexstring", null],
};
export const BitmapSsp: MessageFormat<any> = {
    value: "",
    fieldName: "Bitmap Ssp",
    ty: ["hexstring", null],
    constraints: { min: 0, max: 31 },
};
export const Opaque: MessageFormat<any> = {
    value: "",
    fieldName: "Opaque",
    ty: ["hexstring", null],
};
export const PosConfidenceEllipse: MessageFormat<any> = {
    value: {
        semi_major_confidence: { ...SemiAxisLength, optional: false },
        semi_minor_confidence: { ...SemiAxisLength, optional: false },
        semi_major_orientation: { ...HeadingValue, optional: false },
    },
    ty: ["object", null],
    fieldName: "Pos Confidence Ellipse",
};
export const ChannelIndex: MessageFormat<any> = {
    value: 0,
    fieldName: "Channel Index",
    ty: ["number", null],
    constraints: { min: 0, max: 31 },
};
export const PortNumber: MessageFormat<any> = {
    value: 0,
    fieldName: "Port Number",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const PriorityRequestType: MessageFormat<any> = {
    value: 0,
    fieldName: "Priority Request Type",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const RequestID: MessageFormat<any> = {
    value: 0,
    fieldName: "Request ID",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const SpeedConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Speed Confidence",
    ty: ["number", null],
    constraints: { min: 1, max: 127 },
};
export const SpeedLimit: MessageFormat<any> = {
    value: 0,
    fieldName: "Speed Limit",
    ty: ["number", null],
    constraints: { min: 1, max: 255 },
};
export const SpeedValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Speed Value",
    ty: ["number", null],
    constraints: { min: 0, max: 16383 },
};
export const SteeringWheelAngleConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Steering Wheel Angle Confidence",
    ty: ["number", null],
    constraints: { min: 1, max: 127 },
};
export const SteeringWheelAngleValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Steering Wheel Angle Value",
    ty: ["number", null],
    constraints: { min: -511, max: 512 },
};
export const SAElevation: MessageFormat<any> = {
    value: 0,
    fieldName: "S A Elevation",
    ty: ["number", null],
    constraints: { min: -4096, max: 61439 },
};
export const SALongitude: MessageFormat<any> = {
    value: 0,
    fieldName: "S A Longitude",
    ty: ["number", null],
    constraints: { min: -1800000000, max: 1800000001 },
};
export const EngineCharacteristics: MessageFormat<any> = {
    value: 0,
    fieldName: "Engine Characteristics",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const CopValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Cop Value",
    ty: ["number", null],
    constraints: { min: 0, max: 8 },
};
export const EuroValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Euro Value",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const ReferencePosition: MessageFormat<any> = {
    value: {
        latitude: { ...Latitude, optional: false },
        longitude: { ...Longitude, optional: false },
        position_confidence_ellipse: {
            ...PosConfidenceEllipse,
            optional: false,
        },
        altitude: { ...Altitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Reference Position",
};
export const EuVehicleCategoryL: MessageFormat<any> = {
    value: 0,
    fieldName: "Eu Vehicle Category L",
    ty: ["number", null],
    constraints: { min: 0, max: 6 },
};
export const EuVehicleCategoryM: MessageFormat<any> = {
    value: 0,
    fieldName: "Eu Vehicle Category M",
    ty: ["number", null],
    constraints: { min: 0, max: 2 },
};
export const EuVehicleCategoryN: MessageFormat<any> = {
    value: 0,
    fieldName: "Eu Vehicle Category N",
    ty: ["number", null],
    constraints: { min: 0, max: 2 },
};
export const EuVehicleCategoryO: MessageFormat<any> = {
    value: 0,
    fieldName: "Eu Vehicle Category O",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const ComparisonOperator: MessageFormat<any> = {
    value: 0,
    fieldName: "Comparison Operator",
    ty: ["number", null],
    constraints: { min: 0, max: 3 },
};
export const MultimediaDataUnit: MessageFormat<any> = {
    value: {
        MediaContentUTF8: {
            value: "",
            ty: ["string", null],
            fieldName: "Media Content U TF8",
        },
        MediaContentOctet: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Media Content Octet",
        },
    },
    ty: ["choice", null],
    fieldName: "Multimedia Data Unit",
};
export const Int1: MessageFormat<any> = {
    value: 0,
    fieldName: "Int1",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const Int2: MessageFormat<any> = {
    value: 0,
    fieldName: "Int2",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const UnitType: MessageFormat<any> = {
    value: 0,
    fieldName: "Unit Type",
    ty: ["number", null],
    constraints: { min: 0, max: 1 },
};
export const FrontOverhang: MessageFormat<any> = {
    value: 0,
    fieldName: "Front Overhang",
    ty: ["number", null],
    constraints: { min: 0, max: 50 },
};
export const HitchPointOffset: MessageFormat<any> = {
    value: 0,
    fieldName: "Hitch Point Offset",
    ty: ["number", null],
    constraints: { min: 0, max: 100 },
};
export const RearOverhang: MessageFormat<any> = {
    value: 0,
    fieldName: "Rear Overhang",
    ty: ["number", null],
    constraints: { min: 0, max: 150 },
};
export const RefPointId: MessageFormat<any> = {
    value: 0,
    fieldName: "Ref Point Id",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const Uint16: MessageFormat<any> = {
    value: 0,
    fieldName: "Uint16",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};
export const Uint3: MessageFormat<any> = {
    value: 0,
    fieldName: "Uint3",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const Uint8: MessageFormat<any> = {
    value: 0,
    fieldName: "Uint8",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const VcClass: MessageFormat<any> = {
    value: 0,
    fieldName: "Vc Class",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const CauseCode: MessageFormat<any> = {
    value: {
        cause_code: { ...CauseCodeType, optional: false },
        sub_cause_code: { ...SubCauseCodeType, optional: false },
    },
    ty: ["object", null],
    fieldName: "Cause Code",
};
export const VcOption: MessageFormat<any> = {
    value: 0,
    fieldName: "Vc Option",
    ty: ["number", null],
    constraints: { min: 0, max: 7 },
};
export const VehicleLengthConfidenceIndication: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Length Confidence Indication",
    ty: ["number", null],
    constraints: { min: 0, max: 4 },
};
export const VehicleLengthValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Length Value",
    ty: ["number", null],
    constraints: { min: 1, max: 1023 },
};
export const VehicleRole: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Role",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const Ext2: MessageFormat<any> = {
    value: {
        content: {
            value: 0,
            ty: ["number", null],
            fieldName: "Content",
            constraints: { min: 16512, max: 2113663 },
        },
        Extension: Ext3,
    },
    ty: ["choice", null],
    fieldName: "Ext2",
};
export const Ext1: MessageFormat<any> = {
    value: {
        content: {
            value: 0,
            ty: ["number", null],
            fieldName: "Content",
            constraints: { min: 128, max: 16511 },
        },
        Extension: Ext2,
    },
    ty: ["choice", null],
    fieldName: "Ext1",
};
export const XSensorOffset: MessageFormat<any> = {
    value: 0,
    fieldName: "X Sensor Offset",
    ty: ["number", null],
    constraints: { min: -5000, max: 0 },
};
export const YSensorOffset: MessageFormat<any> = {
    value: 0,
    fieldName: "Y Sensor Offset",
    ty: ["number", null],
    constraints: { min: -1000, max: 1000 },
};
export const ZSensorOffset: MessageFormat<any> = {
    value: 0,
    fieldName: "Z Sensor Offset",
    ty: ["number", null],
    constraints: { min: 0, max: 1000 },
};
export const VehicleSubclassType: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Subclass Type",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const VehicleType: MessageFormat<any> = {
    value: 0,
    fieldName: "Vehicle Type",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const VerticalAccelerationValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Vertical Acceleration Value",
    ty: ["number", null],
    constraints: { min: -160, max: 161 },
};
export const VruProfile: MessageFormat<any> = {
    value: 0,
    fieldName: "Vru Profile",
    ty: ["number", null],
    constraints: { min: 0, max: 15 },
};
export const WsaCountThreshold: MessageFormat<any> = {
    value: 0,
    fieldName: "Wsa Count Threshold",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};
export const YawRateConfidence: MessageFormat<any> = {
    value: 0,
    fieldName: "Yaw Rate Confidence",
    ty: ["number", null],
    constraints: { min: 0, max: 8 },
};
export const YawRateValue: MessageFormat<any> = {
    value: 0,
    fieldName: "Yaw Rate Value",
    ty: ["number", null],
    constraints: { min: -32766, max: 32767 },
};
export const CS5: MessageFormat<any> = {
    value: {
        vin: {
            value: "",
            ty: ["string", null],
            fieldName: "Vin",
            optional: false,
        },
        fill: {
            value: "",
            ty: ["bitstring", null],
            fieldName: "Fill",
            optional: false,
            constraints: { min: 9, max: 9 },
        },
    },
    ty: ["object", null],
    fieldName: "C S5",
};
export const EdcaParameterRecord: MessageFormat<any> = {
    value: {
        res: {
            value: 0,
            ty: ["number", null],
            fieldName: "Res",
            optional: false,
            constraints: { min: 0, max: 1 },
        },
        aci: {
            value: 0,
            ty: ["number", null],
            fieldName: "Aci",
            optional: false,
            constraints: { min: 0, max: 3 },
        },
        acm: {
            value: 0,
            ty: ["number", null],
            fieldName: "Acm",
            optional: false,
            constraints: { min: 0, max: 1 },
        },
        aifsn: {
            value: 0,
            ty: ["number", null],
            fieldName: "Aifsn",
            optional: false,
            constraints: { min: 0, max: 15 },
        },
        ecw_max: {
            value: 0,
            ty: ["number", null],
            fieldName: "Ecw Max",
            optional: false,
            constraints: { min: 0, max: 15 },
        },
        ecw_min: {
            value: 0,
            ty: ["number", null],
            fieldName: "Ecw Min",
            optional: false,
            constraints: { min: 0, max: 15 },
        },
        txop_limit: {
            value: 0,
            ty: ["number", null],
            fieldName: "Txop Limit",
            optional: false,
            constraints: { min: 0, max: 65535 },
        },
    },
    ty: ["object", null],
    fieldName: "Edca Parameter Record",
};
export const EdcaParameterSet: MessageFormat<any> = {
    value: {
        acbe_record: { ...EdcaParameterRecord, optional: false },
        acbk_record: { ...EdcaParameterRecord, optional: false },
        acvi_record: { ...EdcaParameterRecord, optional: false },
        acvo_record: { ...EdcaParameterRecord, optional: false },
    },
    ty: ["object", null],
    fieldName: "Edca Parameter Set",
};
export const GroupLinkageValue: MessageFormat<any> = {
    value: {
        j_value: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "J Value",
            optional: false,
            constraints: { min: 4, max: 4 },
        },
        value: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Value",
            optional: false,
            constraints: { min: 9, max: 9 },
        },
    },
    ty: ["object", null],
    fieldName: "Group Linkage Value",
};
export const ACKResponseService: MessageFormat<any> = {
    value: {
        ack_resp_delay_adjust: {
            value: 0,
            ty: ["number", null],
            fieldName: "Ack Resp Delay Adjust",
            optional: false,
            constraints: { min: -32768, max: 32767 },
        },
        ack_resp_delay_std_dev: {
            value: 0,
            ty: ["number", null],
            fieldName: "Ack Resp Delay StdDev",
            optional: false,
            constraints: { min: 0, max: 65535 },
        },
    },
    ty: ["object", null],
    fieldName: "A C K ResponseService",
};
export const IValue: MessageFormat<any> = { ...Uint16, fieldName: "I Value" };
export const Dot2Latitude: MessageFormat<any> = {
    ...NinetyDegreeInt,
    fieldName: "Dot2Latitude",
};
export const ElevInt: MessageFormat<any> = { ...Uint16, fieldName: "Elev Int" };
export const Dot2Elevation: MessageFormat<any> = {
    ...ElevInt,
    fieldName: "Dot2Elevation",
};
export const Dot2Longitude: MessageFormat<any> = {
    ...OneEightyDegreeInt,
    fieldName: "Dot2Longitude",
};
export const Dot2ThreeDLocation: MessageFormat<any> = {
    value: {
        latitude: { ...Dot2Latitude, optional: false },
        longitude: { ...Dot2Longitude, optional: false },
        elevation: { ...Dot2Elevation, optional: false },
    },
    ty: ["object", null],
    fieldName: "Dot2ThreeDLocation",
};
export const Heading: MessageFormat<any> = {
    value: {
        heading_value: { ...HeadingValue, optional: false },
        heading_confidence: { ...HeadingConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Heading",
};
export const RoadAngles: MessageFormat<any> = {
    value: Heading,
    ty: ["array", ["object", null]],
    fieldName: "Road Angles",
};
export const ITSRangingSAMAppData: MessageFormat<any> = {
    value: {
        protocol_version: {
            value: 0,
            ty: ["number", null],
            fieldName: "Protocol Version",
            optional: false,
            constraints: { min: 0, max: 255 },
        },
        ack_response_service: { ...ACKResponseService, optional: false },
        ground_altitude: { ...Altitude, optional: true },
        road_angles: { ...RoadAngles, optional: true },
    },
    ty: ["object", null],
    fieldName: "I T S RangingSAMAppData",
};
export const KnownLatitude: MessageFormat<any> = {
    ...NinetyDegreeInt,
    fieldName: "Known Latitude",
};
export const KnownLongitude: MessageFormat<any> = {
    ...OneEightyDegreeInt,
    fieldName: "Known Longitude",
};
export const VarLengthNumber: MessageFormat<any> = {
    value: {
        content: {
            value: 0,
            ty: ["number", null],
            fieldName: "Content",
            constraints: { min: 0, max: 127 },
        },
        Extension: Ext1,
    },
    ty: ["choice", null],
    fieldName: "Var Length Number",
};
export const ItsPduHeader: MessageFormat<any> = {
    value: {
        protocol_version: {
            value: 0,
            ty: ["number", null],
            fieldName: "Protocol Version",
            optional: false,
            constraints: { min: 0, max: 255 },
        },
        message_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Message ID",
            optional: false,
            constraints: { min: 0, max: 255 },
        },
        station_id: { ...StationID, optional: false },
    },
    ty: ["object", null],
    fieldName: "Its Pdu Header",
};
export const Distance: MessageFormat<any> = {
    value: {
        value: {
            value: 0,
            ty: ["number", null],
            fieldName: "Value",
            optional: false,
            constraints: { min: 1, max: 16384 },
        },
        unit: { ...RSCUnit, optional: false },
    },
    ty: ["object", null],
    fieldName: "Distance",
};
export const SequenceOfPsid: MessageFormat<any> = {
    value: Psid,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Psid",
};
export const Duration: MessageFormat<any> = {
    value: {
        Microseconds: Uint16,
        Milliseconds: Uint16,
        Seconds: Uint16,
        Minutes: Uint16,
        Hours: Uint16,
        SixtyHours: Uint16,
        Years: Uint16,
    },
    ty: ["choice", null],
    fieldName: "Duration",
};
export const SteeringWheelAngle: MessageFormat<any> = {
    value: {
        steering_wheel_angle_value: {
            ...SteeringWheelAngleValue,
            optional: false,
        },
        steering_wheel_angle_confidence: {
            ...SteeringWheelAngleConfidence,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Steering Wheel Angle",
};
export const MCDMApplicationContainer: MessageFormat<any> = {
    value: {},
    ty: ["object", null],
    fieldName: "M C D MApplicationContainer",
};
export const VruRollAngle: MessageFormat<any> = {
    ...SteeringWheelAngle,
    fieldName: "Vru Roll Angle",
};
export const MCDMMultimediaContainer: MessageFormat<any> = {
    value: MultimediaDataUnit,
    ty: ["array", ["object", null]],
    fieldName: "M C D MMultimediaContainer",
    constraints: { min: 1, max: 7 },
};
export const MCDMSituationContainer: MessageFormat<any> = {
    value: {
        event_type: { ...CauseCode, optional: false },
        linked_cause: { ...CauseCode, optional: true },
        authorized_percentage_loss: {
            value: 0,
            ty: ["number", null],
            fieldName: "Authorized Percentage Loss",
            optional: true,
            constraints: { min: 0, max: 100 },
        },
        information_quality: { ...InformationQuality, optional: false },
    },
    ty: ["object", null],
    fieldName: "M C D MSituationContainer",
};
export const ProtocolType: MessageFormat<any> = {
    ...VarLengthNumber,
    fieldName: "Protocol Type",
};
export const ProviderMacAddress: MessageFormat<any> = {
    ...MACaddress,
    fieldName: "Provider Mac Address",
};
export const ProviderServiceContext: MessageFormat<any> = {
    value: {
        fill_bit: {
            value: "",
            ty: ["bitstring", null],
            fieldName: "Fill Bit",
            optional: false,
            constraints: { min: 3, max: 3 },
        },
        psc: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Psc",
            optional: false,
            constraints: { min: 0, max: 31 },
        },
    },
    ty: ["object", null],
    fieldName: "Provider Service Context",
};
export const SecondaryDns: MessageFormat<any> = {
    ...IPv6Address,
    fieldName: "Secondary Dns",
};
export const Time64: MessageFormat<any> = { ...Uint64, fieldName: "Time64" };
export const Time32: MessageFormat<any> = { ...Uint32, fieldName: "Time32" };
export const ExplicitEdcaIdentifier: MessageFormat<any> = {
    value: {
        qos_info: { ...Uint8, optional: false },
        reserved: { ...Uint8, optional: false },
        set1: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Set1",
            optional: false,
            constraints: { min: 4, max: 4 },
        },
        set2: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Set2",
            optional: false,
            constraints: { min: 4, max: 4 },
        },
        set3: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Set3",
            optional: false,
            constraints: { min: 4, max: 4 },
        },
        set4: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Set4",
            optional: false,
            constraints: { min: 4, max: 4 },
        },
    },
    ty: ["object", null],
    fieldName: "Explicit Edca Identifier",
};
export const Node: MessageFormat<any> = {
    value: {
        id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Id",
            optional: false,
        },
        lane: { ...LaneID, optional: true },
        connection_id: { ...LaneConnectionID, optional: true },
        intersection_id: { ...IntersectionID, optional: true },
    },
    ty: ["object", null],
    fieldName: "Node",
};
export const EdcaIdentifier: MessageFormat<any> = {
    value: { Enum: EnumeratedEdcaIdentifier, Explicit: ExplicitEdcaIdentifier },
    ty: ["choice", null],
    fieldName: "Edca Identifier",
};
export const NodeAttributeXYList: MessageFormat<any> = {
    value: NodeAttributeXY,
    ty: ["array", ["object", null]],
    fieldName: "Node Attribute X YList",
    constraints: { min: 1, max: 8 },
};
export const ValidityPeriod: MessageFormat<any> = {
    value: {
        start: { ...Time32, optional: false },
        duration: { ...Duration, optional: false },
    },
    ty: ["object", null],
    fieldName: "Validity Period",
};
export const ChannelIdentifier: MessageFormat<any> = {
    value: {
        country_string: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Country String",
            optional: false,
            constraints: { min: 3, max: 3 },
        },
        operating_class: { ...Uint8, optional: false },
        channel_number: { ...Uint8, optional: false },
    },
    ty: ["object", null],
    fieldName: "Channel Identifier",
};
export const SequenceOfEdcaIdentifier: MessageFormat<any> = {
    value: EdcaIdentifier,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Edca Identifier",
};
export const AdvertiserPermissions: MessageFormat<any> = {
    value: ChannelIdentifier,
    ty: ["array", ["object", null]],
    fieldName: "Advertiser Permissions",
};
export const ChannelSpecificProviderPermission: MessageFormat<any> = {
    value: {
        channel_id: { ...ChannelIdentifier, optional: false },
        permitted_psids: { ...SequenceOfPsid, optional: true },
        permitted_edca_parameters: {
            ...SequenceOfEdcaIdentifier,
            optional: true,
        },
        maximum_transmit_power: { ...Uint8, optional: true },
    },
    ty: ["object", null],
    fieldName: "Channel Specific Provider Permission",
};
export const ProviderPermissions: MessageFormat<any> = {
    value: ChannelSpecificProviderPermission,
    ty: ["array", ["object", null]],
    fieldName: "Provider Permissions",
};
export const WsaSsp: MessageFormat<any> = {
    value: {
        version: { ...Uint8, optional: false },
        advertiser_permissions: { ...AdvertiserPermissions, optional: true },
        provider_permissions: { ...ProviderPermissions, optional: true },
    },
    ty: ["object", null],
    fieldName: "Wsa Ssp",
};
export const UnknownLongitude: MessageFormat<any> = {
    ...OneEightyDegreeInt,
    fieldName: "Unknown Longitude",
};
export const UnknownLatitude: MessageFormat<any> = {
    ...NinetyDegreeInt,
    fieldName: "Unknown Latitude",
};
export const Speed: MessageFormat<any> = {
    value: {
        speed_value: { ...SpeedValue, optional: false },
        speed_confidence: { ...SpeedConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Speed",
};
export const PositionOfPillars: MessageFormat<any> = {
    value: PosPillar,
    ty: ["array", ["number", null]],
    fieldName: "Position Of Pillars",
    constraints: { min: 1, max: 3 },
};
export const LaneAttributesAddGrpC: MessageFormat<any> = {
    value: {
        max_vehicle_height: { ...VehicleHeight, optional: true },
        max_vehicle_weight: { ...VehicleMass, optional: true },
    },
    ty: ["object", null],
    fieldName: "Lane Attributes Add GrpC",
};
export const SequenceOfHashedId3: MessageFormat<any> = {
    value: HashedId3,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Hashed Id3",
};
export const ImpactReductionContainer: MessageFormat<any> = {
    value: {
        height_lon_carr_left: { ...HeightLonCarr, optional: false },
        height_lon_carr_right: { ...HeightLonCarr, optional: false },
        pos_lon_carr_left: { ...PosLonCarr, optional: false },
        pos_lon_carr_right: { ...PosLonCarr, optional: false },
        position_of_pillars: { ...PositionOfPillars, optional: false },
        pos_cent_mass: { ...PosCentMass, optional: false },
        wheel_base_vehicle: { ...WheelBaseVehicle, optional: false },
        turning_radius: { ...TurningRadius, optional: false },
        pos_front_ax: { ...PosFrontAx, optional: false },
        position_of_occupants: { ...PositionOfOccupants, optional: false },
        vehicle_mass: { ...VehicleMass, optional: false },
        request_response_indication: {
            ...RequestResponseIndication,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Impact Reduction Container",
};
export const ClosedLanes: MessageFormat<any> = {
    value: {
        innerhard_shoulder_status: { ...HardShoulderStatus, optional: true },
        outerhard_shoulder_status: { ...HardShoulderStatus, optional: true },
        driving_lane_status: { ...DrivingLaneStatus, optional: true },
    },
    ty: ["object", null],
    fieldName: "Closed Lanes",
};
export const DeltaReferencePosition: MessageFormat<any> = {
    value: {
        delta_latitude: { ...DeltaLatitude, optional: false },
        delta_longitude: { ...DeltaLongitude, optional: false },
        delta_altitude: { ...DeltaAltitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Delta Reference Position",
};
export const ItineraryPath: MessageFormat<any> = {
    value: ReferencePosition,
    ty: ["array", ["object", null]],
    fieldName: "Itinerary Path",
    constraints: { min: 1, max: 40 },
};
export const ActionID: MessageFormat<any> = {
    value: {
        originating_station_id: { ...StationID, optional: false },
        sequence_number: { ...SequenceNumber, optional: false },
    },
    ty: ["object", null],
    fieldName: "Action ID",
};
export const ReferenceDenms: MessageFormat<any> = {
    value: ActionID,
    ty: ["array", ["object", null]],
    fieldName: "Reference Denms",
    constraints: { min: 1, max: 8 },
};
export const RestrictedTypes: MessageFormat<any> = {
    value: StationType,
    ty: ["array", ["number", null]],
    fieldName: "Restricted Types",
    constraints: { min: 1, max: 3 },
};
export const Text: MessageFormat<any> = {
    value: {
        layout_component_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Layout Component Id",
            optional: true,
            constraints: { min: 1, max: 4 },
        },
        language: {
            value: "",
            ty: ["bitstring", null],
            fieldName: "Language",
            optional: false,
            constraints: { min: 10, max: 10 },
        },
        text_content: {
            value: "",
            ty: ["string", null],
            fieldName: "Text Content",
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Text",
};
export const RoadWorksContainerExtended: MessageFormat<any> = {
    value: {
        light_bar_siren_in_use: { ...LightBarSirenInUse, optional: true },
        closed_lanes: { ...ClosedLanes, optional: true },
        restriction: { ...RestrictedTypes, optional: true },
        speed_limit: { ...SpeedLimit, optional: true },
        incident_indication: { ...CauseCode, optional: true },
        recommended_path: { ...ItineraryPath, optional: true },
        starting_point_speed_limit: {
            ...DeltaReferencePosition,
            optional: true,
        },
        traffic_flow_rule: { ...TrafficRule, optional: true },
        reference_denms: { ...ReferenceDenms, optional: true },
    },
    ty: ["object", null],
    fieldName: "Road Works Container Extended",
};
export const DangerousGoodsExtended: MessageFormat<any> = {
    value: {
        dangerous_goods_type: { ...DangerousGoodsBasic, optional: false },
        un_number: {
            value: 0,
            ty: ["number", null],
            fieldName: "Un Number",
            optional: false,
            constraints: { min: 0, max: 9999 },
        },
        elevated_temperature: {
            value: false,
            ty: ["boolean", null],
            fieldName: "Elevated Temperature",
            optional: false,
        },
        tunnels_restricted: {
            value: false,
            ty: ["boolean", null],
            fieldName: "Tunnels Restricted",
            optional: false,
        },
        limited_quantity: {
            value: false,
            ty: ["boolean", null],
            fieldName: "Limited Quantity",
            optional: false,
        },
        emergency_action_code: {
            value: "",
            ty: ["string", null],
            fieldName: "Emergency Action Code",
            optional: true,
            constraints: { min: 1, max: 24 },
        },
        phone_number: { ...PhoneNumber, optional: true },
        company_name: {
            value: "",
            ty: ["string", null],
            fieldName: "Company Name",
            optional: true,
            constraints: { min: 1, max: 24 },
        },
    },
    ty: ["object", null],
    fieldName: "Dangerous Goods Extended",
};
export const VehicleIdentification: MessageFormat<any> = {
    value: {
        w_m_inumber: { ...WMInumber, optional: true },
        v_d_s: { ...VDS, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vehicle Identification",
};
export const StationaryVehicleContainer: MessageFormat<any> = {
    value: {
        stationary_since: { ...StationarySince, optional: true },
        stationary_cause: { ...CauseCode, optional: true },
        carrying_dangerous_goods: { ...DangerousGoodsExtended, optional: true },
        number_of_occupants: { ...NumberOfOccupants, optional: true },
        vehicle_identification: { ...VehicleIdentification, optional: true },
        energy_storage_type: { ...EnergyStorageType, optional: true },
    },
    ty: ["object", null],
    fieldName: "Stationary Vehicle Container",
};
export const ItsPOIHeader: MessageFormat<any> = {
    value: {
        poi_type: { ...POIType, optional: false },
        time_stamp: { ...TimestampIts, optional: false },
        relay_capable: {
            value: false,
            ty: ["boolean", null],
            fieldName: "Relay Capable",
        },
        optional: false,
    },
    ty: ["object", null],
    fieldName: "Its P O IHeader",
};
export const Dot2TwoDLocation: MessageFormat<any> = {
    value: {
        latitude: { ...Dot2Latitude, optional: false },
        longitude: { ...Dot2Longitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Dot2TwoDLocation",
};
export const MonthDay: MessageFormat<any> = {
    value: {
        month: {
            value: 0,
            ty: ["number", null],
            fieldName: "Month",
            optional: false,
            constraints: { min: 1, max: 12 },
        },
        day: {
            value: 0,
            ty: ["number", null],
            fieldName: "Day",
            optional: false,
            constraints: { min: 1, max: 31 },
        },
    },
    ty: ["object", null],
    fieldName: "Month Day",
};
export const VerticalAcceleration: MessageFormat<any> = {
    value: {
        vertical_acceleration_value: {
            ...VerticalAccelerationValue,
            optional: false,
        },
        vertical_acceleration_confidence: {
            ...AccelerationConfidence,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Vertical Acceleration",
};
export const StabilityChangeIndication: MessageFormat<any> = {
    value: {
        loss_probability: { ...StabilityLossProbability, optional: false },
        action_delta_time: { ...ActionDeltaTime, optional: false },
    },
    ty: ["object", null],
    fieldName: "Stability Change Indication",
};
export const VruSafeDistanceIndication: MessageFormat<any> = {
    value: {
        subject_station: { ...StationID, optional: true },
        station_safe_distance_indication: {
            value: false,
            fieldName: "Station Safe Distance Indication",
            ty: ["boolean", null],
            optional: false,
        },
        time_to_collision: { ...ActionDeltaTime, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vru Safe Distance Indication",
};
export const SequenceOfVruSafeDistanceIndication: MessageFormat<any> = {
    value: VruSafeDistanceIndication,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Vru SafeDistanceIndication",
    constraints: { min: 0, max: 8 },
};
export const IntersectionReferenceID: MessageFormat<any> = {
    value: {
        region: { ...RoadRegulatorID, optional: true },
        id: { ...IntersectionID, optional: false },
    },
    ty: ["object", null],
    fieldName: "Intersection Reference ID",
};
export const PathPoint: MessageFormat<any> = {
    value: {
        path_position: { ...DeltaReferencePosition, optional: false },
        path_delta_time: { ...PathDeltaTime, optional: true },
    },
    ty: ["object", null],
    fieldName: "Path Point",
};
export const PathHistory: MessageFormat<any> = {
    value: PathPoint,
    ty: ["array", ["object", null]],
    fieldName: "Path History",
    constraints: { min: 0, max: 40 },
};
export const PathPrediction: MessageFormat<any> = {
    ...PathHistory,
    fieldName: "Path Prediction",
};
export const VruOrientation: MessageFormat<any> = {
    ...Heading,
    fieldName: "Vru Orientation",
};
export const MapPosition: MessageFormat<any> = {
    value: {
        intersection_id: { ...IntersectionReferenceID, optional: false },
        lane: { ...LaneID, optional: false },
    },
    ty: ["object", null],
    fieldName: "Map Position",
};
export const NonIslandLanePosition: MessageFormat<any> = {
    value: {
        OffRoadLanePosition,
        VehicularLanePosition: LanePosition,
        MapPosition,
    },
    ty: ["choice", null],
    fieldName: "Non Island Lane Position",
};
export const YawRate: MessageFormat<any> = {
    value: {
        yaw_rate_value: { ...YawRateValue, optional: false },
        yaw_rate_confidence: { ...YawRateConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Yaw Rate",
};
export const TrafficIslandPosition: MessageFormat<any> = {
    value: {
        one_side: { ...NonIslandLanePosition, optional: false },
        other_side: { ...NonIslandLanePosition, optional: false },
    },
    ty: ["object", null],
    fieldName: "Traffic Island Position",
};
export const Curvature: MessageFormat<any> = {
    value: {
        curvature_value: { ...CurvatureValue, optional: false },
        curvature_confidence: { ...CurvatureConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Curvature",
};
export const LongitudinalAcceleration: MessageFormat<any> = {
    value: {
        longitudinal_acceleration_value: {
            ...LongitudinalAccelerationValue,
            optional: false,
        },
        longitudinal_acceleration_confidence: {
            ...AccelerationConfidence,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Longitudinal Acceleration",
};
export const LongitudinalLanePosition: MessageFormat<any> = {
    value: {
        longitudinal_lane_position_value: {
            ...LongitudinalLanePositionValue,
            optional: false,
        },
        longitudinal_lane_position_confidence: {
            ...LongitudinalLanePositionConfidence,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Longitudinal Lane Position",
};
export const VruLanePosition: MessageFormat<any> = {
    value: {
        OffRoadLanePosition,
        VehicularLanePosition: LanePosition,
        TrafficIslandPosition,
        MapPosition,
    },
    ty: ["choice", null],
    fieldName: "Vru Lane Position",
};
export const WGS84Angle: MessageFormat<any> = {
    value: {
        value: { ...WGS84AngleValue, optional: false },
        confidence: { ...AngleConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "W G S84Angle",
};
export const LateralAcceleration: MessageFormat<any> = {
    value: {
        lateral_acceleration_value: {
            ...LateralAccelerationValue,
            optional: false,
        },
        lateral_acceleration_confidence: {
            ...AccelerationConfidence,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Lateral Acceleration",
};
export const ClusterBreakupInfo: MessageFormat<any> = {
    value: {
        cluster_breakup_reason: { ...ClusterBreakupReason, optional: false },
        breakup_time: { ...VruClusterOpTimestamp, optional: false },
    },
    ty: ["object", null],
    fieldName: "Cluster Breakup Info",
};
export const ClusterJoinInfo: MessageFormat<any> = {
    value: {
        cluster_id: { ...ClusterId, optional: false },
        join_time: { ...VruClusterOpTimestamp, optional: false },
    },
    ty: ["object", null],
    fieldName: "Cluster Join Info",
};
export const ClusterLeaveInfo: MessageFormat<any> = {
    value: {
        cluster_id: { ...ClusterId, optional: false },
        cluster_leave_reason: { ...ClusterLeaveReason, optional: false },
    },
    ty: ["object", null],
    fieldName: "Cluster Leave Info",
};
export const VruClusterOperationContainer: MessageFormat<any> = {
    value: {
        cluster_join_info: { ...ClusterJoinInfo, optional: true },
        cluster_leave_info: { ...ClusterLeaveInfo, optional: true },
        cluster_breakup_info: { ...ClusterBreakupInfo, optional: true },
        cluster_id_change_info: { ...VruClusterOpTimestamp, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vru Cluster Operation Container",
};
export const VruHighFrequencyContainer: MessageFormat<any> = {
    value: {
        heading: { ...Heading, optional: false },
        speed: { ...Speed, optional: false },
        longitudinal_acceleration: {
            ...LongitudinalAcceleration,
            optional: false,
        },
        curvature: { ...Curvature, optional: true },
        curvature_calculation_mode: {
            ...CurvatureCalculationMode,
            optional: true,
        },
        yaw_rate: { ...YawRate, optional: true },
        lateral_acceleration: { ...LateralAcceleration, optional: true },
        vertical_acceleration: { ...VerticalAcceleration, optional: true },
        vru_lane_position: { ...VruLanePosition, optional: true },
        environment: { ...VruEnvironment, optional: true },
        movement_control: { ...VruMovementControl, optional: true },
        orientation: { ...VruOrientation, optional: true },
        roll_angle: { ...VruRollAngle, optional: true },
        device_usage: { ...VruDeviceUsage, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vru High Frequency Container",
};
export const VruExteriorLights: MessageFormat<any> = {
    value: {
        vru_specific: { ...VruSpecificExteriorLights, optional: false },
        generic: { ...ExteriorLights, optional: false },
    },
    ty: ["object", null],
    fieldName: "Vru Exterior Lights",
};
export const VruProfileAndSubprofile: MessageFormat<any> = {
    value: {
        Pedestrian: VruSubProfilePedestrian,
        Bicyclist: VruSubProfileBicyclist,
        Motorcylist: VruSubProfileMotorcyclist,
        Animal: VruSubProfileAnimal,
    },
    ty: ["choice", null],
    fieldName: "Vru Profile And Subprofile",
};
export const VruLowFrequencyContainer: MessageFormat<any> = {
    value: {
        profile_and_subprofile: { ...VruProfileAndSubprofile, optional: true },
        exterior_lights: { ...VruExteriorLights, optional: true },
        size_class: { ...VruSizeClass, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vru Low Frequency Container",
};
export const AccelerationChangeIndication: MessageFormat<any> = {
    value: {
        accel_or_decel: { ...AccelOrDecel, optional: false },
        action_delta_time: { ...ActionDeltaTime, optional: false },
    },
    ty: ["object", null],
    fieldName: "Acceleration Change Indication",
};
export const TrajectoryInterceptionIndication: MessageFormat<any> = {
    value: {
        subject_station: { ...StationID, optional: true },
        trajectory_interception_indication: {
            value: false,
            fieldName: "Trajectory Interception Indication",
            ty: ["boolean", null],
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Trajectory Interception Indication",
};
export const SequenceOfTrajectoryInterceptionIndication: MessageFormat<any> = {
    value: TrajectoryInterceptionIndication,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Trajectory InterceptionIndication",
    constraints: { min: 1, max: 8 },
};
export const HeadingChangeIndication: MessageFormat<any> = {
    value: {
        direction: { ...LeftOrRight, optional: false },
        action_delta_time: { ...ActionDeltaTime, optional: false },
    },
    ty: ["object", null],
    fieldName: "Heading Change Indication",
};
export const RoadSegmentReferenceID: MessageFormat<any> = {
    value: {
        region: { ...RoadRegulatorID, optional: true },
        id: { ...RoadSegmentID, optional: false },
    },
    ty: ["object", null],
    fieldName: "Road Segment Reference ID",
};
export const AdvisorySpeed: MessageFormat<any> = {
    value: {
        type: { ...AdvisorySpeedType, optional: false },
        speed: { ...SpeedAdvice, optional: true },
        confidence: { ...SAESpeedConfidence, optional: true },
        distance: { ...ZoneLength, optional: true },
        class: { ...RestrictionClassID, optional: true },
    },
    ty: ["object", null],
    fieldName: "Advisory Speed",
};
export const AnimalSubclass: MessageFormat<any> = {
    value: {
        type: { ...AnimalSubclassType, optional: true },
        confidence: { ...ClassConfidence, optional: true },
    },
    ty: ["object", null],
    fieldName: "Animal Subclass",
};
export const AttributeIdList: MessageFormat<any> = {
    value: AnonymousAttributeIdList,
    ty: ["array", ["object", null]],
    fieldName: "Attribute Id List",
    constraints: { min: 0, max: 127 },
};
export const CartesianAngle: MessageFormat<any> = {
    value: {
        value: { ...CartesianAngleValue, optional: false },
        confidence: { ...AngleConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Cartesian Angle",
};
export const CenDsrcTollingZoneID: MessageFormat<any> = {
    ...ProtectedZoneID,
    fieldName: "Cen Dsrc Tolling ZoneID",
};
export const CenDsrcTollingZone: MessageFormat<any> = {
    value: {
        protected_zone_latitude: { ...Latitude, optional: false },
        protected_zone_longitude: { ...Longitude, optional: false },
        cen_dsrc_tolling_zone_id: { ...CenDsrcTollingZoneID, optional: true },
    },
    ty: ["object", null],
    fieldName: "Cen Dsrc Tolling Zone",
};
export const ChInfoOptions: MessageFormat<any> = {
    value: {
        option1: { value: null, ty: ["null", null], fieldName: "Option1" },
        option2: { value: null, ty: ["null", null], fieldName: "Option2" },
        option3: { value: null, ty: ["null", null], fieldName: "Option3" },
        option4: { value: null, ty: ["null", null], fieldName: "Option4" },
        option5: { value: null, ty: ["null", null], fieldName: "Option5" },
        option6: { value: null, ty: ["null", null], fieldName: "Option6" },
        option7: { value: null, ty: ["null", null], fieldName: "Option7" },
    },
    ty: ["object", null],
    fieldName: "Ch Info Options",
};
export const WsaChInfoDataRate: MessageFormat<any> = {
    value: {
        adaptable: {
            value: "",
            ty: ["bitstring", null],
            fieldName: "Adaptable",
            optional: false,
            constraints: { min: 1, max: 1 },
        },
        data_rate: {
            value: 0,
            ty: ["number", null],
            fieldName: "Data Rate",
            optional: false,
            constraints: { min: 0, max: 127 },
        },
    },
    ty: ["object", null],
    fieldName: "Wsa Ch Info DataRate",
};
export const ChannelInfo: MessageFormat<any> = {
    value: {
        operating_class: { ...OperatingClass80211, optional: false },
        channel_number: { ...ChannelNumber80211, optional: false },
        power_level: { ...TXpower80211, optional: false },
        data_rate: { ...WsaChInfoDataRate, optional: false },
        extensions: { ...ChInfoOptions, optional: false },
    },
    ty: ["object", null],
    fieldName: "Channel Info",
};
export const OffsetXaxis: MessageFormat<any> = {
    value: { Small: DrivenLineOffsetSm, Large: DrivenLineOffsetLg },
    ty: ["choice", null],
    fieldName: "Offset Xaxis",
};
export const OffsetYaxis: MessageFormat<any> = {
    value: { Small: DrivenLineOffsetSm, Large: DrivenLineOffsetLg },
    ty: ["choice", null],
    fieldName: "Offset Yaxis",
};
export const ComputedLane: MessageFormat<any> = {
    value: {
        reference_lane_id: { ...LaneID, optional: false },
        offset_xaxis: { ...OffsetXaxis, optional: false },
        offset_yaxis: { ...OffsetYaxis, optional: false },
        rotate_x_y: { ...Angle, optional: true },
        scale_xaxis: { ...ScaleB12, optional: true },
        scale_yaxis: { ...ScaleB12, optional: true },
    },
    ty: ["object", null],
    fieldName: "Computed Lane",
};
export const ConnectingLane: MessageFormat<any> = {
    value: {
        lane: { ...LaneID, optional: false },
        maneuver: { ...AllowedManeuvers, optional: true },
    },
    ty: ["object", null],
    fieldName: "Connecting Lane",
};
export const Connection: MessageFormat<any> = {
    value: {
        connecting_lane: { ...ConnectingLane, optional: false },
        remote_intersection: { ...IntersectionReferenceID, optional: true },
        signal_group: { ...SignalGroupID, optional: true },
        user_class: { ...RestrictionClassID, optional: true },
        connection_id: { ...LaneConnectionID, optional: true },
    },
    ty: ["object", null],
    fieldName: "Connection",
};
export const ConnectionManeuverAssist: MessageFormat<any> = {
    value: {
        connection_id: { ...LaneConnectionID, optional: false },
        queue_length: { ...ZoneLength, optional: true },
        available_storage_length: { ...ZoneLength, optional: true },
        wait_on_stop: { ...WaitOnStopline, optional: true },
        ped_bicycle_detect: { ...PedestrianBicycleDetect, optional: true },
    },
    ty: ["object", null],
    fieldName: "Connection Maneuver Assist",
};
export const EccP256CurvePointUncompressedP256: MessageFormat<any> = {
    value: {
        x: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "X",
            optional: false,
            constraints: { min: 32, max: 32 },
        },
        y: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Y",
            optional: false,
            constraints: { min: 32, max: 32 },
        },
    },
    ty: ["object", null],
    fieldName: "Ecc P256 Curve Point Uncompressed P256",
};
export const EccP256CurvePoint: MessageFormat<any> = {
    value: {
        x_only: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "X Only",
            constraints: { min: 32, max: 32 },
        },
        fill: { value: null, ty: ["null", null], fieldName: "Fill" },
        compressed_y0: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Compressed Y0",
            constraints: { min: 32, max: 32 },
        },
        compressed_y1: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Compressed Y1",
            constraints: { min: 32, max: 32 },
        },
        UncompressedP256: EccP256CurvePointUncompressedP256,
    },
    ty: ["choice", null],
    fieldName: "Ecc P256CurvePoint",
};
export const EccP384CurvePointUncompressedP384: MessageFormat<any> = {
    value: {
        x: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "X",
            optional: false,
            constraints: { min: 48, max: 48 },
        },
        y: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Y",
            optional: false,
            constraints: { min: 48, max: 48 },
        },
    },
    ty: ["object", null],
    fieldName: "Ecc P384 Curve Point Uncompressed P384",
};
export const EccP384CurvePoint: MessageFormat<any> = {
    value: {
        x_only: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "X Only",
            constraints: { min: 48, max: 48 },
        },
        fill: { value: null, ty: ["null", null], fieldName: "Fill" },
        compressed_y0: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Compressed Y0",
            constraints: { min: 48, max: 48 },
        },
        compressed_y1: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Compressed Y1",
            constraints: { min: 48, max: 48 },
        },
        UncompressedP384: EccP384CurvePointUncompressedP384,
    },
    ty: ["choice", null],
    fieldName: "Ecc P384 Curve Point",
};
export const SymmetricEncryptionKey: MessageFormat<any> = {
    value: {
        aes128_ccm: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Aes128Ccm",
            constraints: { min: 16, max: 16 },
        },
    },
    ty: ["choice", null],
    fieldName: "Symmetric Encryption Key",
};
export const ChannelInfos: MessageFormat<any> = {
    value: ChannelInfo,
    ty: ["array", ["object", null]],
    fieldName: "Channel Infos",
};
export const Position3D: MessageFormat<any> = {
    value: {
        lat: { ...Latitude, optional: false },
        long: { ...Longitude, optional: false },
        elevation: { ...Elevation, optional: true },
    },
    ty: ["object", null],
    fieldName: "Position3D",
};
export const ConnectsToList: MessageFormat<any> = {
    value: Connection,
    ty: ["array", ["object", null]],
    fieldName: "Connects To List",
    constraints: { min: 1, max: 16 },
};
export const Weight: MessageFormat<any> = {
    value: {
        value: {
            value: 0,
            ty: ["number", null],
            fieldName: "Value",
            optional: false,
            constraints: { min: 1, max: 16384 },
        },
        unit: { ...RSCUnit, optional: false },
    },
    ty: ["object", null],
    fieldName: "Weight",
};
export const DBV: MessageFormat<any> = { ...Distance, fieldName: "D B V" };
export const HoursMinutes: MessageFormat<any> = {
    value: {
        hours: {
            value: 0,
            ty: ["number", null],
            fieldName: "Hours",
            optional: false,
            constraints: { min: 0, max: 23 },
        },
        mins: {
            value: 0,
            ty: ["number", null],
            fieldName: "Mins",
            optional: false,
            constraints: { min: 0, max: 59 },
        },
    },
    ty: ["object", null],
    fieldName: "Hours Minutes",
};
export const DtmHourMinutes: MessageFormat<any> = {
    value: {
        shm: { ...HoursMinutes, optional: false },
        ehm: { ...HoursMinutes, optional: false },
    },
    ty: ["object", null],
    fieldName: "Dtm Hour Minutes",
};
export const DtmMonthDay: MessageFormat<any> = {
    value: {
        smd: { ...MonthDay, optional: false },
        emd: { ...MonthDay, optional: false },
    },
    ty: ["object", null],
    fieldName: "Dtm Month Day",
};
export const DtmYear: MessageFormat<any> = {
    value: {
        syr: {
            value: 0,
            ty: ["number", null],
            fieldName: "Syr",
            optional: false,
            constraints: { min: 2000, max: 2127 },
        },
        eyr: {
            value: 0,
            ty: ["number", null],
            fieldName: "Eyr",
            optional: false,
            constraints: { min: 2000, max: 2127 },
        },
    },
    ty: ["object", null],
    fieldName: "Dtm Year",
};
export const DTM: MessageFormat<any> = {
    value: {
        year: { ...DtmYear, optional: true },
        month_day: { ...DtmMonthDay, optional: true },
        pmd: { ...PMD, optional: true },
        hour_minutes: { ...DtmHourMinutes, optional: true },
        day_of_week: { ...DayOfWeek, optional: true },
        period: { ...HoursMinutes, optional: true },
    },
    ty: ["object", null],
    fieldName: "DTM",
};
export const EDT: MessageFormat<any> = { ...DTM, fieldName: "EDT" };
export const SPE: MessageFormat<any> = {
    value: {
        spm: {
            value: 0,
            ty: ["number", null],
            fieldName: "Spm",
            optional: true,
            constraints: { min: 0, max: 250 },
        },
        mns: {
            value: 0,
            ty: ["number", null],
            fieldName: "Mns",
            optional: true,
            constraints: { min: 0, max: 250 },
        },
        unit: { ...RSCUnit, optional: false },
    },
    ty: ["object", null],
    fieldName: "SPE",
};
export const VED: MessageFormat<any> = {
    value: {
        hei: { ...Distance, optional: true },
        wid: { ...Distance, optional: true },
        vln: { ...Distance, optional: true },
        wei: { ...Weight, optional: true },
    },
    ty: ["object", null],
    fieldName: "VED",
};
export const ServiceCategoryCode: MessageFormat<any> = {
    value: {
        TrafficSignPictogram,
        PublicFacilitiesPictogram,
        AmbientOrRoadConditionPictogram,
    },
    ty: ["choice", null],
    fieldName: "Service Category Code",
};
export const PictogramCategoryCode: MessageFormat<any> = {
    value: {
        nature: {
            value: 0,
            ty: ["number", null],
            fieldName: "Nature",
            optional: false,
            constraints: { min: 1, max: 9 },
        },
        serial_number: {
            value: 0,
            ty: ["number", null],
            fieldName: "Serial Number",
            optional: false,
            constraints: { min: 0, max: 99 },
        },
    },
    ty: ["object", null],
    fieldName: "Pictogram Category Code",
};
export const PictogramCode: MessageFormat<any> = {
    value: {
        country_code: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Country Code",
            optional: true,
            constraints: { min: 2, max: 2 },
        },
        service_category_code: { ...ServiceCategoryCode, optional: false },
        pictogram_category_code: { ...PictogramCategoryCode, optional: false },
    },
    ty: ["object", null],
    fieldName: "Pictogram Code",
};
export const DestinationRoad: MessageFormat<any> = {
    value: {
        der_type: { ...DDDDER, optional: false },
        ron_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Ron Id",
            optional: true,
            constraints: { min: 1, max: 999 },
        },
        ron_text: {
            value: "",
            ty: ["string", null],
            fieldName: "Ron Text",
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Destination Road",
};
export const DistanceOrDuration: MessageFormat<any> = {
    value: {
        value: {
            value: 0,
            ty: ["number", null],
            fieldName: "Value",
            optional: false,
            constraints: { min: 1, max: 16384 },
        },
        unit: { ...RSCUnit, optional: false },
    },
    ty: ["object", null],
    fieldName: "Distance Or Duration",
};
export const DestinationPlace: MessageFormat<any> = {
    value: {
        dep_type: { ...DDDDEP, optional: false },
        dep_blob: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Dep Blob",
            optional: true,
        },
        pln_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Pln Id",
            optional: true,
            constraints: { min: 1, max: 999 },
        },
        pln_text: {
            value: "",
            ty: ["string", null],
            fieldName: "Pln Text",
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Destination Place",
};
export const DDDIODr: MessageFormat<any> = {
    value: DestinationRoad,
    ty: ["array", ["object", null]],
    fieldName: "DDD IO Dr",
    constraints: { min: 1, max: 4 },
};
export const DDDIODp: MessageFormat<any> = {
    value: DestinationPlace,
    ty: ["array", ["object", null]],
    fieldName: "DDD IO Dp",
    constraints: { min: 1, max: 4 },
};
export const DDDIO: MessageFormat<any> = {
    value: {
        drn: {
            value: 0,
            ty: ["number", null],
            fieldName: "Drn",
            optional: false,
            constraints: { min: 0, max: 7 },
        },
        dp: { ...DDDIODp, optional: true },
        dr: { ...DDDIODr, optional: true },
        rne: {
            value: 0,
            ty: ["number", null],
            fieldName: "Rne",
            optional: true,
            constraints: { min: 1, max: 999 },
        },
        stn_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Stn Id",
            optional: true,
            constraints: { min: 1, max: 999 },
        },
        stn_text: {
            value: "",
            ty: ["string", null],
            fieldName: "Stn Text",
            optional: true,
        },
        dcp: { ...DistanceOrDuration, optional: true },
        ddp: { ...DistanceOrDuration, optional: true },
    },
    ty: ["object", null],
    fieldName: "DDD IO",
};
export const DDDIoList: MessageFormat<any> = {
    value: DDDIO,
    ty: ["array", ["object", null]],
    fieldName: "DDD Io List",
    constraints: { min: 1, max: 8 },
};
export const DDD: MessageFormat<any> = {
    value: {
        dcj: {
            value: 0,
            ty: ["number", null],
            fieldName: "Dcj",
            optional: true,
            constraints: { min: 1, max: 128 },
        },
        dcr: {
            value: 0,
            ty: ["number", null],
            fieldName: "Dcr",
            optional: true,
            constraints: { min: 1, max: 128 },
        },
        tpl: {
            value: 0,
            ty: ["number", null],
            fieldName: "Tpl",
            optional: true,
            constraints: { min: 1, max: 128 },
        },
        io_list: { ...DDDIoList, optional: false },
    },
    ty: ["object", null],
    fieldName: "DDD",
};
export const ISO14823Attribute: MessageFormat<any> = {
    value: {
        Dtm: DTM,
        Edt: EDT,
        Dfl: DFL,
        Ved: VED,
        Spe: SPE,
        Roi: ROI,
        Dbv: DBV,
        Ddd: DDD,
    },
    ty: ["choice", null],
    fieldName: "ISO 14823 Attribute",
};
export const ISO14823Attributes: MessageFormat<any> = {
    value: ISO14823Attribute,
    ty: ["array", ["object", null]],
    fieldName: "ISO 14823 Attributes",
    constraints: { min: 1, max: 8 },
};
export const ISO14823Code: MessageFormat<any> = {
    value: {
        pictogram_code: { ...PictogramCode, optional: false },
        attributes: { ...ISO14823Attributes, optional: true },
    },
    ty: ["object", null],
    fieldName: "I S O14823Code",
};
export const Provider: MessageFormat<any> = {
    value: {
        country_code: { ...CountryCode, optional: false },
        provider_identifier: { ...IssuerIdentifier, optional: false },
    },
    ty: ["object", null],
    fieldName: "Provider",
};
export const AnyCatalogue: MessageFormat<any> = {
    value: {
        owner: { ...Provider, optional: false },
        version: {
            value: 0,
            ty: ["number", null],
            fieldName: "Version",
            optional: false,
            constraints: { min: 0, max: 255 },
        },
        pictogram_code: {
            value: 0,
            ty: ["number", null],
            fieldName: "Pictogram Code",
            optional: false,
            constraints: { min: 0, max: 65535 },
        },
        value: {
            value: 0,
            ty: ["number", null],
            fieldName: "Value",
            optional: true,
            constraints: { min: 0, max: 65535 },
        },
        unit: { ...RSCUnit, optional: true },
        attributes: { ...ISO14823Attributes, optional: true },
    },
    ty: ["object", null],
    fieldName: "Any Catalogue",
};
export const VcCodeValidity: MessageFormat<any> = {
    value: DTM,
    ty: ["array", ["object", null]],
    fieldName: "Vc Code Validity",
    constraints: { min: 1, max: 8 },
};
export const VcCode: MessageFormat<any> = {
    value: {
        road_sign_class: { ...VcClass, optional: false },
        road_sign_code: {
            value: 0,
            ty: ["number", null],
            fieldName: "Road Sign Code",
            optional: false,
            constraints: { min: 1, max: 64 },
        },
        vc_option: { ...VcOption, optional: false },
        validity: { ...VcCodeValidity, optional: true },
        value: {
            value: 0,
            ty: ["number", null],
            fieldName: "Value",
            optional: true,
            constraints: { min: 0, max: 65535 },
        },
        unit: { ...RSCUnit, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vc Code",
};
export const Code: MessageFormat<any> = {
    value: {
        ViennaConvention: VcCode,
        Iso14823: ISO14823Code,
        itis_codes: {
            value: 0,
            ty: ["number", null],
            fieldName: "Itis Codes",
            constraints: { min: 0, max: 65535 },
        },
        AnyCatalogue,
    },
    ty: ["choice", null],
    fieldName: "Code",
};
export const RSCode: MessageFormat<any> = {
    value: {
        layout_component_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Layout Component Id",
            optional: true,
            constraints: { min: 1, max: 4 },
        },
        code: { ...Code, optional: false },
    },
    ty: ["object", null],
    fieldName: "Code",
};
export const LayoutComponent: MessageFormat<any> = {
    value: {
        layout_component_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Layout Component Id",
            optional: false,
            constraints: { min: 1, max: 8 },
        },
        height: {
            value: 0,
            ty: ["number", null],
            fieldName: "Height",
            optional: false,
            constraints: { min: 10, max: 73 },
        },
        width: {
            value: 0,
            ty: ["number", null],
            fieldName: "Width",
            optional: false,
            constraints: { min: 10, max: 265 },
        },
        x: {
            value: 0,
            ty: ["number", null],
            fieldName: "X",
            optional: false,
            constraints: { min: 10, max: 265 },
        },
        y: {
            value: 0,
            ty: ["number", null],
            fieldName: "Y",
            optional: false,
            constraints: { min: 10, max: 73 },
        },
        text_scripting: {
            value: 0,
            ty: ["number", null],
            fieldName: "Text Scripting",
            optional: false,
            constraints: { min: 0, max: 1 },
        },
    },
    ty: ["object", null],
    fieldName: "Layout Component",
};
export const SequenceOfUint8: MessageFormat<any> = {
    value: Uint8,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Uint8",
};
export const CountryOnly: MessageFormat<any> = {
    ...Uint16,
    fieldName: "Country Only",
};
export const PublicVerificationKey: MessageFormat<any> = {
    value: {
        EcdsaNistP256: EccP256CurvePoint,
        EcdsaBrainpoolP256r1: EccP256CurvePoint,
        EcdsaBrainpoolP384r1: EccP384CurvePoint,
    },
    ty: ["choice", null],
    fieldName: "Public Verification Key",
};
export const RegulatorySpeedLimit: MessageFormat<any> = {
    value: {
        type: { ...SpeedLimitType, optional: false },
        speed: { ...Velocity, optional: false },
    },
    ty: ["object", null],
    fieldName: "Regulatory Speed Limit",
};
export const SpeedLimitList: MessageFormat<any> = {
    value: RegulatorySpeedLimit,
    ty: ["array", ["object", null]],
    fieldName: "Speed Limit List",
    constraints: { min: 1, max: 9 },
};
export const EnabledLaneList: MessageFormat<any> = {
    value: LaneID,
    ty: ["array", ["number", null]],
    fieldName: "Enabled Lane List",
    constraints: { min: 1, max: 16 },
};
export const ManeuverAssistList: MessageFormat<any> = {
    value: ConnectionManeuverAssist,
    ty: ["array", ["object", null]],
    fieldName: "Maneuver Assist List",
    constraints: { min: 1, max: 16 },
};
export const DigitalMap: MessageFormat<any> = {
    value: ReferencePosition,
    ty: ["array", ["object", null]],
    fieldName: "Digital Map",
    constraints: { min: 1, max: 256 },
};
export const SpotAvailability: MessageFormat<any> = {
    value: {
        max_waiting_time_minutes: {
            value: 0,
            ty: ["number", null],
            fieldName: "Max Waiting Time Minutes",
            optional: false,
            constraints: { min: 0, max: 1400 },
        },
        blocking: {
            value: false,
            ty: ["boolean", null],
            fieldName: "Blocking",
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Spot Availability",
};
export const ParkingPlacesData: MessageFormat<any> = {
    value: SpotAvailability,
    ty: ["array", ["object", null]],
    fieldName: "Parking Places Data",
    constraints: { min: 1, max: 4 },
};
export const ItsChargingSpotDataElements: MessageFormat<any> = {
    value: {
        type: { ...ChargingSpotType, optional: false },
        ev_equipment_id: {
            value: "",
            ty: ["string", null],
            fieldName: "Ev Equipment ID",
            optional: true,
        },
        type_of_receptacle: { ...TypeOfReceptacle, optional: false },
        energy_availability: {
            value: "",
            ty: ["string", null],
            fieldName: "Energy Availability",
            optional: false,
        },
        parking_places_data: { ...ParkingPlacesData, optional: true },
    },
    ty: ["object", null],
    fieldName: "Its Charging Spot Data Elements",
};
export const ItsChargingSpots: MessageFormat<any> = {
    value: ItsChargingSpotDataElements,
    ty: ["array", ["object", null]],
    fieldName: "Its Charging Spots",
    constraints: { min: 1, max: 16 },
};
export const ItsChargingStationData: MessageFormat<any> = {
    value: {
        charging_station_id: { ...StationID, optional: false },
        utility_distributor_id: {
            value: "",
            ty: ["string", null],
            fieldName: "Utility Distributor Id",
            optional: true,
            constraints: { min: 1, max: 32 },
        },
        provider_id: {
            value: "",
            ty: ["string", null],
            fieldName: "Provider ID",
            optional: true,
            constraints: { min: 1, max: 32 },
        },
        charging_station_location: { ...ReferencePosition, optional: false },
        address: {
            value: "",
            ty: ["string", null],
            fieldName: "Address",
            optional: true,
        },
        phone_number: {
            value: "",
            ty: ["string", null],
            fieldName: "Phone Number",
            optional: true,
            constraints: { min: 1, max: 16 },
        },
        accessibility: {
            value: "",
            ty: ["string", null],
            fieldName: "Accessibility",
            optional: false,
            constraints: { min: 1, max: 32 },
        },
        digital_map: { ...DigitalMap, optional: true },
        opening_days_hours: {
            value: "",
            ty: ["string", null],
            fieldName: "Opening Days Hours",
            optional: false,
        },
        pricing: {
            value: "",
            ty: ["string", null],
            fieldName: "Pricing",
            optional: false,
        },
        booking_contact_info: {
            value: "",
            ty: ["string", null],
            fieldName: "Booking Contact Info",
            optional: true,
        },
        payment: {
            value: "",
            ty: ["string", null],
            fieldName: "Payment",
            optional: true,
        },
        charging_spots_available: { ...ItsChargingSpots, optional: false },
    },
    ty: ["object", null],
    fieldName: "Its Charging Station Data",
};
export const ItsEVCSNDataChargingStationsData: MessageFormat<any> = {
    value: ItsChargingStationData,
    ty: ["array", ["object", null]],
    fieldName: "Its E V CSNDataChargingStationsData",
    constraints: { min: 1, max: 256 },
};
export const ItsEVCSNData: MessageFormat<any> = {
    value: {
        total_number_of_stations: { ...NumberStations, optional: false },
        charging_stations_data: {
            ...ItsEVCSNDataChargingStationsData,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Its E V CSNData",
};
export const NodeLLmD64b: MessageFormat<any> = {
    value: {
        lon: { ...Longitude, optional: false },
        lat: { ...Latitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Node L Lm D64b",
};
export const NodeXY20b: MessageFormat<any> = {
    value: {
        x: { ...OffsetB10, optional: false },
        y: { ...OffsetB10, optional: false },
    },
    ty: ["object", null],
    fieldName: "Node X Y20b",
};
export const NodeXY22b: MessageFormat<any> = {
    value: {
        x: { ...OffsetB11, optional: false },
        y: { ...OffsetB11, optional: false },
    },
    ty: ["object", null],
    fieldName: "Node X Y22b",
};
export const NodeXY24b: MessageFormat<any> = {
    value: {
        x: { ...OffsetB12, optional: false },
        y: { ...OffsetB12, optional: false },
    },
    ty: ["object", null],
    fieldName: "Node X Y24b",
};
export const NodeXY26b: MessageFormat<any> = {
    value: {
        x: { ...OffsetB13, optional: false },
        y: { ...OffsetB13, optional: false },
    },
    ty: ["object", null],
    fieldName: "Node X Y26b",
};
export const NodeXY28b: MessageFormat<any> = {
    value: {
        x: { ...OffsetB14, optional: false },
        y: { ...OffsetB14, optional: false },
    },
    ty: ["object", null],
    fieldName: "Node X Y28b",
};
export const NodeXY32b: MessageFormat<any> = {
    value: {
        x: { ...OffsetB16, optional: false },
        y: { ...OffsetB16, optional: false },
    },
    ty: ["object", null],
    fieldName: "Node X Y32b",
};
export const ITSaid: MessageFormat<any> = {
    ...VarLengthNumber,
    fieldName: "I T Said",
};
export const ItsAidCtxRef: MessageFormat<any> = {
    value: {
        itsaid: { ...ITSaid, optional: false },
        ctx: { ...CtxRef, optional: false },
    },
    ty: ["object", null],
    fieldName: "Its Aid Ctx Ref",
};
export const MandAppCtx: MessageFormat<any> = {
    value: ItsAidCtxRef,
    ty: ["array", ["object", null]],
    fieldName: "Mand App Ctx",
};
export const NodeOffsetPointXY: MessageFormat<any> = {
    value: {
        NodeXY1: NodeXY20b,
        NodeXY2: NodeXY22b,
        NodeXY3: NodeXY24b,
        NodeXY4: NodeXY26b,
        NodeXY5: NodeXY28b,
        NodeXY6: NodeXY32b,
        NodeLatLon: NodeLLmD64b,
    },
    ty: ["choice", null],
    fieldName: "Node Offset Point XY",
};
export const LaneTypeAttributes: MessageFormat<any> = {
    value: {
        Vehicle: LaneAttributesVehicle,
        Crosswalk: LaneAttributesCrosswalk,
        BikeLane: LaneAttributesBike,
        Sidewalk: LaneAttributesSidewalk,
        Median: LaneAttributesBarrier,
        Striping: LaneAttributesStriping,
        TrackedVehicle: LaneAttributesTrackedVehicle,
        Parking: LaneAttributesParking,
    },
    ty: ["choice", null],
    fieldName: "Lane Type Attributes",
};
export const LaneAttributes: MessageFormat<any> = {
    value: {
        directional_use: { ...LaneDirection, optional: false },
        shared_with: { ...LaneSharing, optional: false },
        lane_type: { ...LaneTypeAttributes, optional: false },
    },
    ty: ["object", null],
    fieldName: "Lane Attributes",
};
export const LaneDataAttribute: MessageFormat<any> = {
    value: {
        PathEndPointAngle: DeltaAngle,
        LaneCrownPointCenter: RoadwayCrownAngle,
        LaneCrownPointLeft: RoadwayCrownAngle,
        LaneCrownPointRight: RoadwayCrownAngle,
        LaneAngle: MergeDivergeNodeAngle,
        SpeedLimits: SpeedLimitList,
    },
    ty: ["choice", null],
    fieldName: "Lane Data Attribute",
};
export const LaneDataAttributeList: MessageFormat<any> = {
    value: LaneDataAttribute,
    ty: ["array", ["object", null]],
    fieldName: "Lane Data Attribute List",
    constraints: { min: 1, max: 8 },
};
export const SegmentAttributeXYList: MessageFormat<any> = {
    value: SegmentAttributeXY,
    ty: ["array", ["object", null]],
    fieldName: "Segment Attribute X YList",
    constraints: { min: 1, max: 8 },
};
export const NodeLink: MessageFormat<any> = {
    value: Node,
    ty: ["array", ["object", null]],
    fieldName: "Node Link",
    constraints: { min: 1, max: 5 },
};
export const ItsStationPosition: MessageFormat<any> = {
    value: {
        station_id: { ...StationID, optional: false },
        lane_id: { ...LaneID, optional: true },
        node_x_y: { ...NodeOffsetPointXY, optional: true },
        time_reference: { ...TimeReference, optional: true },
    },
    ty: ["object", null],
    fieldName: "Its Station Position",
};
export const NodeAttributeSetXY: MessageFormat<any> = {
    value: {
        local_node: { ...NodeAttributeXYList, optional: true },
        disabled: { ...SegmentAttributeXYList, optional: true },
        enabled: { ...SegmentAttributeXYList, optional: true },
        data: { ...LaneDataAttributeList, optional: true },
        d_width: { ...OffsetB10, optional: true },
        d_elevation: { ...OffsetB10, optional: true },
    },
    ty: ["object", null],
    fieldName: "Node Attribute Set XY",
};
export const NodeXY: MessageFormat<any> = {
    value: {
        delta: { ...NodeOffsetPointXY, optional: false },
        attributes: { ...NodeAttributeSetXY, optional: true },
    },
    ty: ["object", null],
    fieldName: "Node X Y",
};
export const NodeSetXY: MessageFormat<any> = {
    value: NodeXY,
    ty: ["array", ["object", null]],
    fieldName: "Node Set X Y",
    constraints: { min: 2, max: 63 },
};
export const NodeListXY: MessageFormat<any> = {
    value: { Nodes: NodeSetXY, Computed: ComputedLane },
    ty: ["choice", null],
    fieldName: "Node List X Y",
};
export const ItsStationPositionList: MessageFormat<any> = {
    value: ItsStationPosition,
    ty: ["array", ["object", null]],
    fieldName: "Its Station Position List",
    constraints: { min: 1, max: 5 },
};
export const OverlayLaneList: MessageFormat<any> = {
    value: LaneID,
    ty: ["array", ["object", null]],
    fieldName: "Overlay Lane List",
    constraints: { min: 1, max: 5 },
};
export const GenericLane: MessageFormat<any> = {
    value: {
        lane_id: { ...LaneID, optional: false },
        name: { ...DescriptiveName, optional: true },
        ingress_approach: { ...ApproachID, optional: true },
        egress_approach: { ...ApproachID, optional: true },
        lane_attributes: { ...LaneAttributes, optional: false },
        maneuvers: { ...AllowedManeuvers, optional: true },
        node_list: { ...NodeListXY, optional: false },
        connects_to: { ...ConnectsToList, optional: true },
        overlays: { ...OverlayLaneList, optional: true },
    },
    ty: ["object", null],
    fieldName: "Generic Lane",
};
export const LaneList: MessageFormat<any> = {
    value: GenericLane,
    ty: ["array", ["object", null]],
    fieldName: "Lane List",
    constraints: { min: 1, max: 255 },
};
export const LayoutContainerLayoutComponents: MessageFormat<any> = {
    value: LayoutComponent,
    ty: ["array", ["object", null]],
    fieldName: "Layout Container Layout Components",
    constraints: { min: 1, max: 4 },
};
export const LayoutContainer: MessageFormat<any> = {
    value: {
        layout_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Layout Id",
            optional: false,
            constraints: { min: 1, max: 4 },
        },
        height: {
            value: 0,
            ty: ["number", null],
            fieldName: "Height",
            optional: true,
            constraints: { min: 10, max: 73 },
        },
        width: {
            value: 0,
            ty: ["number", null],
            fieldName: "Width",
            optional: true,
            constraints: { min: 10, max: 265 },
        },
        layout_components: {
            ...LayoutContainerLayoutComponents,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Layout Container",
};
export const Traces: MessageFormat<any> = {
    value: PathHistory,
    ty: ["array", ["array", ["object", null]]],
    fieldName: "Traces",
    constraints: { min: 1, max: 7 },
};
export const LocationContainer: MessageFormat<any> = {
    value: {
        event_speed: { ...Speed, optional: true },
        event_position_heading: { ...Heading, optional: true },
        traces: { ...Traces, optional: false },
        road_type: { ...RoadType, optional: true },
    },
    ty: ["object", null],
    fieldName: "Location Container",
};
export const MediaTypeOfMDUs: MessageFormat<any> = {
    value: {
        starting_m_d_u: { ...SequenceNumber, optional: true },
        ending_m_d_u: { ...SequenceNumber, optional: true },
        media_type: {
            value: "",
            ty: ["string", null],
            fieldName: "Media Type",
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Media Type Of MDUs",
};
export const MCDMManagementContainerMediaTypes: MessageFormat<any> = {
    value: MediaTypeOfMDUs,
    ty: ["array", ["object", null]],
    fieldName: "M C D MManagementContainerMediaTypes",
};
export const URLOfMDUs: MessageFormat<any> = {
    value: {
        starting_m_d_u: { ...SequenceNumber, optional: true },
        ending_m_d_u: { ...SequenceNumber, optional: true },
        url: {
            value: "",
            ty: ["string", null],
            fieldName: "Url",
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "URL Of MDUs",
};
export const MCDMManagementContainerUrls: MessageFormat<any> = {
    value: URLOfMDUs,
    ty: ["array", ["object", null]],
    fieldName: "MCDM Management Container Urls",
};
export const MCDMManagementContainer: MessageFormat<any> = {
    value: {
        action_id: { ...ActionID, optional: false },
        request: { ...RequestResponseIndication, optional: true },
        ack: { ...AckNackIndication, optional: true },
        detection_time: { ...TimestampIts, optional: true },
        reference_time: { ...TimestampIts, optional: false },
        linked_denm: { ...ActionID, optional: true },
        validity_duration: { ...ValidityDuration, optional: true },
        station_type: { ...StationType, optional: true },
        number_of_m_d_us: {
            value: 0,
            ty: ["number", null],
            fieldName: "Number Of MDUs",
            optional: true,
            constraints: { min: 0, max: 4294967295 },
        },
        number_of_p_d_us: {
            value: 0,
            ty: ["number", null],
            fieldName: "Number Of PDUs",
            optional: true,
            constraints: { min: 1, max: 4294967295 },
        },
        pdu_sequence_number: {
            value: 0,
            ty: ["number", null],
            fieldName: "Pdu Sequence Number",
            optional: true,
            constraints: { min: 1, max: 4294967295 },
        },
        media_types: { ...MCDMManagementContainerMediaTypes, optional: true },
        urls: { ...MCDMManagementContainerUrls, optional: true },
        real_time: { value: false, fieldName: "", optional: true },
        size: {
            value: 0,
            ty: ["number", null],
            fieldName: "Size",
            optional: true,
            constraints: { min: 0, max: 4294967295 },
        },
    },
    ty: ["object", null],
    fieldName: "MCDM Management Container",
};
export const ManagementContainer: MessageFormat<any> = {
    value: {
        action_id: { ...ActionID, optional: false },
        detection_time: { ...TimestampIts, optional: false },
        reference_time: { ...TimestampIts, optional: false },
        termination: { ...Termination, optional: true },
        event_position: { ...ReferencePosition, optional: false },
        relevance_distance: { ...RelevanceDistance, optional: true },
        relevance_traffic_direction: {
            ...RelevanceTrafficDirection,
            optional: true,
        },
        validity_duration: { ...ValidityDuration, optional: true },
        transmission_interval: { ...TransmissionInterval, optional: true },
        station_type: { ...StationType, optional: false },
    },
    ty: ["object", null],
    fieldName: "Management Container",
};
export const TimeChangeDetails: MessageFormat<any> = {
    value: {
        start_time: { ...TimeMark, optional: true },
        min_end_time: { ...TimeMark, optional: false },
        max_end_time: { ...TimeMark, optional: true },
        likely_time: { ...TimeMark, optional: true },
        confidence: { ...TimeIntervalConfidence, optional: true },
        next_time: { ...TimeMark, optional: true },
    },
    ty: ["object", null],
    fieldName: "Time Change Details",
};
export const AdvisorySpeedList: MessageFormat<any> = {
    value: AdvisorySpeed,
    ty: ["array", ["object", null]],
    fieldName: "Advisory Speed List",
    constraints: { min: 1, max: 16 },
};
export const MovementEvent: MessageFormat<any> = {
    value: {
        event_state: { ...MovementPhaseState, optional: false },
        timing: { ...TimeChangeDetails, optional: true },
        speeds: { ...AdvisorySpeedList, optional: true },
    },
    ty: ["object", null],
    fieldName: "Movement Event",
};
export const MovementEventList: MessageFormat<any> = {
    value: MovementEvent,
    ty: ["array", ["object", null]],
    fieldName: "Movement Event List",
    constraints: { min: 1, max: 16 },
};
export const DataParameters: MessageFormat<any> = {
    value: {
        process_method: {
            value: "",
            ty: ["string", null],
            fieldName: "Process Method",
            optional: true,
            constraints: { min: 1, max: 255 },
        },
        process_agency: {
            value: "",
            ty: ["string", null],
            fieldName: "Process Agency",
            optional: true,
            constraints: { min: 1, max: 255 },
        },
        last_checked_date: {
            value: "",
            ty: ["string", null],
            fieldName: "Last Checked Date",
            optional: true,
            constraints: { min: 1, max: 255 },
        },
        geoid_used: {
            value: "",
            ty: ["string", null],
            fieldName: "Geoid Used",
            optional: true,
            constraints: { min: 1, max: 255 },
        },
    },
    ty: ["object", null],
    fieldName: "Data Parameters",
};
export const MovementState: MessageFormat<any> = {
    value: {
        movement_name: { ...DescriptiveName, optional: true },
        signal_group: { ...SignalGroupID, optional: false },
        state_time_speed: { ...MovementEventList, optional: false },
        maneuver_assist_list: { ...ManeuverAssistList, optional: true },
    },
    ty: ["object", null],
    fieldName: "Movement State",
};
export const MovementList: MessageFormat<any> = {
    value: MovementState,
    ty: ["array", ["object", null]],
    fieldName: "Movement List",
    constraints: { min: 1, max: 255 },
};
export const IntersectionGeometry: MessageFormat<any> = {
    value: {
        name: { ...DescriptiveName, optional: true },
        id: { ...IntersectionReferenceID, optional: false },
        revision: { ...MsgCount, optional: false },
        ref_point: { ...Position3D, optional: false },
        lane_width: { ...LaneWidth, optional: true },
        speed_limits: { ...SpeedLimitList, optional: true },
        lane_set: { ...LaneList, optional: false },
    },
    ty: ["object", null],
    fieldName: "Intersection Geometry",
};
export const IntersectionState: MessageFormat<any> = {
    value: {
        name: { ...DescriptiveName, optional: true },
        id: { ...IntersectionReferenceID, optional: false },
        revision: { ...MsgCount, optional: false },
        status: { ...IntersectionStatusObject, optional: false },
        moy: { ...MinuteOfTheYear, optional: true },
        time_stamp: { ...DSecond, optional: true },
        enabled_lanes: { ...EnabledLaneList, optional: true },
        states: { ...MovementList, optional: false },
        maneuver_assist_list: { ...ManeuverAssistList, optional: true },
    },
    ty: ["object", null],
    fieldName: "Intersection State",
};
export const IntersectionGeometryList: MessageFormat<any> = {
    value: IntersectionGeometry,
    ty: ["array", ["object", null]],
    fieldName: "Intersection Geometry List",
    constraints: { min: 1, max: 32 },
};
export const McdmInfo: MessageFormat<any> = {
    value: {
        management: { ...MCDMManagementContainer, optional: false },
        situation: { ...MCDMSituationContainer, optional: true },
        application: { ...MCDMApplicationContainer, optional: true },
        multimedia: { ...MCDMMultimediaContainer, optional: true },
    },
    ty: ["object", null],
    fieldName: "Mcdm Info",
};
export const McdmPdu: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        mcdm_info: { ...McdmInfo, optional: false },
    },
    ty: ["object", null],
    fieldName: "Mcdm Pdu",
};
export const OtherSubclass: MessageFormat<any> = {
    value: {
        type: { ...OtherSublassType, optional: true },
        confidence: { ...ClassConfidence, optional: true },
    },
    ty: ["object", null],
    fieldName: "Other Subclass",
};
export const NodeOffsetPointZ: MessageFormat<any> = {
    value: {
        NodeZ1: OffsetB10,
        NodeZ2: OffsetB11,
        NodeZ3: OffsetB12,
        NodeZ4: OffsetB13,
        NodeZ5: OffsetB14,
        NodeZ6: OffsetB16,
    },
    ty: ["choice", null],
    fieldName: "Node Offset Point Z",
};
export const OffsetPoint: MessageFormat<any> = {
    value: {
        node_offset_pointxy: { ...NodeOffsetPointXY, optional: false },
        node_offset_point_z: { ...NodeOffsetPointZ, optional: true },
    },
    ty: ["object", null],
    fieldName: "Offset Point",
};
export const AreaCircular: MessageFormat<any> = {
    value: {
        node_center_point: { ...OffsetPoint, optional: true },
        radius: { ...Radius, optional: false },
    },
    ty: ["object", null],
    fieldName: "Area Circular",
};
export const PolyPointList: MessageFormat<any> = {
    value: OffsetPoint,
    ty: ["array", ["object", null]],
    fieldName: "Poly Point List",
    constraints: { min: 3, max: 16 },
};
export const AreaPolygon: MessageFormat<any> = {
    value: { PolyPointList: { ...PolyPointList, optional: false } },
    ty: ["object", null],
    fieldName: "Area Polygon",
};
export const AreaRectangle: MessageFormat<any> = {
    value: {
        node_center_point: { ...OffsetPoint, optional: true },
        semi_major_range_length: { ...SemiRangeLength, optional: false },
        semi_minor_range_length: { ...SemiRangeLength, optional: false },
        semi_major_range_orientation: { ...WGS84AngleValue, optional: false },
        semi_height: { ...SemiRangeLength, optional: true },
    },
    ty: ["object", null],
    fieldName: "Area Rectangle",
};
export const MatchedPosition: MessageFormat<any> = {
    value: {
        lane_id: { ...LaneID, optional: true },
        longitudinal_lane_position: {
            ...LongitudinalLanePosition,
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Matched Position",
};
export const ObjectDistanceWithConfidence: MessageFormat<any> = {
    value: {
        value: { ...DistanceValue, optional: false },
        confidence: { ...DistanceConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Object Distance With Confidence",
};
export const SpeedExtended: MessageFormat<any> = {
    value: {
        value: { ...SpeedValueExtended, optional: false },
        confidence: { ...SpeedConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Speed Extended",
};
export const AreaEllipse: MessageFormat<any> = {
    value: {
        node_center_point: { ...OffsetPoint, optional: true },
        semi_minor_range_length: { ...SemiRangeLength, optional: false },
        semi_major_range_length: { ...SemiRangeLength, optional: false },
        semi_major_range_orientation: { ...WGS84AngleValue, optional: false },
        semi_height: { ...SemiRangeLength, optional: true },
    },
    ty: ["object", null],
    fieldName: "Area Ellipse",
};
export const FreeSpaceArea: MessageFormat<any> = {
    value: {
        FreeSpacePolygon: AreaPolygon,
        FreeSpaceCircular: AreaCircular,
        FreeSpaceEllipse: AreaEllipse,
        FreeSpaceRectangle: AreaRectangle,
    },
    ty: ["choice", null],
    fieldName: "Free Space Area",
};
export const SensorIdList: MessageFormat<any> = {
    value: Identifier,
    ty: ["array", ["number", null]],
    fieldName: "Sensor ID List",
    constraints: { min: 1, max: 128 },
};
export const FreeSpaceAddendum: MessageFormat<any> = {
    value: {
        free_space_confidence: { ...FreeSpaceConfidence, optional: false },
        free_space_area: { ...FreeSpaceArea, optional: false },
        sensor_id_list: { ...SensorIdList, optional: true },
        shadowing_applies: { ...ShadowingApplies, optional: true },
    },
    ty: ["object", null],
    fieldName: "Free Space Addendum",
};
export const PersonSubclass: MessageFormat<any> = {
    value: {
        type: { ...PersonSubclassType, optional: true },
        confidence: { ...ClassConfidence, optional: true },
    },
    ty: ["object", null],
    fieldName: "Person Subclass",
};
export const PrioritizationResponse: MessageFormat<any> = {
    value: {
        station_id: { ...StationID, optional: false },
        prior_state: { ...PrioritizationResponseStatus, optional: false },
        signal_group: { ...SignalGroupID, optional: false },
    },
    ty: ["object", null],
    fieldName: "Prioritization Response",
};
export const ProtectedCommunicationZone: MessageFormat<any> = {
    value: {
        protected_zone_type: { ...ProtectedZoneType, optional: false },
        expiry_time: { ...TimestampIts, optional: true },
        protected_zone_latitude: { ...Latitude, optional: false },
        protected_zone_longitude: { ...Longitude, optional: false },
        protected_zone_radius: { ...ProtectedZoneRadius, optional: true },
        protected_zone_id: { ...ProtectedZoneID, optional: true },
    },
    ty: ["object", null],
    fieldName: "Protected Communication Zone",
};
export const PrioritizationResponseList: MessageFormat<any> = {
    value: PrioritizationResponse,
    ty: ["array", ["object", null]],
    fieldName: "Prioritization Response List",
    constraints: { min: 1, max: 10 },
};
export const PtActivation: MessageFormat<any> = {
    value: {
        pt_activation_type: { ...PtActivationType, optional: false },
        pt_activation_data: { ...PtActivationData, optional: false },
    },
    ty: ["object", null],
    fieldName: "Pt Activation",
};
export const BasePublicEncryptionKey: MessageFormat<any> = {
    value: {
        EciesNistP256: EccP256CurvePoint,
        EciesBrainpoolP256r1: EccP256CurvePoint,
    },
    ty: ["choice", null],
    fieldName: "Base Public Encryption Key",
};
export const PublicEncryptionKey: MessageFormat<any> = {
    value: {
        supported_symm_alg: { ...SymmAlgorithm, optional: false },
        public_key: { ...BasePublicEncryptionKey, optional: false },
    },
    ty: ["object", null],
    fieldName: "Public Encryption Key",
};
export const DDateTime: MessageFormat<any> = {
    value: {
        year: { ...DYear, optional: true },
        month: { ...DMonth, optional: true },
        day: { ...DDay, optional: true },
        hour: { ...DHour, optional: true },
        minute: { ...DMinute, optional: true },
        second: { ...DSecond, optional: true },
        offset: { ...DOffset, optional: true },
    },
    ty: ["object", null],
    fieldName: "D Date Time",
};
export const PositionConfidenceSet: MessageFormat<any> = {
    value: {
        pos: { ...PositionConfidence, optional: false },
        elevation: { ...ElevationConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Position Confidence Set",
};
export const PositionalAccuracy: MessageFormat<any> = {
    value: {
        semi_major: { ...SemiMajorAxisAccuracy, optional: false },
        semi_minor: { ...SemiMinorAxisAccuracy, optional: false },
        orientation: { ...SemiMajorAxisOrientation, optional: false },
    },
    ty: ["object", null],
    fieldName: "Positional Accuracy",
};
export const SpeedandHeadingandThrottleConfidence: MessageFormat<any> = {
    value: {
        heading: { ...SAEHeadingConfidence, optional: false },
        speed: { ...SAESpeedConfidence, optional: false },
        throttle: { ...SAEThrottleConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Speedand Headingand Throttle Confidence",
};
export const TransmissionAndSpeed: MessageFormat<any> = {
    value: {
        transmisson: { ...TransmissionState, optional: false },
        speed: { ...Velocity, optional: false },
    },
    ty: ["object", null],
    fieldName: "Transmission And Speed",
};
export const FullPositionVector: MessageFormat<any> = {
    value: {
        utc_time: { ...DDateTime, optional: true },
        long: { ...Longitude, optional: false },
        lat: { ...Latitude, optional: false },
        elevation: { ...Elevation, optional: true },
        heading: { ...SAEHeading, optional: true },
        speed: { ...TransmissionAndSpeed, optional: true },
        pos_accuracy: { ...PositionalAccuracy, optional: true },
        time_confidence: { ...TimeConfidence, optional: true },
        pos_confidence: { ...PositionConfidenceSet, optional: true },
        speed_confidence: {
            ...SpeedandHeadingandThrottleConfidence,
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Full Position Vector",
};
export const AntennaOffsetSet: MessageFormat<any> = {
    value: {
        ant_offset_x: { ...OffsetB12, optional: false },
        ant_offset_y: { ...OffsetB09, optional: false },
        ant_offset_z: { ...OffsetB10, optional: false },
    },
    ty: ["object", null],
    fieldName: "Antenna Offset Set",
};
export const RTCMheader: MessageFormat<any> = {
    value: {
        status: { ...GNSSstatus, optional: false },
        offset_set: { ...AntennaOffsetSet, optional: false },
    },
    ty: ["object", null],
    fieldName: "R T C Mheader",
};
export const RTCMmessageList: MessageFormat<any> = {
    value: RTCMmessage,
    ty: ["array", ["object", null]],
    fieldName: "R T C MmessageList",
    constraints: { min: 1, max: 5 },
};
export const EnvironmentalCharacteristics: MessageFormat<any> = {
    value: {
        euro_value: { ...EuroValue, optional: false },
        cop_value: { ...CopValue, optional: false },
    },
    ty: ["object", null],
    fieldName: "Environmental Characteristics",
};
export const LoadType: MessageFormat<any> = {
    value: {
        goods_type: { ...GoodsType, optional: false },
        dangerous_goods_type: { ...DangerousGoodsBasic, optional: false },
        special_transport_type: { ...SpecialTransportType, optional: false },
    },
    ty: ["object", null],
    fieldName: "Load Type",
};
export const VehicleDimensions: MessageFormat<any> = {
    value: {
        vehicle_length_overall: { ...Int1, optional: false },
        vehicle_heigth_overall: { ...Int1, optional: false },
        vehicle_width_overall: { ...Int1, optional: false },
    },
    ty: ["object", null],
    fieldName: "Vehicle Dimensions",
};
export const VehicleWeightLimits: MessageFormat<any> = {
    value: {
        vehicle_max_laden_weight: { ...Int2, optional: false },
        vehicle_train_maximum_weight: { ...Int2, optional: false },
        vehicle_weight_unladen: { ...Int2, optional: false },
    },
    ty: ["object", null],
    fieldName: "Vehicle Weight Limits",
};
export const AxleWeightLimits: MessageFormat<any> = {
    value: {
        max_ladenweight_on_axle1: { ...Int2, optional: false },
        max_ladenweight_on_axle2: { ...Int2, optional: false },
        max_ladenweight_on_axle3: { ...Int2, optional: false },
        max_ladenweight_on_axle4: { ...Int2, optional: false },
        max_ladenweight_on_axle5: { ...Int2, optional: false },
    },
    ty: ["object", null],
    fieldName: "Axle Weight Limits",
};
export const Particulate: MessageFormat<any> = {
    value: {
        unit_type: { ...UnitType, optional: false },
        value: {
            value: 0,
            ty: ["number", null],
            fieldName: "Value",
            optional: false,
            constraints: { min: 0, max: 32767 },
        },
    },
    ty: ["object", null],
    fieldName: "Particulate",
};
export const DieselEmissionValues: MessageFormat<any> = {
    value: {
        particulate: { ...Particulate, optional: false },
        absorption_coeff: { ...Int2, optional: false },
    },
    ty: ["object", null],
    fieldName: "Diesel Emission Values",
};
export const ExhaustEmissionValues: MessageFormat<any> = {
    value: {
        unit_type: { ...UnitType, optional: false },
        emission_c_o: {
            value: 0,
            ty: ["number", null],
            fieldName: "Emission C O",
            optional: false,
            constraints: { min: 0, max: 32767 },
        },
        emission_h_c: { ...Int2, optional: false },
        emission_n_o_x: { ...Int2, optional: false },
        emission_h_c_n_o_x: { ...Int2, optional: false },
    },
    ty: ["object", null],
    fieldName: "Exhaust Emission Values",
};
export const PassengerCapacity: MessageFormat<any> = {
    value: {
        number_of_seats: { ...Int1, optional: false },
        number_of_standing_places: { ...Int1, optional: false },
    },
    ty: ["object", null],
    fieldName: "Passenger Capacity",
};
export const SoundLevel: MessageFormat<any> = {
    value: {
        soundstationary: { ...Int1, optional: false },
        sounddriveby: { ...Int1, optional: false },
    },
    ty: ["object", null],
    fieldName: "Sound Level",
};
export const RTCMcorrections: MessageFormat<any> = {
    value: {
        msg_cnt: { ...MsgCount, optional: false },
        rev: { ...RTCMRevision, optional: false },
        time_stamp: { ...MinuteOfTheYear, optional: true },
        anchor_point: { ...FullPositionVector, optional: true },
        rtcm_header: { ...RTCMheader, optional: true },
        msgs: { ...RTCMmessageList, optional: false },
    },
    ty: ["object", null],
    fieldName: "R T C Mcorrections",
};
export const EuVehicleCategoryCode: MessageFormat<any> = {
    value: {
        EuVehicleCategoryL,
        EuVehicleCategoryM,
        EuVehicleCategoryN,
        EuVehicleCategoryO,
        eu_vehilcle_category_t: {
            value: null,
            ty: ["null", null],
            fieldName: "",
        },
        eu_vehilcle_category_g: {
            value: null,
            ty: ["null", null],
            fieldName: "",
        },
    },
    ty: ["choice", null],
    fieldName: "Eu Vehicle Category Code",
};
export const VehicleCharacteristicsRangeLimits: MessageFormat<any> = {
    value: {
        number_of_axles: {
            value: 0,
            ty: ["number", null],
            fieldName: "Number Of Axles",
            constraints: { min: 0, max: 7 },
        },
        VehicleDimensions,
        VehicleWeightLimits,
        AxleWeightLimits,
        PassengerCapacity,
        ExhaustEmissionValues,
        DieselEmissionValues,
        SoundLevel,
    },
    ty: ["choice", null],
    fieldName: "Vehicle Characteristics Range Limits",
};
export const VehicleCharacteristicsRanges: MessageFormat<any> = {
    value: {
        comparison_operator: { ...ComparisonOperator, optional: false },
        limits: { ...VehicleCharacteristicsRangeLimits, optional: false },
    },
    ty: ["object", null],
    fieldName: "Vehicle Characteristics Ranges",
};
export const TractorCharacteristicsRanges: MessageFormat<any> = {
    value: VehicleCharacteristicsRanges,
    ty: ["array", ["object", null]],
    fieldName: "Tractor Characteristics Ranges",
    constraints: { min: 1, max: 4 },
};
export const VehicleCharacteristicsFixValues: MessageFormat<any> = {
    value: {
        SimpleVehicleType: StationType,
        EuVehicleCategoryCode,
        Iso3833VehicleType,
        EuroAndCo2value: EnvironmentalCharacteristics,
        EngineCharacteristics,
        LoadType,
        Usage: VehicleRole,
    },
    ty: ["choice", null],
    fieldName: "Vehicle Characteristics Fix Values",
};
export const TractorCharacteristicsEqualTo: MessageFormat<any> = {
    value: VehicleCharacteristicsFixValues,
    ty: ["array", ["object", null]],
    fieldName: "Tractor Characteristics Equal To",
    constraints: { min: 1, max: 4 },
};
export const TractorCharacteristicsNotEqualTo: MessageFormat<any> = {
    value: VehicleCharacteristicsFixValues,
    ty: ["array", ["object", null]],
    fieldName: "Tractor Characteristics Not EqualTo",
    constraints: { min: 1, max: 4 },
};
export const TractorCharacteristics: MessageFormat<any> = {
    value: {
        equal_to: { ...TractorCharacteristicsEqualTo, optional: true },
        not_equal_to: { ...TractorCharacteristicsNotEqualTo, optional: true },
        ranges: { ...TractorCharacteristicsRanges, optional: true },
    },
    ty: ["object", null],
    fieldName: "Tractor Characteristics",
};
export const TrailerCharacteristicsEqualTo: MessageFormat<any> = {
    value: VehicleCharacteristicsFixValues,
    ty: ["array", ["object", null]],
    fieldName: "Trailer Characteristics Equal To",
    constraints: { min: 1, max: 4 },
};
export const TrailerCharacteristicsNotEqualTo: MessageFormat<any> = {
    value: VehicleCharacteristicsFixValues,
    ty: ["array", ["object", null]],
    fieldName: "Trailer Characteristics Not EqualTo",
    constraints: { min: 1, max: 4 },
};
export const TrailerCharacteristicsRanges: MessageFormat<any> = {
    value: VehicleCharacteristicsRanges,
    ty: ["array", ["object", null]],
    fieldName: "Trailer Characteristics Ranges",
    constraints: { min: 1, max: 4 },
};
export const TrailerCharacteristics: MessageFormat<any> = {
    value: {
        equal_to: { ...TrailerCharacteristicsEqualTo, optional: true },
        not_equal_to: { ...TrailerCharacteristicsNotEqualTo, optional: true },
        ranges: { ...TrailerCharacteristicsRanges, optional: true },
    },
    ty: ["object", null],
    fieldName: "Trailer Characteristics",
};
export const TrailerData: MessageFormat<any> = {
    value: {
        ref_point_id: { ...RefPointId, optional: false },
        hitch_point_offset: { ...HitchPointOffset, optional: false },
        front_overhang: { ...FrontOverhang, optional: false },
        rear_overhang: { ...RearOverhang, optional: false },
        trailer_width: { ...VehicleWidth, optional: true },
        hitch_angle: { ...CartesianAngle, optional: true },
    },
    ty: ["object", null],
    fieldName: "Trailer Data",
};
export const RccPartZoneIds: MessageFormat<any> = {
    value: Zid,
    ty: ["array", ["object", null]],
    fieldName: "Rcc Part Zone Ids",
    constraints: { min: 1, max: 8 },
};
export const MotorcylistSpecialContainer: MessageFormat<any> = {
    value: {
        vru_sub_profile_motorcyclist: {
            ...VruSubProfileMotorcyclist,
            optional: false,
        },
        vru_size_class: { ...VruSizeClass, optional: false },
        roll_angle: { ...VruRollAngle, optional: true },
        vru_safe_distance: {
            ...SequenceOfVruSafeDistanceIndication,
            optional: true,
        },
        path_history: { ...PathHistory, optional: true },
        path_prediction: { ...PathPrediction, optional: true },
        stability_change_indication: {
            ...StabilityChangeIndication,
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Motorcylist Special Container",
};
export const CompleteVehicleCharacteristicsTrailer: MessageFormat<any> = {
    value: TrailerCharacteristics,
    ty: ["array", ["object", null]],
    fieldName: "Complete Vehicle Characteristics Trailer",
    constraints: { min: 1, max: 3 },
};
export const TrainCharacteristics: MessageFormat<any> = {
    ...TractorCharacteristics,
    fieldName: "Train Characteristics",
};
export const CompleteVehicleCharacteristics: MessageFormat<any> = {
    value: {
        tractor: { ...TractorCharacteristics, optional: true },
        trailer: { ...CompleteVehicleCharacteristicsTrailer, optional: true },
        train: { ...TrainCharacteristics, optional: true },
    },
    ty: ["object", null],
    fieldName: "Complete Vehicle Characteristics",
};
export const LaneInformation: MessageFormat<any> = {
    value: {
        lane_number: { ...LanePosition, optional: false },
        direction: { ...Direction, optional: false },
        validity: { ...DTM, optional: true },
        lane_type: { ...LaneType, optional: false },
        lane_type_qualifier: {
            ...CompleteVehicleCharacteristics,
            optional: true,
        },
        lane_status: { ...LaneStatus, optional: false },
        lane_width: { ...IVILaneWidth, optional: true },
    },
    ty: ["object", null],
    fieldName: "Lane Information",
};
export const RccPartLaneConfiguration: MessageFormat<any> = {
    value: LaneInformation,
    ty: ["array", ["object", null]],
    fieldName: "Rcc Part Lane Configuration",
    constraints: { min: 1, max: 16 },
};
export const RccPart: MessageFormat<any> = {
    value: {
        zone_ids: { ...RccPartZoneIds, optional: false },
        road_type: { ...RoadType, optional: false },
        lane_configuration: { ...RccPartLaneConfiguration, optional: false },
    },
    ty: ["object", null],
    fieldName: "Rcc Part",
};
export const RectangularRegion: MessageFormat<any> = {
    value: {
        north_west: { ...Dot2TwoDLocation, optional: false },
        south_east: { ...Dot2TwoDLocation, optional: false },
    },
    ty: ["object", null],
    fieldName: "Rectangular Region",
};
export const RoadConfigurationContainer: MessageFormat<any> = {
    value: RccPart,
    ty: ["array", ["object", null]],
    fieldName: "Road Configuration Container",
    constraints: { min: 1, max: 16 },
};
export const SequenceOfUint16: MessageFormat<any> = {
    value: Uint16,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Uint16",
};
export const RegionAndSubregions: MessageFormat<any> = {
    value: {
        region: { ...Uint8, optional: false },
        subregions: { ...SequenceOfUint16, optional: false },
    },
    ty: ["object", null],
    fieldName: "Region And Subregions",
};
export const RequesterPositionVector: MessageFormat<any> = {
    value: {
        position: { ...Position3D, optional: false },
        heading: { ...Angle, optional: true },
        speed: { ...TransmissionAndSpeed, optional: true },
    },
    ty: ["object", null],
    fieldName: "Requester Position Vector",
};
export const RequesterType: MessageFormat<any> = {
    value: {
        role: { ...BasicVehicleRole, optional: false },
        subrole: { ...RequestSubRole, optional: true },
        request: { ...RequestImportanceLevel, optional: true },
        iso3883: { ...Iso3833VehicleType, optional: true },
        hpms_type: { ...VehicleType, optional: true },
    },
    ty: ["object", null],
    fieldName: "Requester Type",
};
export const VehicleID: MessageFormat<any> = {
    value: { EntityID: TemporaryID, StationID },
    ty: ["choice", null],
    fieldName: "Vehicle ID",
};
export const RequesterDescription: MessageFormat<any> = {
    value: {
        id: { ...VehicleID, optional: false },
        type: { ...RequesterType, optional: true },
        position: { ...RequesterPositionVector, optional: true },
        name: { ...DescriptiveName, optional: true },
        route_name: { ...DescriptiveName, optional: true },
        transit_status: { ...TransitVehicleStatus, optional: true },
        transit_occupancy: { ...TransitVehicleOccupancy, optional: true },
        transit_schedule: { ...DeltaTime, optional: true },
    },
    ty: ["object", null],
    fieldName: "Requester Description",
};
export const RestrictionUserType: MessageFormat<any> = {
    value: { BasicType: RestrictionAppliesTo },
    ty: ["choice", null],
    fieldName: "Restriction User Type",
};
export const RoadLaneSetList: MessageFormat<any> = {
    value: GenericLane,
    ty: ["array", ["object", null]],
    fieldName: "Road Lane Set List",
    constraints: { min: 1, max: 255 },
};
export const EncryptionKey: MessageFormat<any> = {
    value: { Public: PublicEncryptionKey, Symmetric: SymmetricEncryptionKey },
    ty: ["choice", null],
    fieldName: "Encryption Key",
};
export const RoadSegment: MessageFormat<any> = {
    value: {
        name: { ...DescriptiveName, optional: true },
        id: { ...RoadSegmentReferenceID, optional: false },
        revision: { ...MsgCount, optional: false },
        ref_point: { ...Position3D, optional: false },
        lane_width: { ...LaneWidth, optional: true },
        speed_limits: { ...SpeedLimitList, optional: true },
        road_lane_set: { ...RoadLaneSetList, optional: false },
    },
    ty: ["object", null],
    fieldName: "Road Segment",
};
export const IntersectionStateList: MessageFormat<any> = {
    value: IntersectionState,
    ty: ["array", ["object", null]],
    fieldName: "Intersection State List",
    constraints: { min: 1, max: 32 },
};
export const SPAT: MessageFormat<any> = {
    value: {
        time_stamp: { ...MinuteOfTheYear, optional: true },
        name: { ...DescriptiveName, optional: true },
        intersections: { ...IntersectionStateList, optional: false },
    },
    ty: ["object", null],
    fieldName: "SPAT",
};
export const SrvAdvChangeCount: MessageFormat<any> = {
    value: {
        sa_id: { ...SrvAdvID, optional: false },
        content_count: { ...SrvAdvContentCount, optional: false },
    },
    ty: ["object", null],
    fieldName: "Srv Adv Change Count",
};
export const RoutingAdvertisement: MessageFormat<any> = {
    value: {
        lifetime: { ...RouterLifetime, optional: false },
        ip_prefix: { ...IpV6Prefix, optional: false },
        ip_prefix_length: { ...IpV6PrefixLength, optional: false },
        default_gateway: { ...IPv6Address, optional: false },
        primary_dns: { ...IPv6Address, optional: false },
    },
    ty: ["object", null],
    fieldName: "Routing Advertisement",
};
export const AbsolutePosition: MessageFormat<any> = {
    value: {
        latitude: { ...Latitude, optional: false },
        longitude: { ...Longitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Absolute Position",
};
export const AbsolutePositionWAltitude: MessageFormat<any> = {
    value: {
        latitude: { ...Latitude, optional: false },
        longitude: { ...Longitude, optional: false },
        altitude: { ...Altitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Absolute Position W Altitude",
};
export const AbsolutePositions: MessageFormat<any> = {
    value: AbsolutePosition,
    ty: ["array", ["object", null]],
    fieldName: "Absolute Positions",
    constraints: { min: 1, max: 8 },
};
export const AbsolutePositionsWithAltitude: MessageFormat<any> = {
    value: AbsolutePositionWAltitude,
    ty: ["array", ["object", null]],
    fieldName: "Absolute Positions With Altitude",
    constraints: { min: 1, max: 8 },
};
export const DeltaPosition: MessageFormat<any> = {
    value: {
        delta_latitude: { ...DeltaLatitude, optional: false },
        delta_longitude: { ...DeltaLongitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Delta Position",
};
export const DeltaPositions: MessageFormat<any> = {
    value: DeltaPosition,
    ty: ["array", ["object", null]],
    fieldName: "Delta Positions",
    constraints: { min: 1, max: 32 },
};
export const DeltaPositionsWithAltitude: MessageFormat<any> = {
    value: DeltaReferencePosition,
    ty: ["array", ["object", null]],
    fieldName: "Delta Positions With Altitude",
    constraints: { min: 1, max: 32 },
};
export const PolygonalLine: MessageFormat<any> = {
    value: {
        DeltaPositions,
        DeltaPositionsWithAltitude,
        AbsolutePositions,
        AbsolutePositionsWithAltitude,
    },
    ty: ["choice", null],
    fieldName: "Polygonal Line",
};
export const Segment: MessageFormat<any> = {
    value: {
        line: { ...PolygonalLine, optional: false },
        lane_width: { ...IVILaneWidth, optional: true },
    },
    ty: ["object", null],
    fieldName: "Segment",
};
export const AreaRadial: MessageFormat<any> = {
    value: {
        range: { ...Range, optional: false },
        stationary_horizontal_opening_angle_start: {
            ...WGS84AngleValue,
            optional: false,
        },
        stationary_horizontal_opening_angle_end: {
            ...WGS84AngleValue,
            optional: false,
        },
        vertical_opening_angle_start: {
            ...CartesianAngleValue,
            optional: true,
        },
        vertical_opening_angle_end: { ...CartesianAngleValue, optional: true },
        sensor_position_offset: { ...OffsetPoint, optional: true },
        sensor_height: { ...SensorHeight, optional: true },
    },
    ty: ["object", null],
    fieldName: "Area Radial",
};
export const SequenceOfOctetString: MessageFormat<any> = {
    value: AnonymousSequenceOfOctetString,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Octet String",
};
export const ServiceSpecificPermissions: MessageFormat<any> = {
    value: {
        opaque: { value: "", ty: ["hexstring", null], fieldName: "Opaque" },
        BitmapSsp,
    },
    ty: ["choice", null],
    fieldName: "Service Specific Permissions",
};
export const PsidSsp: MessageFormat<any> = {
    value: {
        psid: { ...Psid, optional: false },
        ssp: { ...ServiceSpecificPermissions, optional: true },
    },
    ty: ["object", null],
    fieldName: "Psid Ssp",
};
export const SequenceOfPsidSsp: MessageFormat<any> = {
    value: PsidSsp,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Psid Ssp",
};
export const ReplyAddress: MessageFormat<any> = {
    ...PortNumber,
    fieldName: "Reply Address",
};
export const NodeAttributeSetAddGrpC: MessageFormat<any> = {
    value: {
        ptv_request: { ...PtvRequestType, optional: true },
        node_link: { ...NodeLink, optional: true },
        node: { ...Node, optional: true },
    },
    ty: ["object", null],
    fieldName: "Node Attribute Set AddGrpC",
};
export const ChannelOptions: MessageFormat<any> = {
    value: { ServiceProviderPort: { ...ReplyAddress, optional: true } },
    ty: ["object", null],
    fieldName: "Channel Options",
};
export const ServiceInfo: MessageFormat<any> = {
    value: {
        service_id: { ...ITSaid, optional: false },
        channel_index: { ...ChannelIndex, optional: false },
        ch_options: { ...ChannelOptions, optional: false },
    },
    ty: ["object", null],
    fieldName: "Service Info",
};
export const SignalHeadLocation: MessageFormat<any> = {
    value: {
        node_x_y: { ...NodeOffsetPointXY, optional: false },
        node_z: { ...DeltaAltitude, optional: false },
        signal_group_id: { ...SignalGroupID, optional: false },
    },
    ty: ["object", null],
    fieldName: "Signal Head Location",
};
export const IntersectionAccessPoint: MessageFormat<any> = {
    value: { Lane: LaneID, Approach: ApproachID, Connection: LaneConnectionID },
    ty: ["choice", null],
    fieldName: "Intersection Access Point",
};
export const SignalRequest: MessageFormat<any> = {
    value: {
        id: { ...IntersectionReferenceID, optional: false },
        request_id: { ...RequestID, optional: false },
        request_type: { ...PriorityRequestType, optional: false },
        in_bound_lane: { ...IntersectionAccessPoint, optional: false },
        out_bound_lane: { ...IntersectionAccessPoint, optional: true },
    },
    ty: ["object", null],
    fieldName: "Signal Request",
};
export const ServiceInfos: MessageFormat<any> = {
    value: ServiceInfo,
    ty: ["array", ["object", null]],
    fieldName: "Service Infos",
};
export const SignalRequestPackage: MessageFormat<any> = {
    value: {
        request: { ...SignalRequest, optional: false },
        minute: { ...MinuteOfTheYear, optional: true },
        second: { ...DSecond, optional: true },
        duration: { ...DSecond, optional: true },
    },
    ty: ["object", null],
    fieldName: "Signal Request Package",
};
export const SignalRequestList: MessageFormat<any> = {
    value: SignalRequestPackage,
    ty: ["array", ["object", null]],
    fieldName: "Signal Request List",
    constraints: { min: 1, max: 32 },
};
export const RoadSegmentList: MessageFormat<any> = {
    value: RoadSegment,
    ty: ["array", ["object", null]],
    fieldName: "Road Segment List",
    constraints: { min: 1, max: 32 },
};
export const SignalRequestMessage: MessageFormat<any> = {
    value: {
        time_stamp: { ...MinuteOfTheYear, optional: true },
        second: { ...DSecond, optional: false },
        sequence_number: { ...MsgCount, optional: true },
        requests: { ...SignalRequestList, optional: true },
        requester: { ...RequesterDescription, optional: false },
    },
    ty: ["object", null],
    fieldName: "Signal Request Message",
};
export const SamBody: MessageFormat<any> = {
    value: {
        change_count: { ...SrvAdvChangeCount, optional: false },
        service_infos: { ...ServiceInfos, optional: true },
        channel_infos: { ...ChannelInfos, optional: true },
        routing_advertisement: { ...RoutingAdvertisement, optional: true },
    },
    ty: ["object", null],
    fieldName: "Sam Body",
};
export const SignalRequesterInfo: MessageFormat<any> = {
    value: {
        id: { ...VehicleID, optional: false },
        request: { ...RequestID, optional: false },
        sequence_number: { ...MsgCount, optional: false },
        role: { ...BasicVehicleRole, optional: true },
        type_data: { ...RequesterType, optional: true },
    },
    ty: ["object", null],
    fieldName: "Signal Requester Info",
};
export const Sam: MessageFormat<any> = {
    value: {
        version: { ...RsvAdvPrtVersion, optional: false },
        body: { ...SamBody, optional: false },
    },
    ty: ["object", null],
    fieldName: "Sam",
};
export const SignalStatusPackage: MessageFormat<any> = {
    value: {
        requester: { ...SignalRequesterInfo, optional: true },
        inbound_on: { ...IntersectionAccessPoint, optional: false },
        outbound_on: { ...IntersectionAccessPoint, optional: true },
        minute: { ...MinuteOfTheYear, optional: true },
        second: { ...DSecond, optional: true },
        duration: { ...DSecond, optional: true },
        status: { ...PrioritizationResponseStatus, optional: false },
    },
    ty: ["object", null],
    fieldName: "Signal Status Package",
};
export const SignalStatusPackageList: MessageFormat<any> = {
    value: SignalStatusPackage,
    ty: ["array", ["object", null]],
    fieldName: "Signal Status Package List",
    constraints: { min: 1, max: 32 },
};
export const SignalStatus: MessageFormat<any> = {
    value: {
        sequence_number: { ...MsgCount, optional: false },
        id: { ...IntersectionReferenceID, optional: false },
        sig_status: { ...SignalStatusPackageList, optional: false },
    },
    ty: ["object", null],
    fieldName: "Signal Status",
};
export const SignalStatusList: MessageFormat<any> = {
    value: SignalStatus,
    ty: ["array", ["object", null]],
    fieldName: "Signal Status List",
    constraints: { min: 1, max: 32 },
};
export const SignalStatusMessage: MessageFormat<any> = {
    value: {
        time_stamp: { ...MinuteOfTheYear, optional: true },
        second: { ...DSecond, optional: false },
        sequence_number: { ...MsgCount, optional: true },
        status: { ...SignalStatusList, optional: false },
    },
    ty: ["object", null],
    fieldName: "Signal Status Message",
};
export const EcdsaP256Signature: MessageFormat<any> = {
    value: {
        r_sig: { ...EccP256CurvePoint, optional: false },
        s_sig: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "S Sig",
            optional: false,
            constraints: { min: 32, max: 32 },
        },
    },
    ty: ["object", null],
    fieldName: "Ecdsa P256Signature",
};
export const EcdsaP384Signature: MessageFormat<any> = {
    value: {
        r_sig: { ...EccP384CurvePoint, optional: false },
        s_sig: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "S Sig",
            optional: false,
            constraints: { min: 48, max: 48 },
        },
    },
    ty: ["object", null],
    fieldName: "Ecdsa P384Signature",
};
export const Signature: MessageFormat<any> = {
    value: {
        EcdsaNistP256Signature: EcdsaP256Signature,
        EcdsaBrainpoolP256r1Signature: EcdsaP256Signature,
        EcdsaBrainpoolP384r1Signature: EcdsaP384Signature,
    },
    ty: ["choice", null],
    fieldName: "Signature",
};
export const EventPoint: MessageFormat<any> = {
    value: {
        event_position: { ...DeltaReferencePosition, optional: false },
        event_delta_time: { ...PathDeltaTime, optional: true },
        information_quality: { ...InformationQuality, optional: false },
    },
    ty: ["object", null],
    fieldName: "Event Point",
};
export const EventHistory: MessageFormat<any> = {
    value: EventPoint,
    ty: ["array", ["object", null]],
    fieldName: "Event History",
    constraints: { min: 1, max: 23 },
};
export const SituationContainer: MessageFormat<any> = {
    value: {
        information_quality: { ...InformationQuality, optional: false },
        event_type: { ...CauseCode, optional: false },
        linked_cause: { ...CauseCode, optional: true },
        event_history: { ...EventHistory, optional: true },
    },
    ty: ["object", null],
    fieldName: "Situation Container",
};
export const SrmPrvChAllocReq: MessageFormat<any> = {
    value: ITSaid,
    ty: ["array", ["object", null]],
    fieldName: "Srm Prv Ch AllocReq",
};
export const SrmPrivateChannelsRq: MessageFormat<any> = {
    value: {
        port_dyn_sam: { ...PortNumber, optional: false },
        alloc_reqs: { ...SrmPrvChAllocReq, optional: false },
    },
    ty: ["object", null],
    fieldName: "Srm Private Channels Rq",
};
export const SrmPrvChAllocConf: MessageFormat<any> = {
    value: ITSaid,
    ty: ["array", ["object", null]],
    fieldName: "Srm Prv Ch AllocConf",
};
export const SrmBody: MessageFormat<any> = {
    value: {
        prv_channels_rq: { ...SrmPrivateChannelsRq, optional: true },
        prv_channels_cf: { ...SrmPrvChAllocConf, optional: true },
    },
    ty: ["object", null],
    fieldName: "Srm Body",
};
export const Srm: MessageFormat<any> = {
    value: {
        header: { ...RsvAdvPrtVersion, optional: false },
        body: { ...SrmBody, optional: false },
    },
    ty: ["object", null],
    fieldName: "Srm",
};
export const SrmContext: MessageFormat<any> = {
    value: { ClientPort: { ...PortNumber, optional: false } },
    ty: ["object", null],
    fieldName: "Srm Context",
};
export const BitmapSspRange: MessageFormat<any> = {
    value: {
        ssp_value: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Ssp Value",
            optional: false,
            constraints: { min: 1, max: 32 },
        },
        ssp_bitmask: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Ssp Bitmask",
            optional: false,
            constraints: { min: 1, max: 32 },
        },
    },
    ty: ["object", null],
    fieldName: "Bitmap Ssp Range",
};
export const SspRange: MessageFormat<any> = {
    value: {
        Opaque: SequenceOfOctetString,
        all: { value: null, ty: ["null", null], fieldName: "" },
        BitmapSspRange,
    },
    ty: ["choice", null],
    fieldName: "Ssp Range",
};
export const RestrictionUserTypeList: MessageFormat<any> = {
    value: RestrictionUserType,
    ty: ["array", ["object", null]],
    fieldName: "Restriction User Type List",
    constraints: { min: 1, max: 16 },
};
export const RestrictionClassAssignment: MessageFormat<any> = {
    value: {
        id: { ...RestrictionClassID, optional: false },
        users: { ...RestrictionUserTypeList, optional: false },
    },
    ty: ["object", null],
    fieldName: "Restriction Class Assignment",
};
export const RestrictionClassList: MessageFormat<any> = {
    value: RestrictionClassAssignment,
    ty: ["array", ["object", null]],
    fieldName: "Restriction Class List",
    constraints: { min: 1, max: 254 },
};
export const TcPartDetectionZoneIds: MessageFormat<any> = {
    value: Zid,
    ty: ["array", ["object", null]],
    fieldName: "Tc Part Detection ZoneIds",
    constraints: { min: 1, max: 8 },
};
export const TcPartRelevanceZoneIds: MessageFormat<any> = {
    value: Zid,
    ty: ["array", ["object", null]],
    fieldName: "Tc Part Relevance ZoneIds",
    constraints: { min: 1, max: 8 },
};
export const TcPartDriverAwarenessZoneIds: MessageFormat<any> = {
    value: Zid,
    ty: ["array", ["object", null]],
    fieldName: "Tc Part Driver AwarenessZoneIds",
    constraints: { min: 1, max: 8 },
};
export const TcPartApplicableLanes: MessageFormat<any> = {
    value: LanePosition,
    ty: ["array", ["object", null]],
    fieldName: "Tc Part Applicable Lanes",
    constraints: { min: 1, max: 8 },
};
export const SALatitude: MessageFormat<any> = {
    value: {
        fill_bit: {
            value: "",
            ty: ["bitstring", null],
            fieldName: "Fill Bit",
            optional: false,
            constraints: { min: 1, max: 1 },
        },
        lat: {
            value: 0,
            ty: ["number", null],
            fieldName: "Lat",
            optional: false,
            constraints: { min: -900000000, max: 900000001 },
        },
    },
    ty: ["object", null],
    fieldName: "S A Latitude",
};
export const TcPartText: MessageFormat<any> = {
    value: Text,
    ty: ["array", ["object", null]],
    fieldName: "Tc Part Text",
    constraints: { min: 1, max: 4 },
};
export const TcPart: MessageFormat<any> = {
    value: {
        detection_zone_ids: { ...TcPartDetectionZoneIds, optional: true },
        relevance_zone_ids: { ...TcPartRelevanceZoneIds, optional: false },
        direction: { ...Direction, optional: true },
        driver_awareness_zone_ids: {
            ...TcPartDriverAwarenessZoneIds,
            optional: true,
        },
        minimum_awareness_time: {
            value: 0,
            ty: ["number", null],
            fieldName: "Minimum Awareness Time",
            optional: true,
            constraints: { min: 0, max: 255 },
        },
        applicable_lanes: { ...TcPartApplicableLanes, optional: true },
        layout_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Layout Id",
            optional: true,
            constraints: { min: 1, max: 4 },
        },
        pre_storedlayout_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Pre Storedlayout Id",
            optional: true,
            constraints: { min: 1, max: 64 },
        },
        text: { ...TcPartText, optional: true },
        data: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "Data",
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Tc Part",
};
export const ThreeDLocation: MessageFormat<any> = {
    value: {
        latitude: { ...SALatitude, optional: false },
        longitude: { ...SALongitude, optional: false },
        elevation: { ...SAElevation, optional: false },
    },
    ty: ["object", null],
    fieldName: "Three D Location",
};
export const PsidSspRange: MessageFormat<any> = {
    value: {
        psid: { ...Psid, optional: false },
        ssp_range: { ...SspRange, optional: true },
    },
    ty: ["object", null],
    fieldName: "Psid Ssp Range",
};
export const TwoDLocation: MessageFormat<any> = {
    value: {
        latitude: { ...SALatitude, optional: false },
        longitude: { ...SALongitude, optional: false },
    },
    ty: ["object", null],
    fieldName: "Two D Location",
};
export const SequenceOfPsidSspRange: MessageFormat<any> = {
    value: PsidSspRange,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Psid SspRange",
};
export const GicPartDetectionZoneIds: MessageFormat<any> = {
    value: Zid,
    ty: ["array", ["object", null]],
    fieldName: "Gic Part Detection ZoneIds",
    constraints: { min: 1, max: 8 },
};
export const GicPartRelevanceZoneIds: MessageFormat<any> = {
    value: Zid,
    ty: ["array", ["object", null]],
    fieldName: "Gic Part Relevance ZoneIds",
    constraints: { min: 1, max: 8 },
};
export const GicPartDriverAwarenessZoneIds: MessageFormat<any> = {
    value: Zid,
    ty: ["array", ["object", null]],
    fieldName: "Gic Part Driver AwarenessZoneIds",
    constraints: { min: 1, max: 8 },
};
export const GicPartApplicableLanes: MessageFormat<any> = {
    value: LanePosition,
    ty: ["array", ["object", null]],
    fieldName: "Gic Part Applicable Lanes",
    constraints: { min: 1, max: 8 },
};
export const GicPartVehicleCharacteristics: MessageFormat<any> = {
    value: CompleteVehicleCharacteristics,
    ty: ["array", ["object", null]],
    fieldName: "Gic Part Vehicle Characteristics",
    constraints: { min: 1, max: 8 },
};
export const GicPartRoadSignCodes: MessageFormat<any> = {
    value: RSCode,
    ty: ["array", ["object", null]],
    fieldName: "Gic Part Road SignCodes",
    constraints: { min: 1, max: 4 },
};
export const GicPartExtraText: MessageFormat<any> = {
    value: Text,
    ty: ["array", ["object", null]],
    fieldName: "Gic Part Extra Text",
    constraints: { min: 1, max: 4 },
};
export const GicPart: MessageFormat<any> = {
    value: {
        detection_zone_ids: { ...GicPartDetectionZoneIds, optional: true },
        its_rrid: { ...VarLengthNumber, optional: true },
        relevance_zone_ids: { ...GicPartRelevanceZoneIds, optional: true },
        direction: { ...Direction, optional: true },
        driver_awareness_zone_ids: {
            ...GicPartDriverAwarenessZoneIds,
            optional: true,
        },
        minimum_awareness_time: {
            value: 0,
            ty: ["number", null],
            fieldName: "Minimum Awareness Time",
            optional: true,
            constraints: { min: 0, max: 255 },
        },
        applicable_lanes: { ...GicPartApplicableLanes, optional: true },
        ivi_type: { ...IviType, optional: false },
        ivi_purpose: { ...IviPurpose, optional: true },
        lane_status: { ...LaneStatus, optional: true },
        vehicle_characteristics: {
            ...GicPartVehicleCharacteristics,
            optional: true,
        },
        driver_characteristics: { ...DriverCharacteristics, optional: true },
        layout_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Layout Id",
            optional: true,
            constraints: { min: 1, max: 4 },
        },
        pre_storedlayout_id: {
            value: 0,
            ty: ["number", null],
            fieldName: "Pre Storedlayout Id",
            optional: true,
            constraints: { min: 1, max: 64 },
        },
        road_sign_codes: { ...GicPartRoadSignCodes, optional: false },
        extra_text: { ...GicPartExtraText, optional: true },
    },
    ty: ["object", null],
    fieldName: "Gic Part",
};
export const GeneralIviContainer: MessageFormat<any> = {
    value: GicPart,
    ty: ["array", ["object", null]],
    fieldName: "General Ivi Container",
    constraints: { min: 1, max: 16 },
};
export const TextContainer: MessageFormat<any> = {
    value: TcPart,
    ty: ["array", ["object", null]],
    fieldName: "Text Container",
    constraints: { min: 1, max: 16 },
};
export const ComputedSegment: MessageFormat<any> = {
    value: {
        zone_id: { ...Zid, optional: false },
        lane_number: { ...LanePosition, optional: false },
        lane_width: { ...IVILaneWidth, optional: false },
        offset_distance: {
            value: 0,
            ty: ["number", null],
            fieldName: "Offset Distance",
            optional: true,
            constraints: { min: -32768, max: 32767 },
        },
        offset_position: { ...DeltaReferencePosition, optional: true },
    },
    ty: ["object", null],
    fieldName: "Computed Segment",
};
export const Zone: MessageFormat<any> = {
    value: {
        Segment,
        Area: PolygonalLine,
        ComputedSegment,
    },
    ty: ["choice", null],
    fieldName: "Zone",
};
export const GlcPart: MessageFormat<any> = {
    value: {
        zone_id: { ...Zid, optional: false },
        lane_number: { ...LanePosition, optional: true },
        zone_extension: {
            value: 0,
            ty: ["number", null],
            fieldName: "Zone Extension",
            optional: true,
            constraints: { min: 0, max: 255 },
        },
        zone_heading: { ...HeadingValue, optional: true },
        zone: { ...Zone, optional: true },
    },
    ty: ["object", null],
    fieldName: "Glc Part",
};
export const GeographicLocationContainerParts: MessageFormat<any> = {
    value: GlcPart,
    ty: ["array", ["object", null]],
    fieldName: "Geographic Location Container Parts",
    constraints: { min: 1, max: 16 },
};
export const GeographicLocationContainer: MessageFormat<any> = {
    value: {
        reference_position: { ...ReferencePosition, optional: false },
        reference_position_time: { ...TimestampIts, optional: true },
        reference_position_heading: { ...Heading, optional: true },
        reference_position_speed: { ...Speed, optional: true },
        parts: { ...GeographicLocationContainerParts, optional: false },
    },
    ty: ["object", null],
    fieldName: "Geographic Location Container",
};
export const IviContainer: MessageFormat<any> = {
    value: {
        Glc: GeographicLocationContainer,
        Giv: GeneralIviContainer,
        Rcc: RoadConfigurationContainer,
        Tc: TextContainer,
        Lac: LayoutContainer,
    },
    ty: ["choice", null],
    fieldName: "Ivi Container",
};
export const IviStructureOptional: MessageFormat<any> = {
    value: IviContainer,
    ty: ["array", ["object", null]],
    fieldName: "Ivi Structure Optional",
    constraints: { min: 1, max: 8 },
};
export const IVIManagementContainerConnectedIviStructures: MessageFormat<any> =
    {
        value: IviIdentificationNumber,
        ty: ["array", ["object", null]],
        fieldName: "IVI Management Container: Connected Ivi-Structures",
        constraints: { min: 1, max: 8 },
    };
export const IVIManagementContainer: MessageFormat<any> = {
    value: {
        service_provider_id: { ...Provider, optional: false },
        ivi_identification_number: {
            ...IviIdentificationNumber,
            optional: false,
        },
        time_stamp: { ...TimestampIts, optional: true },
        valid_from: { ...TimestampIts, optional: true },
        valid_to: { ...TimestampIts, optional: true },
        connected_ivi_structures: {
            ...IVIManagementContainerConnectedIviStructures,
            optional: true,
        },
        ivi_status: { ...IviStatus, optional: false },
    },
    ty: ["object", null],
    fieldName: "IVI ManagementContainer",
};
export const IviStructure: MessageFormat<any> = {
    value: {
        mandatory: { ...IVIManagementContainer, optional: false },
        optional: { ...IviStructureOptional, optional: true },
    },
    ty: ["object", null],
    fieldName: "Ivi Structure",
};
export const VehicleSensorProperties: MessageFormat<any> = {
    value: {
        range: { ...Range, optional: false },
        horizontal_opening_angle_start: {
            ...CartesianAngleValue,
            optional: false,
        },
        horizontal_opening_angle_end: {
            ...CartesianAngleValue,
            optional: false,
        },
        vertical_opening_angle_start: {
            ...CartesianAngleValue,
            optional: true,
        },
        vertical_opening_angle_end: { ...CartesianAngleValue, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vehicle Sensor Properties",
};
export const VehicleSensorPropertyList: MessageFormat<any> = {
    value: VehicleSensorProperties,
    ty: ["array", ["object", null]],
    fieldName: "Vehicle Sensor Property List",
    constraints: { min: 1, max: 10 },
};
export const VehicleLength: MessageFormat<any> = {
    value: {
        vehicle_length_value: { ...VehicleLengthValue, optional: false },
        vehicle_length_confidence_indication: {
            ...VehicleLengthConfidenceIndication,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Vehicle Length",
};
export const VehicleSensor: MessageFormat<any> = {
    value: {
        ref_point_id: { ...RefPointId, optional: true },
        x_sensor_offset: { ...XSensorOffset, optional: false },
        y_sensor_offset: { ...YSensorOffset, optional: false },
        z_sensor_offset: { ...ZSensorOffset, optional: true },
        vehicle_sensor_property_list: {
            ...VehicleSensorPropertyList,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Vehicle Sensor",
};
export const DetectionArea: MessageFormat<any> = {
    value: {
        VehicleSensor,
        StationarySensorRadial: AreaRadial,
        StationarySensorPolygon: AreaPolygon,
        StationarySensorCircular: AreaCircular,
        StationarySensorEllipse: AreaEllipse,
        StationarySensorRectangle: AreaRectangle,
    },
    ty: ["choice", null],
    fieldName: "Detection Area",
};
export const SensorInformation: MessageFormat<any> = {
    value: {
        sensor_id: { ...Identifier, optional: false },
        type: { ...SensorType, optional: false },
        detection_area: { ...DetectionArea, optional: false },
        free_space_confidence: { ...FreeSpaceConfidence, optional: true },
    },
    ty: ["object", null],
    fieldName: "Sensor Information",
};
export const VehicleSubclass: MessageFormat<any> = {
    value: {
        type: { ...VehicleSubclassType, optional: true },
        confidence: { ...ClassConfidence, optional: true },
    },
    ty: ["object", null],
    fieldName: "Vehicle Subclass",
};
export const ObjectClassChoice: MessageFormat<any> = {
    value: {
        Vehicle: VehicleSubclass,
        Person: PersonSubclass,
        Animal: AnimalSubclass,
        Other: OtherSubclass,
    },
    ty: ["choice", null],
    fieldName: "Object Class Choice",
};
export const ObjectClass: MessageFormat<any> = {
    value: {
        confidence: { ...ClassConfidence, optional: false },
        class: { ...ObjectClassChoice, optional: false },
    },
    ty: ["object", null],
    fieldName: "Object Class",
};
export const ProtectedCommunicationZonesRSU: MessageFormat<any> = {
    value: ProtectedCommunicationZone,
    ty: ["array", ["object", null]],
    fieldName: "Protected Communication Zones RSU",
    constraints: { min: 1, max: 16 },
};
export const RSUContainerHighFrequency: MessageFormat<any> = {
    value: {
        protected_communication_zones_r_s_u: {
            ...ProtectedCommunicationZonesRSU,
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "RSU ContainerHighFrequency",
};
export const ClusterBoundingBoxShape: MessageFormat<any> = {
    value: {
        ClusterRectangle: AreaRectangle,
        ClusterCircle: AreaCircular,
        ClusterPolygon: AreaPolygon,
    },
    ty: ["choice", null],
    fieldName: "Cluster Bounding Box Shape",
};
export const VruClusterInformationContainer: MessageFormat<any> = {
    value: {
        cluster_id: { ...ClusterId, optional: false },
        cluster_bounding_box_shape: {
            ...ClusterBoundingBoxShape,
            optional: false,
        },
        cluster_cardinality_size: {
            value: 0,
            ty: ["number", null],
            fieldName: "Cluster Cardinality Size",
            optional: false,
            constraints: { min: 0, max: 255 },
        },
        cluster_profiles: { ...ClusterProfiles, optional: false },
    },
    ty: ["object", null],
    fieldName: "Vru Cluster Information Container",
};
export const BasicVehicleContainerHighFrequency: MessageFormat<any> = {
    value: {
        heading: { ...Heading, optional: false },
        speed: { ...Speed, optional: false },
        drive_direction: { ...DriveDirection, optional: false },
        vehicle_length: { ...VehicleLength, optional: false },
        vehicle_width: { ...VehicleWidth, optional: false },
        longitudinal_acceleration: {
            ...LongitudinalAcceleration,
            optional: false,
        },
        curvature: { ...Curvature, optional: false },
        curvature_calculation_mode: {
            ...CurvatureCalculationMode,
            optional: false,
        },
        yaw_rate: { ...YawRate, optional: false },
        acceleration_control: { ...AccelerationControl, optional: true },
        lane_position: { ...LanePosition, optional: true },
        steering_wheel_angle: { ...SteeringWheelAngle, optional: true },
        lateral_acceleration: { ...LateralAcceleration, optional: true },
        vertical_acceleration: { ...VerticalAcceleration, optional: true },
        performance_class: { ...PerformanceClass, optional: true },
        cen_dsrc_tolling_zone: { ...CenDsrcTollingZone, optional: true },
    },
    ty: ["object", null],
    fieldName: "Basic Vehicle Container HighFrequency",
};
export const HighFrequencyContainer: MessageFormat<any> = {
    value: {
        BasicVehicleContainerHighFrequency,
        RsuContainerHighFrequency: RSUContainerHighFrequency,
    },
    ty: ["choice", null],
    fieldName: "High Frequency Container",
};
export const BasicVehicleContainerLowFrequency: MessageFormat<any> = {
    value: {
        vehicle_role: { ...VehicleRole, optional: false },
        exterior_lights: { ...ExteriorLights, optional: false },
        path_history: { ...PathHistory, optional: false },
    },
    ty: ["object", null],
    fieldName: "Basic Vehicle Container LowFrequency",
};
export const LowFrequencyContainer: MessageFormat<any> = {
    value: {
        BasicVehicleContainerLowFrequency,
    },
    ty: ["choice", null],
    fieldName: "Low Frequency Container",
};
export const ObjectDimension: MessageFormat<any> = {
    value: {
        value: { ...ObjectDimensionValue, optional: false },
        confidence: { ...ObjectDimensionConfidence, optional: false },
    },
    ty: ["object", null],
    fieldName: "Object Dimension",
};
export const ObjectClassDescription: MessageFormat<any> = {
    value: ObjectClass,
    ty: ["array", ["object", null]],
    fieldName: "Object Class Description",
    constraints: { min: 1, max: 8 },
};
export const PerceivedObject: MessageFormat<any> = {
    value: {
        object_id: { ...Identifier, optional: false },
        sensor_id_list: { ...SensorIdList, optional: true },
        time_of_measurement: { ...TimeOfMeasurement, optional: false },
        object_age: { ...ObjectAge, optional: true },
        object_confidence: { ...ObjectConfidence, optional: true },
        x_distance: { ...ObjectDistanceWithConfidence, optional: false },
        y_distance: { ...ObjectDistanceWithConfidence, optional: false },
        z_distance: { ...ObjectDistanceWithConfidence, optional: true },
        x_speed: { ...SpeedExtended, optional: false },
        y_speed: { ...SpeedExtended, optional: false },
        z_speed: { ...SpeedExtended, optional: true },
        x_acceleration: { ...LongitudinalAcceleration, optional: true },
        y_acceleration: { ...LateralAcceleration, optional: true },
        z_acceleration: { ...VerticalAcceleration, optional: true },
        yaw_angle: { ...CartesianAngle, optional: true },
        planar_object_dimension1: { ...ObjectDimension, optional: true },
        planar_object_dimension2: { ...ObjectDimension, optional: true },
        vertical_object_dimension: { ...ObjectDimension, optional: true },
        object_ref_point: { ...ObjectRefPoint, optional: true },
        dynamic_status: { ...DynamicStatus, optional: true },
        classification: { ...ObjectClassDescription, optional: true },
        matched_position: { ...MatchedPosition, optional: true },
    },
    ty: ["object", null],
    fieldName: "Perceived Object",
};
export const PerceivedObjectContainer: MessageFormat<any> = {
    value: PerceivedObject,
    ty: ["array", ["object", null]],
    fieldName: "Perceived Object Container",
    constraints: { min: 1, max: 128 },
};
export const CrlSeries: MessageFormat<any> = {
    ...Uint16,
    fieldName: "Crl Series",
};
export const DangerousGoodsContainer: MessageFormat<any> = {
    value: { DangerousGoodsBasic: { ...DangerousGoodsBasic, optional: false } },
    ty: ["object", null],
    fieldName: "Dangerous Goods Container",
};
export const EmergencyContainer: MessageFormat<any> = {
    value: {
        light_bar_siren_in_use: { ...LightBarSirenInUse, optional: false },
        incident_indication: { ...CauseCode, optional: true },
        emergency_priority: { ...EmergencyPriority, optional: true },
    },
    ty: ["object", null],
    fieldName: "Emergency Container",
};
export const PublicTransportContainer: MessageFormat<any> = {
    value: {
        embarkation_status: { ...EmbarkationStatus, optional: false },
        pt_activation: { ...PtActivation, optional: true },
    },
    ty: ["object", null],
    fieldName: "Public Transport Container",
};
export const RescueContainer: MessageFormat<any> = {
    value: { LightBarSirenInUse: { ...LightBarSirenInUse, optional: false } },
    ty: ["object", null],
    fieldName: "Rescue Container",
};
export const RoadWorksContainerBasic: MessageFormat<any> = {
    value: {
        roadworks_sub_cause_code: { ...RoadworksSubCauseCode, optional: true },
        light_bar_siren_in_use: { ...LightBarSirenInUse, optional: false },
        closed_lanes: { ...ClosedLanes, optional: true },
    },
    ty: ["object", null],
    fieldName: "Road Works Container Basic",
};
export const SafetyCarContainer: MessageFormat<any> = {
    value: {
        light_bar_siren_in_use: { ...LightBarSirenInUse, optional: false },
        incident_indication: { ...CauseCode, optional: true },
        traffic_rule: { ...TrafficRule, optional: true },
        speed_limit: { ...SpeedLimit, optional: true },
    },
    ty: ["object", null],
    fieldName: "Safety Car Container",
};
export const SpecialTransportContainer: MessageFormat<any> = {
    value: {
        special_transport_type: { ...SpecialTransportType, optional: false },
        light_bar_siren_in_use: { ...LightBarSirenInUse, optional: false },
    },
    ty: ["object", null],
    fieldName: "Special Transport Container",
};
export const TrailerDataContainer: MessageFormat<any> = {
    value: TrailerData,
    ty: ["array", ["object", null]],
    fieldName: "Trailer Data Container",
    constraints: { min: 1, max: 2 },
};
export const SpecialVehicleContainer: MessageFormat<any> = {
    value: {
        PublicTransportContainer,
        SpecialTransportContainer,
        DangerousGoodsContainer,
        RoadWorksContainerBasic,
        RescueContainer,
        EmergencyContainer,
        SafetyCarContainer,
    },
    ty: ["choice", null],
    fieldName: "Special Vehicle Container",
};
export const PerceivedObjectContainerSegmentInfo: MessageFormat<any> = {
    value: {
        total_msg_segments: { ...SegmentCount, optional: false },
        this_segment_num: { ...SegmentCount, optional: false },
    },
    ty: ["object", null],
    fieldName: "Perceived Object Container SegmentInfo",
};
export const CpmManagementContainer: MessageFormat<any> = {
    value: {
        station_type: { ...StationType, optional: false },
        perceived_object_container_segment_info: {
            ...PerceivedObjectContainerSegmentInfo,
            optional: true,
        },
        reference_position: { ...ReferencePosition, optional: false },
    },
    ty: ["object", null],
    fieldName: "Cpm Management Container",
};
export const VruMotionPredictionContainer: MessageFormat<any> = {
    value: {
        path_history: { ...PathHistory, optional: true },
        path_prediction: { ...PathPrediction, optional: true },
        safe_distance: {
            ...SequenceOfVruSafeDistanceIndication,
            optional: true,
        },
        trajectory_change_indication: {
            ...SequenceOfTrajectoryInterceptionIndication,
            optional: true,
        },
        acceleration_change_indication: {
            ...AccelerationChangeIndication,
            optional: true,
        },
        heading_change_indication: {
            ...HeadingChangeIndication,
            optional: true,
        },
        stability_change_indication: {
            ...StabilityChangeIndication,
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Vru Motion Prediction Container",
};
export const FreeSpaceAddendumContainer: MessageFormat<any> = {
    value: FreeSpaceAddendum,
    ty: ["array", ["object", null]],
    fieldName: "Free Space Addendum Container",
    constraints: { min: 1, max: 128 },
};
export const SensorInformationContainer: MessageFormat<any> = {
    value: SensorInformation,
    ty: ["array", ["object", null]],
    fieldName: "Sensor Information Container",
    constraints: { min: 1, max: 128 },
};
export const OriginatingRSUContainer: MessageFormat<any> = {
    value: {
        IntersectionReferenceId: IntersectionReferenceID,
        RoadSegmentReferenceId: RoadSegmentReferenceID,
    },
    ty: ["choice", null],
    fieldName: "Originating RSUContainer",
};
export const OriginatingVehicleContainer: MessageFormat<any> = {
    value: {
        heading: { ...Heading, optional: false },
        speed: { ...Speed, optional: false },
        vehicle_orientation_angle: { ...WGS84Angle, optional: true },
        drive_direction: { ...DriveDirection, optional: true },
        longitudinal_acceleration: {
            ...LongitudinalAcceleration,
            optional: true,
        },
        lateral_acceleration: { ...LateralAcceleration, optional: true },
        vertical_acceleration: { ...VerticalAcceleration, optional: true },
        yaw_rate: { ...YawRate, optional: true },
        pitch_angle: { ...CartesianAngle, optional: true },
        roll_angle: { ...CartesianAngle, optional: true },
        vehicle_length: { ...VehicleLength, optional: true },
        vehicle_width: { ...VehicleWidth, optional: true },
        vehicle_height: { ...VehicleHeight, optional: true },
        trailer_data_container: { ...TrailerDataContainer, optional: true },
    },
    ty: ["object", null],
    fieldName: "Originating Vehicle Container",
};
export const StationDataContainer: MessageFormat<any> = {
    value: {
        OriginatingVehicleContainer,
        OriginatingRSUContainer,
    },
    ty: ["choice", null],
    fieldName: "Station Data Container",
};
export const CpmParameters: MessageFormat<any> = {
    value: {
        management_container: { ...CpmManagementContainer, optional: false },
        station_data_container: { ...StationDataContainer, optional: true },
        sensor_information_container: {
            ...SensorInformationContainer,
            optional: true,
        },
        perceived_object_container: {
            ...PerceivedObjectContainer,
            optional: true,
        },
        free_space_addendum_container: {
            ...FreeSpaceAddendumContainer,
            optional: true,
        },
        number_of_perceived_objects: {
            ...NumberOfPerceivedObjects,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Cpm Parameters",
};
export const MapData: MessageFormat<any> = {
    value: {
        time_stamp: { ...MinuteOfTheYear, optional: true },
        msg_issue_revision: { ...MsgCount, optional: false },
        layer_type: { ...LayerType, optional: true },
        layer_id: { ...LayerID, optional: true },
        intersections: { ...IntersectionGeometryList, optional: true },
        road_segments: { ...RoadSegmentList, optional: true },
        data_parameters: { ...DataParameters, optional: true },
        restriction_list: { ...RestrictionClassList, optional: true },
    },
    ty: ["object", null],
    fieldName: "Map Data",
};
export const PreCrashContainer: MessageFormat<any> = {
    value: {
        perceived_object: { ...PerceivedObject, optional: false },
        object_station_id: { ...StationID, optional: true },
        time_to_collision: { ...TransmissionInterval, optional: true },
        impact_section: { ...ImpactSection, optional: true },
        host_vehicle_orientation: { ...WGS84Angle, optional: false },
    },
    ty: ["object", null],
    fieldName: "Pre Crash Container",
};
export const BasicContainer: MessageFormat<any> = {
    value: {
        station_type: { ...StationType, optional: false },
        reference_position: { ...ReferencePosition, optional: false },
    },
    ty: ["object", null],
    fieldName: "Basic Container",
};
export const CamParameters: MessageFormat<any> = {
    value: {
        basic_container: { ...BasicContainer, optional: false },
        high_frequency_container: {
            ...HighFrequencyContainer,
            optional: false,
        },
        low_frequency_container: { ...LowFrequencyContainer, optional: true },
        special_vehicle_container: {
            ...SpecialVehicleContainer,
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Cam Parameters",
};
export const CoopAwareness: MessageFormat<any> = {
    value: {
        generation_delta_time: { ...GenerationDeltaTime, optional: false },
        cam_parameters: { ...CamParameters, optional: false },
    },
    ty: ["object", null],
    fieldName: "Coop Awareness",
};
export const CollectivePerceptionMessage: MessageFormat<any> = {
    value: {
        generation_delta_time: { ...GenerationDeltaTime, optional: false },
        cpm_parameters: { ...CpmParameters, optional: false },
    },
    ty: ["object", null],
    fieldName: "Collective Perception Message",
};
export const EciesP256EncryptedKey: MessageFormat<any> = {
    value: {
        v: { ...EccP256CurvePoint, optional: false },
        c: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "C",
            optional: false,
            constraints: { min: 16, max: 16 },
        },
        t: {
            value: "",
            ty: ["hexstring", null],
            fieldName: "T",
            optional: false,
            constraints: { min: 16, max: 16 },
        },
    },
    ty: ["object", null],
    fieldName: "Ecies P256EncryptedKey",
};
export const EVChargingSpotNotificationPOIMessage: MessageFormat<any> = {
    value: {
        poi_header: { ...ItsPOIHeader, optional: false },
        evcsn_data: { ...ItsEVCSNData, optional: false },
    },
    ty: ["object", null],
    fieldName: "E V Charging SpotNotificationPOIMessage",
};
export const EvcsnPdu: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        evcsn: { ...EVChargingSpotNotificationPOIMessage, optional: false },
    },
    ty: ["object", null],
    fieldName: "Evcsn Pdu",
};
export const GatewayMacAddress: MessageFormat<any> = {
    ...MACaddress,
    fieldName: "Gateway Mac Address",
};
export const CountryAndRegions: MessageFormat<any> = {
    value: {
        country_only: { ...CountryOnly, optional: false },
        regions: { ...SequenceOfUint8, optional: false },
    },
    ty: ["object", null],
    fieldName: "Country And Regions",
};
export const SequenceOfRegionAndSubregions: MessageFormat<any> = {
    value: RegionAndSubregions,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Region AndSubregions",
};
export const CountryAndSubregions: MessageFormat<any> = {
    value: {
        country: { ...CountryOnly, optional: false },
        region_and_subregions: {
            ...SequenceOfRegionAndSubregions,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "Country And Subregions",
};
export const IdentifiedRegion: MessageFormat<any> = {
    value: {
        CountryOnly,
        CountryAndRegions,
        CountryAndSubregions,
    },
    ty: ["choice", null],
    fieldName: "Identified Region",
};
export const SequenceOfIdentifiedRegion: MessageFormat<any> = {
    value: IdentifiedRegion,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Identified Region",
};
export const SignalHeadLocationList: MessageFormat<any> = {
    value: SignalHeadLocation,
    ty: ["array", ["object", null]],
    fieldName: "Signal Head Location List",
    constraints: { min: 1, max: 64 },
};
export const PolygonalRegion: MessageFormat<any> = {
    value: Dot2TwoDLocation,
    ty: ["array", ["object", null]],
    fieldName: "Polygonal Region",
};
export const AlacarteContainer: MessageFormat<any> = {
    value: {
        lane_position: { ...LanePosition, optional: true },
        impact_reduction: { ...ImpactReductionContainer, optional: true },
        external_temperature: { ...Temperature, optional: true },
        road_works: { ...RoadWorksContainerExtended, optional: true },
        positioning_solution: { ...PositioningSolutionType, optional: true },
        stationary_vehicle: { ...StationaryVehicleContainer, optional: true },
        pre_crash_container: { ...PreCrashContainer, optional: true },
    },
    ty: ["object", null],
    fieldName: "Alacarte Container",
};
export const SequenceOfRectangularRegion: MessageFormat<any> = {
    value: RectangularRegion,
    ty: ["array", ["object", null]],
    fieldName: "Sequence Of Rectangular Region",
};
export const CircularRegion: MessageFormat<any> = {
    value: {
        center: { ...Dot2TwoDLocation, optional: false },
        radius: { ...Uint16, optional: false },
    },
    ty: ["object", null],
    fieldName: "Circular Region",
};
export const GeographicRegion: MessageFormat<any> = {
    value: {
        CircularRegion,
        RectangularRegion: SequenceOfRectangularRegion,
        PolygonalRegion,
        IdentifiedRegion: SequenceOfIdentifiedRegion,
    },
    ty: ["choice", null],
    fieldName: "Geographic Region",
};
export const CAM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        cam: { ...CoopAwareness, optional: false },
    },
    ty: ["object", null],
    fieldName: "CAM",
};
export const CPM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        cpm: { ...CollectivePerceptionMessage, optional: false },
    },
    ty: ["object", null],
    fieldName: "CPM",
};
export const DecentralizedEnvironmentalNotificationMessage: MessageFormat<any> =
    {
        value: {
            management: { ...ManagementContainer, optional: false },
            situation: { ...SituationContainer, optional: true },
            location: { ...LocationContainer, optional: true },
            alacarte: { ...AlacarteContainer, optional: true },
        },
        ty: ["object", null],
        fieldName: "Decentralized Environmental Notification Message",
    };
export const DENM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        denm: {
            ...DecentralizedEnvironmentalNotificationMessage,
            optional: false,
        },
    },
    ty: ["object", null],
    fieldName: "DENM",
};
export const MAPEM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        map: { ...MapData, optional: false },
    },
    ty: ["object", null],
    fieldName: "MAPEM",
};
export const RTCMEM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        rtcmc: { ...RTCMcorrections, optional: false },
    },
    ty: ["object", null],
    fieldName: "RTCMEM",
};
export const SREM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        srm: { ...SignalRequestMessage, optional: false },
    },
    ty: ["object", null],
    fieldName: "SREM",
};
export const SSEM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        ssm: { ...SignalStatusMessage, optional: false },
    },
    ty: ["object", null],
    fieldName: "SSEM",
};
export const VamParameters: MessageFormat<any> = {
    value: {
        basic_container: { ...BasicContainer, optional: false },
        vru_high_frequency_container: {
            ...VruHighFrequencyContainer,
            optional: true,
        },
        vru_low_frequency_container: {
            ...VruLowFrequencyContainer,
            optional: true,
        },
        vru_cluster_information_container: {
            ...VruClusterInformationContainer,
            optional: true,
        },
        vru_cluster_operation_container: {
            ...VruClusterOperationContainer,
            optional: true,
        },
        vru_motion_prediction_container: {
            ...VruMotionPredictionContainer,
            optional: true,
        },
    },
    ty: ["object", null],
    fieldName: "Vam Parameters",
};
export const VruAwareness: MessageFormat<any> = {
    value: {
        generation_delta_time: { ...GenerationDeltaTime, optional: false },
        vam_parameters: { ...VamParameters, optional: false },
    },
    ty: ["object", null],
    fieldName: "Vru Awareness",
};
export const VAM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        vam: { ...VruAwareness, optional: false },
    },
    ty: ["object", null],
    fieldName: "VAM",
};
export const SPATEM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        spat: { ...SPAT, optional: false },
    },
    ty: ["object", null],
    fieldName: "SPATEM",
};
export const IVIM: MessageFormat<any> = {
    value: {
        header: { ...ItsPduHeader, optional: false },
        ivi: { ...IviStructure, optional: false },
    },
    ty: ["object", null],
    fieldName: "IVIM",
};
