import { CRoadsBIMessage } from "../../../../services/AmqpClient";
import { FilterCondition, LogicalOperations, RowData } from "../../../../types/MessageType";
import { FilterOption } from "../../../../types/MessageType";

export function rowFromMessage(message: CRoadsBIMessage, msgId: number, creationTime: Date | undefined): RowData {
    return {
        ...message,
        msgId,
        length: message.payload.length,
        timestamp: (creationTime ?? new Date(Date.now())).toISOString(),
        pinned: false,
    };
}

export const handleFilter = (row: RowData, filterConditions: FilterCondition[]) => {
    if (filterConditions.length === 0) {
        return true;
    }

    let result = true;

    filterConditions.forEach((condition, index) => {
        const columnValue = row[condition.column as keyof RowData];
        const isString = typeof columnValue === "string";

        let conditionResult = false;

        switch (condition.option) {
            case FilterOption.EQUALS:
                conditionResult = columnValue === condition.text;
                break;

            case FilterOption.CONTAINS:
                conditionResult = isString && columnValue.toLowerCase().includes(condition.text.toLowerCase());
                break;

            case FilterOption.STARTS_WITH:
                conditionResult = isString && columnValue.toLowerCase().startsWith(condition.text.toLowerCase());
                break;

            case FilterOption.ENDS_WITH:
                conditionResult = isString && columnValue.toLowerCase().endsWith(condition.text.toLowerCase());
                break;

            case FilterOption.IS_EMPTY:
                conditionResult = !columnValue;
                break;

            case FilterOption.IS_NOT_EMPTY:
                conditionResult = !!columnValue;
                break;

            default:
                conditionResult = true;
                break;
        }

        if (index === 0) {
            result = conditionResult;
        } else {
            result = condition.logicalOperator === LogicalOperations.AND ?
                result && conditionResult : result || conditionResult;
        }
    });

    return result;
};