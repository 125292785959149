/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, Autocomplete, TextField } from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { useEffect, useId, useState } from "react";
import AnyTypeInput from "../AnyTypeInput/AnyTypeInput";
import OptionalCheckbox from "../OptionalCheckbox/OptionalCheckbox";

type ChoiceInputProps = {
  choiceFormat: MessageFormat<object>;
  onChange: (instance: object | undefined) => void;
};

const ChoiceInput = ({
  choiceFormat,
  onChange,
}: ChoiceInputProps) => {
  const id = useId();
  const [isOptionalAndFilled, setIsOptionalAndFilled] = useState(
    choiceFormat.optional !== true
  );
  const [objectState, setObjectState] = useState<object | undefined>();
  const [variant, setVariant] = useState<string>(
    Object.keys(choiceFormat.value)[0]
  );

  useEffect(() => {
    if (objectState !== undefined) {
      onChange(isOptionalAndFilled ? objectState : undefined);
    }
  }, [isOptionalAndFilled, objectState, onChange]);

  const updateChoice = (key: string) => (val: any) => {
    if (
      objectState === undefined ||
      objectState[key as keyof object] === undefined ||
      objectState[key as keyof object] !== val
    ) {
      setObjectState({ [key]: val });
    }
  };

  return (
    <>
      <Typography>{choiceFormat.fieldName}</Typography>
      <OptionalCheckbox value={isOptionalAndFilled} onUpdate={setIsOptionalAndFilled} format={choiceFormat} />
      {(choiceFormat.optional !== true || isOptionalAndFilled) && (
        <>
          <Autocomplete
            size="small"
            disableClearable
            fullWidth
            id={`${choiceFormat.fieldName}-${id}`}
            value={variant}
            onChange={(_, newVal) => setVariant(newVal)}
            options={Object.keys(choiceFormat.value)}
            componentsProps={{ popper: { style: { width: "auto" } } }}
            renderInput={params => (
              <TextField {...params} InputProps={{ ...params.InputProps }} />
            )}
          />
          <AnyTypeInput
            type={
              (
                choiceFormat.value[
                variant as keyof object
                ] as MessageFormat<any>
              ).ty
            }
            value={
              (
                choiceFormat.value[
                variant as keyof object
                ] as MessageFormat<any>
              )
            }
            onChange={updateChoice(variant)}
          />
        </>
      )}
    </>
  );
};

export default ChoiceInput;
