import React, { useState } from "react";
import { Button, Input, Box, Typography, InputLabel, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteBtnIcon, fileInput, fileInputLabel, uploadedFileBox } from "./fileInputStyles";
import { Buffer } from "buffer";

type FileInputProps = {
    setData: (data: string) => void;
};

const FileInput = ({ setData }: FileInputProps) => {
    const [file, setFile] = useState<File | null>(null);
    const [inputKey, setInputKey] = useState(0);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];

        if (selectedFile) {
            setFile(selectedFile);

            const reader = new FileReader();

            reader.onload = (e) => {
                const data = Buffer.from(e.target?.result as ArrayBuffer);
                setData(data.toString("hex"));
            };

            reader.readAsArrayBuffer(selectedFile);
            setInputKey(inputKey + 1);
        }
    };

    return (
        <Box sx={{ textAlign: "center" }}>
            <Input
                type="file"
                id="raw-file"
                onChange={handleFileUpload}
                sx={fileInput}
                key={inputKey}
            />
            <InputLabel htmlFor="raw-file" sx={fileInputLabel}>
                <Button variant="contained" component="span">Upload File</Button>
            </InputLabel>
            {file && (
                <Box sx={uploadedFileBox}>
                    <Typography>
                        <b>Selected File:</b> {file.name}
                    </Typography>
                    <IconButton sx={deleteBtnIcon} onClick={() => setFile(null)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default FileInput;
