/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    Button,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AnyTypeInput from "../AnyTypeInput/AnyTypeInput";
import OptionalCheckbox from "../OptionalCheckbox/OptionalCheckbox";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
    arrayElementTable,
    arrayElementTableHeader,
    arrayElementTableWrapper,
    itemInputContainer,
} from "./arrayInputStyles";
import { uploadJsonFile } from "./utils";

const DEFAULT_VALUE = "";

type ArrayInputProps = {
    arrayFormat: MessageFormat<object>;
    onChange: (instance: any[] | undefined) => void;
};

const ArrayInput = ({ arrayFormat, onChange }: ArrayInputProps) => {
    const [isOptionalAndFilled, setIsOptionalAndFilled] = useState(
        arrayFormat.optional !== true
    );
    const [error, setError] = useState<string | undefined>(
        arrayFormat.value === undefined ? DEFAULT_VALUE : undefined
    );
    const [arrayState, setArrayState] = useState<any[] | undefined>(undefined);
    const [editElement, setEditElement] = useState<any>();
    const [uploadErr, setUploadErr] = useState(false);

    const validate = () => {
        const newArrayState = [...(arrayState ?? []), editElement];

        if (
            newArrayState.length >= (arrayFormat.constraints?.min ?? 0) &&
            newArrayState.length <= (arrayFormat.constraints?.max ?? Number.MAX_VALUE)
        ) {
            setArrayState(newArrayState);
            onChange(isOptionalAndFilled ? newArrayState : undefined);
            setError(undefined);
        } else {
            const min = arrayFormat.constraints?.min !== undefined ? ` Minimum value ${arrayFormat.constraints!.min}.` : "";
            const max = arrayFormat.constraints?.max !== undefined ? ` Maximum value ${arrayFormat.constraints!.max}.` : "";
            setError(`Invalid value!${min}${max}`);
        }
    };

    useEffect(() => {
        if (arrayState !== undefined) {
            onChange(isOptionalAndFilled ? arrayState : undefined);
        }
    }, [isOptionalAndFilled, arrayState, onChange]);

    const addElement = () => {
        if (editElement !== undefined) {
            validate();
        }
    };

    return (
        <>
            <Typography>{arrayFormat.fieldName}</Typography>
            <OptionalCheckbox
                value={isOptionalAndFilled}
                onUpdate={setIsOptionalAndFilled}
                format={arrayFormat}
            />
            {error && <Typography color="error">{error}</Typography>}
            {uploadErr && <Typography color="error">Something went wrong. Please make sure to upload a valid JSON array file.</Typography>}
            {(arrayFormat.optional !== true || isOptionalAndFilled) && (
                <List>
                    <ListItem>
                        <TableContainer component={Paper}>
                            <Box sx={arrayElementTableWrapper}>
                                <Typography sx={arrayElementTableHeader}>
                                    Elements of {arrayFormat.fieldName}
                                </Typography>
                                <Button
                                    sx={{ textTransform: "none" }}
                                    startIcon={<UploadFileIcon />}
                                    onClick={() => uploadJsonFile(arrayState, setArrayState, setUploadErr)}
                                >
                                    <Typography>Import {arrayFormat.fieldName}</Typography>
                                </Button>
                            </Box>

                            <Table
                                sx={arrayElementTable}
                                size="small"
                                aria-label={`${arrayFormat.fieldName} Table`}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Index</TableCell>
                                        <TableCell align="right">
                                            JSON
                                        </TableCell>
                                        <TableCell align="right">
                                            Delete
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {arrayState?.map((el, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {i.toString()}
                                            </TableCell>
                                            <TableCell align="right">
                                                {JSON.stringify(el)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    size="small"
                                                    startIcon={<DeleteIcon />}
                                                    onClick={() => {
                                                        setArrayState([
                                                            ...arrayState.slice(
                                                                0,
                                                                i
                                                            ),
                                                            ...arrayState.slice(
                                                                i + 1
                                                            ),
                                                        ]);
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={addElement}
                            >{`Add ${arrayFormat.fieldName} Element`}</Button>
                        </TableContainer>
                    </ListItem>
                    <ListItem>
                        <div style={itemInputContainer}>
                            <AnyTypeInput
                                type={arrayFormat.ty[1]!}
                                value={arrayFormat.value}
                                onChange={setEditElement}
                            />
                        </div>
                    </ListItem>
                </List>
            )}
        </>
    );
};

export default ArrayInput;
