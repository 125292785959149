import { Box, TextField } from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { ChangeEvent, useId, useState } from "react";
import { inputField } from "./stringInputStyles";
import { flexAlignCenter } from "../ObjectInput/objectInputStyles";
import OptionalCheckbox from "../OptionalCheckbox/OptionalCheckbox";

const DEFAULT_VALUE = "";

type StringInputProps = {
  stringFormat: MessageFormat<string | undefined>;
  onChange: (instance: string | undefined) => void;
  stringType: "string" | "hexstring" | "bitstring";
  multiline?: boolean;
  value?: string;
};

const StringInput = ({
  stringFormat,
  onChange,
  stringType,
  multiline,
  value,
}: StringInputProps) => {
  const id = useId();
  const [isOptionalAndFilled, setIsOptionalAndFilled] = useState(
    stringFormat.optional !== true
  );
  const [error, setError] = useState<string | undefined>(
    stringFormat.value === undefined ? DEFAULT_VALUE : undefined
  );

  const validate = (
    input: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const update = (value: string) => {
      if (
        value.length >= (stringFormat.constraints?.min ?? Number.MIN_VALUE) &&
        value.length <= (stringFormat.constraints?.max ?? Number.MAX_VALUE)
      ) {
        setError(undefined);
        onChange(isOptionalAndFilled ? value : undefined);
      } else {
        const min =
          stringFormat.constraints?.min !== undefined
            ? ` Minimum length ${stringFormat.constraints!.min}.`
            : "";
        const max =
          stringFormat.constraints?.max !== undefined
            ? ` Maximum length ${stringFormat.constraints!.max}.`
            : "";
        setError(`Invalid value!${min}${max}`);
      }
    };
    if (stringType === "bitstring") {
      binaryValidator(input, update);
    } else if (stringType === "hexstring") {
      hexValidator(input, update);
    } else {
      update(input.target.value);
    }
  };

  const hexValidator = (
    input: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    update: (value: string) => void
  ) => {
    if (/^[0-9a-fA-F]*$/.test(input.target.value)) {
      update(input.target.value);
    } else {
      update(input.target.value);
      setError(
        "Invalid value! Only hex characters '0123456789abcdefABCDEF' allowed."
      );
    }
  };

  const binaryValidator = (
    input: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    update: (value: string) => void
  ) => {
    if (/^[01]*$/.test(input.target.value)) {
      update(input.target.value);
    } else {
      setError("Invalid value! Only binary characters '0' and '1' allowed.");
    }
  };

  return (
    <Box sx={flexAlignCenter}>
      <OptionalCheckbox value={isOptionalAndFilled} onUpdate={setIsOptionalAndFilled} format={stringFormat} />
      {(stringFormat.optional !== true || isOptionalAndFilled) &&  (<TextField
        error={!!error}
        helperText={error}
        id={`${stringFormat.fieldName}-${id}`}
        label={stringFormat.fieldName}
        value={value}
        onChange={validate}
        multiline={!!multiline}
        rows={multiline ? 5 : 0}
        sx={multiline ? inputField : {}}
      />)}
    </Box>
  );
};

export default StringInput;
