/* eslint @typescript-eslint/no-explicit-any: 0 */
import { StationType } from "./ItsMessageFormats";
import { MessageFormat } from "./MessageForm/MessageForm";

const SourcePort = {
    value: 0,
    ty: ["number", null],
    fieldName: "Source Port",
    constraints: { min: 0, max: 65535 },
};

const DestinationPort = {
    value: 0,
    ty: ["number", null],
    fieldName: "Destination Port",
    constraints: { min: 0, max: 65535 },
};

const DestinationPortInfo = {
    value: 0,
    ty: ["number", null],
    fieldName: "Destination Port Info",
    constraints: { min: 0, max: 65535 },
};

const BTPAHeader = {
    value: {
        destination_port: DestinationPort,
        source_port: SourcePort,
    },
    ty: ["object", null],
    fieldName: "BTP A Header",
};

const BTPBHeader = {
    value: {
        destination_port: DestinationPort,
        destination_port_info: DestinationPortInfo,
    },
    ty: ["object", null],
    fieldName: "BTP B Header",
};

export const BasicTransportHeader: MessageFormat<any> = {
    value: {
        btp: {
            value: {
                BTPAHeader,
                BTPBHeader,
            },
            fieldName: "Basic Transport Header",
            ty: ["choice", null],
        },
    },
    fieldName: "",
    ty: ["object", null],
};

const Uint8 = {
    value: 0,
    fieldName: "Byte",
    ty: ["number", null],
    constraints: { min: 0, max: 255 },
};

const Timestamp = {
    value: 0,
    fieldName: "Timestamp",
    ty: ["number", null],
    constraints: { min: 0, max: 4294967295 },
};

const LifeTime = {
    value: 0,
    fieldName: "LifeTime",
    ty: ["number", null],
    constraints: { min: 0, max: 256 },
};

const Latitude = {
    value: 0,
    fieldName: "Latitude",
    ty: ["number", null],
    constraints: { min: -900000000, max: 900000000 },
};

const Longitude = {
    value: 0,
    fieldName: "Longitude",
    ty: ["number", null],
    constraints: { min: -1800000000, max: 1800000000 },
};

const Address = {
    value: {
        manually_configured: {
            value: false,
            fieldName: "Manually Configured",
            ty: ["boolean", null],
        },
        station_type: StationType,
        reserved: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 10, max: 10 },
        },
        address: {
            value: Uint8,
            fieldName: "Address",
            ty: ["array", ["number", null]],
            constraints: { min: 6, max: 6 },
        },
    },
    ty: ["object", null],
    fieldName: "Address",
};

const Version = {
    value: 0,
    fieldName: "Version",
    ty: ["number", null],
    constraints: { min: 0, max: 16 },
};

const NextAfterBasic = {
    value: 0,
    fieldName: "Next Header",
    ty: ["number", null],
    constraints: { min: 1, max: 1 },
};

const RemainingHopLimit = {
    value: 0,
    fieldName: "Remaining Hop Limit",
    ty: ["number", null],
    constraints: { min: 0, max: 256 },
};

const BasicHeader = {
    value: {
        version: Version,
        next_header: NextAfterBasic,
        reserved: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 8, max: 8 },
        },
        lifetime: LifeTime,
        remaining_hop_limit: RemainingHopLimit,
    },
    fieldName: "Basic Header",
    ty: ["object", null],
};

const TrafficClass = {
    value: {
        store_carry_forward: {
            value: false,
            fieldName: "Store Carry Forward",
            ty: ["boolean", null],
        },
        channel_offload: {
            value: false,
            fieldName: "Channel Offload",
            ty: ["boolean", null],
        },
        traffic_class_id: {
            value: 0,
            fieldName: "Traffic Class ID",
            ty: ["number", null],
            constraints: { min: 0, max: 256 },
        },
    },
    fieldName: "Traffic Class",
    ty: ["object", null],
};

const NextAfterCommon = {
    value: 0,
    fieldName: "Next Header",
    ty: ["number", null],
    constraints: { min: 1, max: 2 },
};

const ShortPositionVector = {
    value: {
        gn_address: Address,
        timestamp: Timestamp,
        latitude: Latitude,
        longitude: Longitude,
    },
    fieldName: "Short Position Vector",
    ty: ["object", null],
};

const SequenceNumber = {
    value: 0,
    fieldName: "Sequence Number",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};

const LongPositionVector = {
    value: {
        gn_address: Address,
        timestamp: Timestamp,
        latitude: Latitude,
        longitude: Longitude,
        position_accuracy: {
            value: false,
            fieldName: "Position Accuracy",
            ty: ["boolean", null],
        },
        speed: {
            value: 0,
            fieldName: "Speed",
            ty: ["number", null],
            constraints: { min: -16382, max: 16383 },
        },
        heading: {
            value: 0,
            fieldName: "Heading",
            ty: ["number", null],
            constraints: { min: 0, max: 65535 },
        },
    },
    fieldName: "Long Position Vector",
    ty: ["object", null],
};

const GeoUnicast = {
    value: {
        sequence_number: SequenceNumber,
        reserved: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 16, max: 16 },
        },
        source_position_vector: LongPositionVector,
        destination_position_vector: ShortPositionVector,
    },
    fieldName: "GeoUnicast",
    ty: ["object", null],
};

const TopologicallyScopedBroadcast = {
    value: {
        sequence_number: SequenceNumber,
        reserved: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 16, max: 16 },
        },
        source_position_vector: LongPositionVector,
    },
    fieldName: "Topologically Scoped Broadcast",
    ty: ["object", null],
};

const SingleHopBroadcast = {
    value: {
        source_position_vector: LongPositionVector,
        media_dependent_data: {
            value: Uint8,
            fieldName: "Media-Dependent Data",
            ty: ["array", ["number", null]],
            constraints: { min: 4, max: 4 },
        },
    },
    fieldName: "Single Hop Broadcast",
    ty: ["object", null],
};

const Distance = {
    value: 0,
    fieldName: "Distance",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};

const Angle = {
    value: 0,
    fieldName: "Angle",
    ty: ["number", null],
    constraints: { min: 0, max: 65535 },
};

const GeoAnycast = {
    value: {
        sequence_number: SequenceNumber,
        reserved_1: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 16, max: 16 },
        },
        source_position_vector: LongPositionVector,
        geo_area_position_latitude: Latitude,
        geo_area_position_longitude: Longitude,
        distance_a: Distance,
        distance_b: Distance,
        angle: Angle,
        reserved_2: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 16, max: 16 },
        },
    },
    fieldName: "GeoAnycast",
    ty: ["object", null],
};

const GeoBroadcast = GeoAnycast;

const Beacon = {
    value: {
        source_position_vector: LongPositionVector,
    },
    fieldName: "Beacon",
    ty: ["object", null],
};

const LSRequest = {
    value: {
        sequence_number: SequenceNumber,
        reserved: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 16, max: 16 },
        },
        source_position_vector: LongPositionVector,
        request_gn_address: Address,
    },
    fieldName: "Location Service Request",
    ty: ["object", null],
};

const LSReply = {
    value: {
        sequence_number: SequenceNumber,
        reserved: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 16, max: 16 },
        },
        source_position_vector: LongPositionVector,
        destination_position_vector: ShortPositionVector,
    },
    fieldName: "Location Service Reply",
    ty: ["object", null],
};

const ExtendedHeader = {
    value: {
        GUC: GeoUnicast,
        TSB: TopologicallyScopedBroadcast,
        SHB: SingleHopBroadcast,
        GBC: GeoBroadcast,
        GAC: GeoAnycast,
        Beacon,
        LSRequest,
        LSReply,
    },
    fieldName: "Extended Header",
    ty: ["choice", null],
    optional: true,
};

const CommonHeader = {
    value: {
        next_header: NextAfterCommon,
        reserved_1: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 4, max: 4 },
        },
        traffic_class: TrafficClass,
        flags: {
            value: "",
            fieldName: "Flags",
            ty: ["bitstring", null],
            constraints: { min: 8, max: 8 },
        },
        maximum_hop_limit: {
            value: 0,
            fieldName: "Maximum Hop Limit",
            ty: ["number", null],
            constraints: { min: 0, max: 256 },
        },
        reserved_2: {
            value: "",
            fieldName: "Reserved",
            ty: ["bitstring", null],
            constraints: { min: 8, max: 8 },
        },
    },
    fieldName: "Common Header",
    ty: ["object", null],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Unsecured = {
    value: {
        basic: BasicHeader,
        common: CommonHeader,
        extended: ExtendedHeader,
    },
    fieldName: "Unsecured Geonetworking",
    ty: ["object", null],
};

export const GeonetworkingHeader: MessageFormat<object> = {
    value: {
        basic: BasicHeader,
        common: CommonHeader,
        extended: ExtendedHeader,
    },
    fieldName: "Unsecured Geonetworking",
    ty: ["object", null],
};
