import { SxProps, Theme } from "@mui/material";

export const wrapperBox: SxProps = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: "60%",
    gap: 2,
    m: "0 auto 8px auto",
};

export const selectInput: SxProps = {
    mx: 1,
    minWidth: 150,
};

export const filterOperation: SxProps<Theme> = {
    color: (theme: Theme) => theme.palette.text.primary
};

export const chipsBox: SxProps = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 1,
    maxWidth: "60%",
    m: "16px auto",
};

export const chipItem: SxProps<Theme> = {
    backgroundColor: (theme: Theme) => theme.palette.primary.main,
    color: (theme: Theme) => theme.palette.paper,
    fontSize: 14,

    "& .MuiChip-deleteIcon": {
        color: (theme: Theme) => theme.palette.paper,
    },

    "& .MuiChip-deleteIcon:hover": {
        color: "#ccc"
    }
};