import { CRoadsBIMessage } from "../services/AmqpClient";

export const cRoadsDescriptions: Record<keyof CRoadsBIMessage, string> & {
    [key: string]: string;
} = {
    publisherId: "Unique ID of the publisher. It is Linked to the country where the provider wants to register. It could be in one country or several.",
    publicationId: "publisherId defined in table 1. Each dataset/publication identifier needs to be unique for the given publisher. When using the II, the publicationId shall uniquely identify a single capability entry.",
    originatingCountry: "Country code where the C-ITS message is created",
    protocolVersion: "Represent the version of standard used to create the message",
    serviceType: "Acronym of C-ITS use case(s) defined in latest version of Common C-ITS Service and Use Case Definitions",
    baselineVersion: "The baseline version indicates which release of the C-Roads specifications were used to create the CITS message",
    messageType: "CITS message type",
    latitude: "Latitude of the event published; for DENM (eventPosition) and for IVI and SPATEM/MAPEM/SSEM/SREM (referencePosition)",
    longitude: "Longitude of the event published; for DENM (eventPosition) and for IVI and SPATEM/MAPEM/SSEM/SREM (referencePosition)",
    quadTree: "Relevant spatial index location of the C-ITS message",
    causeCode: "DENM only: CauseCode from ETSI_EN_302_637-3",
    subCauseCode: "DENM only: subCauseCode from ETSI_EN_302_637-3",
    iviType: "IVI only: iviType",
    pictogramCategoryCode: "IVI only: The ISO 14823:2017 [10] pictogramCategoryCode is a combined numeral value (nature and serialNumber) referring to a specific sign of the ISO 14823:2017 [10] sign catalogue, e.g. 557 = Maximum speed limit",
    iviContainer: "IVI only: All valid IviContainer types out of the ISO 19321:2015 [11] standard that should be present in the target IVIM after applying filtering",
    name: "SPATEM/MAPEM only: Typically human readable and recognizable by road authority.",
    id: "SPATEM/MAPEM/SREM/SSEM only: (IntersectionReferenceID) The combination of region and id (i.e region-id) must be unique within a country. Reference: C-Roads C-ITS Message Profiles [2]",
    stationType: "CAM only: Station ID as defined in ETSI_TS_102_894-2",
    vehicleRole: "CAM only: Vehicle Role as defined in ETSI_TS_102_894-2",
    _id: "Internal rolling ID for message sorting",
    payload: "Binary payload of the AMQP message",
};