import * as rhea from "rhea/dist/rhea-umd";
import { Buffer } from "buffer/";

export type MessageCallback = (message: CRoadsBIMessage) => void;

export interface CRoadsApplicationProperties {
    // Unique ID of the publisher.
    // It is Linked to the country where the provider wants to register.
    // It could be in one country or several.
    publisherId: string;
    // publisherId defined in table 1.
    // Each dataset/publication identifier needs to be unique for the given publisher.
    // When using the II, the publicationId shall uniquely identify a single capability entry.
    publicationId?: string;
    // Country code where the C-ITS message is created
    originatingCountry: string;
    // Represent the version of standard used to create the message
    protocolVersion: string;
    // Acronym of C-ITS use case(s) defined in latest version
    // of Common C-ITS Service and Use Case Definitions
    serviceType?: string[];
    // The baseline version indicates which release of the
    // C-Roads specifications were used to create the CITS message
    baselineVersion?: string;
    // CITS message type
    messageType: string;
    // Latitude of the event published; for DENM (eventPosition)
    // and for IVI and SPATEM/MAPEM/SSEM/SREM (referencePosition)
    latitude?: number;
    // Longitude of the event published; for DENM (eventPosition)
    // and for IVI and SPATEM/MAPEM/SSEM/SREM (referencePosition)
    longitude?: number;
    // Relevant spatial index location of the C-ITS message
    quadTree?: string[];
    // DENM only: CauseCode from ETSI_EN_302_637-3
    causeCode?: number;
    // DENM only: subCauseCode from ETSI_EN_302_637-3
    subCauseCode?: number;
    // IVI only: iviType
    iviType?: string[];
    // IVI only: The ISO 14823:2017 [10] pictogramCategoryCode is a
    // combined numeral value (nature and serialNumber) referring to
    // a specific sign of the ISO 14823:2017 [10] sign catalogue, e.g. 557 = Maximum speed limit
    pictogramCategoryCode?: string[];
    // IVI only: All valid IviContainer types out of the ISO 19321:2015 [11]
    // standard that should be present in the target IVIM after applying filtering
    iviContainer?: string[];
    // SPATEM/MAPEM only: Typically human readable and recognizable by road authority.
    name?: string;
    // SPATEM/MAPEM/SREM/SSEM only: (IntersectionReferenceID) The combination of
    // region and id (i.e region-id) must be unique within a country.
    // Reference: C-Roads C-ITS Message Profiles [2]
    id?: string[];
    // CAM only: Station ID as defined in ETSI_TS_102_894-2
    stationType?: number;
    // CAM only: Vehicle Role as defined in ETSI_TS_102_894-2
    vehicleRole?: number;
}

export interface CRoadsBIMessage extends CRoadsApplicationProperties {
    // Internal rolling ID for message sorting
    _id: number;
    // Binary payload of the AMQP message
    payload: Uint8Array;
}

export class AmqpClient {
    private sender: rhea.Sender;
    private connection: rhea.Connection;
    private listeners: Map<number, MessageCallback> = new Map();
    private lastListenerId = 0;
    private messageCounter = 0;

    constructor(connection: rhea.Connection, sender: rhea.Sender) {
        this.sender = sender;
        this.connection = connection;
        this.connection.attach_receiver("croads");
        rhea.on("message", (context) => {
            const message = context.message;
            if (message && message.body) {
                const payload = message.body.content ?? message.body;
                this.messageCounter++;
                const biMessage = toBIMessage(
                    message,
                    payload,
                    this.messageCounter
                );
                if (biMessage === undefined) {
                    return;
                }
                this.listeners.forEach(listener => listener(biMessage));
            }
        });
    }

    addListener = (callback: MessageCallback): number => {
        this.lastListenerId++;
        this.listeners.set(this.lastListenerId, callback);
        return this.lastListenerId;
    };

    removeListener = (id: number): boolean => {
        return this.listeners.delete(id);
    };

    send = (binary: Buffer): Promise<void> => {
        const delivery = new Promise<void>((resolve, reject) => {
            rhea.on("accepted", () => resolve());
            rhea.on("sender_error", error => reject(error));
        });
        if (this.sender === undefined) {
            return Promise.reject("Sender is uninitialized!");
        } else {
            this.sender.send({
                body: binary,
                application_properties: DEFAULT_APPLICATION_PROPERTIES,
            });
        }
        return delivery;
    };

    close = (): void => {
        this.sender.close();
        this.connection.close();
    };
}

export const getAmqpClient = async (
    username: string,
    password: string
): Promise<AmqpClient> => {
    const ws = rhea.websocket_connect(WebSocket);
    rhea.options.username = username;
    rhea.options.password = password;
    const sender = new Promise<rhea.Sender>((resolve, reject) => {
        rhea.on("sendable", context =>
            context.sender
                ? resolve(context.sender)
                : reject(
                      new Error(
                          "Sender could not be initialized for unknown reasons." +
                              " Please contact the broker administrator."
                      )
                  )
        );
        rhea.on("sender_error", error => reject(error));
        rhea.on("connection_error", error => reject(error));
        rhea.on("session_error", error => reject(error));
        rhea.on("protocol_error", error => reject(error));
        rhea.on("error", error => reject(error));
        rhea.on("disconnected", () =>
            reject(
                new Error(
                    "Cannot connect to AMQP broker for unknown reasons. Please contact the broker administrator."
                )
            )
        );
    });

  const connection = rhea.connect({
    connection_details: ws(
      "wss://amqp.croads-broker.at:5672"
    ),
    reconnect: true,
  });
  connection.open_sender("croads");
  return sender.then(sender => new AmqpClient(connection, sender));
};

const toBIMessage = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    amqpMessage: any,
    payload: Uint8Array,
    internalId: number
): CRoadsBIMessage | undefined => {
    const applicationProps = amqpMessage?.application_properties;
    if (applicationProps !== undefined) {
        const {
            publisherId,
            publicationId,
            originatingCountry,
            protocolVersion,
            serviceType,
            baselineVersion,
            messageType,
            latitude,
            longitude,
            quadTree,
            causeCode,
            subCauseCode,
            iviType,
            pictogramCategoryCode,
            iviContainer,
            name,
            id,
            stationType,
            vehicleRole,
        } = applicationProps;
        return {
            publisherId,
            publicationId,
            originatingCountry,
            protocolVersion,
            serviceType: serviceType
                ?.split(",")
                .filter((s: string) => s.length > 0)
                .join(", "),
            baselineVersion,
            messageType,
            latitude,
            longitude,
            quadTree: quadTree
                ?.split(",")
                .filter((s: string) => s.length > 0)
                .join(", "),
            causeCode,
            subCauseCode,
            iviType: iviType
                ?.split(",")
                .filter((s: string) => s.length > 0)
                .join(", "),
            pictogramCategoryCode: pictogramCategoryCode
                ?.split(",")
                .filter((s: string) => s.length > 0)
                .join(", "),
            iviContainer: iviContainer
                ?.split(",")
                .filter((s: string) => s.length > 0)
                .join(", "),
            name,
            id: id
                ?.split(",")
                .filter((s: string) => s.length > 0)
                .join(", "),
            stationType,
            vehicleRole,
            _id: internalId,
            payload,
        };
    }
};

const DEFAULT_APPLICATION_PROPERTIES: CRoadsApplicationProperties = {
    publisherId: "AustriaTech",
    originatingCountry: "AT",
    protocolVersion: "test",
    messageType: "test",
};
