import { Box, Chip, IconButton, useTheme } from "@mui/material";
import React from "react";
import { chipItem, chipsBox } from "./filterOptionStyles";
import { FilterCondition } from "../../../../types/MessageType";
import ClearIcon from "@mui/icons-material/Clear";
import { TABLE_COLUMNS } from "../constants/tableConstants";

type ChipsProps = {
    filterConditions: FilterCondition[];
    onRemoveFilter: (index: number) => void;
    onClear: () => void;
};

const Chips = ({ filterConditions, onRemoveFilter, onClear }: ChipsProps) => {
    const theme = useTheme();

    return (
        <Box sx={chipsBox}>
            {filterConditions.map((condition, index) => (
                <React.Fragment key={index}>
                    {index > 0 && (
                        <Chip
                            sx={chipItem}
                            label={condition.logicalOperator}
                            onDelete={() => onRemoveFilter(index)}
                        />
                    )}
                    <Chip
                        sx={chipItem}
                        label={
                            `
                                ${TABLE_COLUMNS.find(column => column.key === condition.column)?.label} 
                                ${condition.option.toLowerCase()} 
                                ${condition.text}
                            `
                        }
                        onDelete={() => onRemoveFilter(index)}
                    />

                </React.Fragment>
            ))}
            {filterConditions.length > 1 &&
                <IconButton onClick={onClear} size="small">
                    <ClearIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
            }
        </Box>
    );
};

export default Chips;