import { TableColumn } from "../../../../types/MessageType";

export const MAX_ENTRIES = 500;

export const COLUMN_INPUT_LABEL = "Select Column";
export const FILTER_OPTIONS_LABEL = "Filter Option";

export const TABLE_COLUMNS: TableColumn[] = [
    {
        label: "Publisher ID",
        key: "publisherId",
        isNumeric: false,
    },
    {
        label: "Publication ID",
        key: "publicationId",
        isNumeric: false,
    },
    {
        label: "Originating Country",
        key: "originatingCountry",
        isNumeric: false,
    },
    {
        label: "Protocol Version",
        key: "protocolVersion",
        isNumeric: false,
    },
    {
        label: "Service Type",
        key: "serviceType",
        isNumeric: false,
    },
    {
        label: "Baseline Version",
        key: "baselineVersion",
        isNumeric: false,
    },
    {
        label: "Message Type",
        key: "messageType",
        isNumeric: false,
    },
    {
        label: "Latitude",
        key: "latitude",
        isNumeric: true,
    },
    {
        label: "Longitude",
        key: "longitude",
        isNumeric: true,
    },
    {
        label: "QuadTree",
        key: "quadTree",
        isNumeric: false,
    },
    {
        label: "Cause Code",
        key: "causeCode",
        isNumeric: true,
    },
    {
        label: "Sub Cause Code",
        key: "subCauseCode",
        isNumeric: true,
    },
    {
        label: "IVI Type",
        key: "iviType",
        isNumeric: false,
    },
    {
        label: "Pictogram Category Code",
        key: "pictogramCategoryCode",
        isNumeric: false,
    },
    {
        label: "IVI Container",
        key: "iviContainer",
        isNumeric: false,
    },
    {
        label: "Name",
        key: "name",
        isNumeric: false,
    },
    {
        label: "ID",
        key: "id",
        isNumeric: false,
    },
    {
        label: "Station Type",
        key: "stationType",
        isNumeric: true,
    },
    {
        label: "Vehicle Role",
        key: "vehicleRole",
        isNumeric: true,
    },
];