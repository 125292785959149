import React from "react";
import { ThemeProvider } from "@mui/material";
import Client from "./pages/Client/Client";
import initEtsiWebLib from "@consider-it/etsi-web";
import { croadsTheme } from "./themes/croadsTheme";

initEtsiWebLib();

const App: React.FC = () => {
    return (
        <ThemeProvider theme={croadsTheme}>
            <Client />
        </ThemeProvider>
    );
};

export default App;
