import { SxProps } from "@mui/system";

export const boxWrapper: SxProps = {
    position: "relative",
};

export const fabSend: SxProps = {
    position: "fixed",
    bottom: 48,
    right: 48,
};

export const fabSendIcon: SxProps = {
    mr: 1
};
