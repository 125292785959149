import { Box, TextField } from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { ChangeEvent, useEffect, useId, useState } from "react";
import { flexAlignCenter } from "../ObjectInput/objectInputStyles";
import OptionalCheckbox from "../OptionalCheckbox/OptionalCheckbox";

const DEFAULT_VALUE = "";

type NumberInputProps = {
    numberFormat: MessageFormat<number | undefined>;
    onChange: (instance: number | undefined) => void;
};

const NumberInput = ({ numberFormat, onChange }: NumberInputProps) => {
    const id = useId();
    const [isOptionalAndFilled, setIsOptionalAndFilled] = useState(
        numberFormat.optional !== true
    );
    const [error, setError] = useState<string | undefined>(
        numberFormat.value === undefined ? DEFAULT_VALUE : undefined
    );

    const validate = (
        input: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (input.target.value === DEFAULT_VALUE) {
            return;
        }
        const parsed = parseInt(input.target.value);
        if (
            !isNaN(parsed) &&
            parsed >= (numberFormat.constraints?.min ?? Number.MIN_VALUE) &&
            parsed <= (numberFormat.constraints?.max ?? Number.MAX_VALUE)
        ) {
            setError(undefined);
            onChange(isOptionalAndFilled ? parsed : numberFormat.defaultValue);
        } else {
            const min =
                numberFormat.constraints?.min !== undefined
                    ? ` Minimum value ${numberFormat.constraints!.min}.`
                    : "";
            const max =
                numberFormat.constraints?.max !== undefined
                    ? ` Maximum value ${numberFormat.constraints!.max}.`
                    : "";
            setError(`Invalid value!${min}${max}`);
        }
    };

    useEffect(() => {
        if (!isOptionalAndFilled) {
            onChange(numberFormat.defaultValue);
        }
    }, [isOptionalAndFilled]);

    useEffect(() => {
        if (numberFormat.defaultValue !== undefined) {
            onChange(numberFormat.defaultValue);
        }
    });

    return (
        <Box sx={flexAlignCenter}>
            <OptionalCheckbox
                value={isOptionalAndFilled}
                onUpdate={setIsOptionalAndFilled}
                format={numberFormat}
            />
            {(numberFormat.optional !== true || isOptionalAndFilled) && (
                <TextField
                    error={!!error}
                    helperText={error}
                    disabled={
                        numberFormat.optional === true && !isOptionalAndFilled
                    }
                    id={`${numberFormat.fieldName}-${id}`}
                    label={numberFormat.fieldName}
                    defaultValue={DEFAULT_VALUE}
                    variant="outlined"
                    onChange={validate}
                    size="small"
                    type="number"
                />
            )}
        </Box>
    );
};

export default NumberInput;
