/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsnType, MessageFormat } from "../MessageForm/MessageForm";
import NumberInput from "../NumberInput/NumberInput";
import StringInput from "../StringInput/StringInput";
import ObjectInput from "../ObjectInput/ObjectInput";
import ArrayInput from "../ArrayInput/ArrayInput";
import ChoiceInput from "../ChoiceInput/ChoiceInput";
import BooleanInput from "../BooleanInput/BooleanInput";
import NullInput from "../NullInput/NullInput";

type AnyTypeInputProps = {
  type: AsnType;
  value: any;
  onChange: (instance: any | undefined) => void;
};

const AnyTypeInput = ({ type, value, onChange }: AnyTypeInputProps) => {
  switch (type[0]) {
    case "array":
      return (
          <ArrayInput
            arrayFormat={value as MessageFormat<object>}
            onChange={onChange}
          />
      );
    case "object":
      return (
          <ObjectInput
            objectFormat={value as MessageFormat<object>}
            onChange={onChange}
          />
      );
    case "number":
      return (
          <NumberInput
            numberFormat={value as MessageFormat<number | undefined>}
            onChange={onChange}
          />
      );
    case "boolean":
      return (
          <BooleanInput
            booleanFormat={value as MessageFormat<boolean | undefined>}
            onChange={onChange}
          />
      );
    case "null":
      return (
          <NullInput
            nullFormat={value as MessageFormat<null | undefined>}
            onChange={onChange}
          />
      );
    case "string":
    case "hexstring":
    case "bitstring":
      return (
          <StringInput
            stringType={type[0]}
            stringFormat={value as MessageFormat<string | undefined>}
            onChange={onChange}
          />
      );
    case "choice":
      return (
          <ChoiceInput
            choiceFormat={value as MessageFormat<object>}
            onChange={onChange}
          />
      );
  }
};

export default AnyTypeInput;
