import { useState } from "react";
import ReactJson from "@microlink/react-json-view";
import { Box, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MessageTable from "../table/MessageTable/MessageTable";
import { decode, EtsiJson } from "@consider-it/etsi-web";
import { boxContainer, closeIconBtn, jsonTreeView } from "./etsiViewStyles";
import { Buffer } from "buffer/";
import { RowData } from "../../../types/MessageType";

const EtsiView = () => {
    const [error, setError] = useState<string>();
    const [selectedMessage, setSelectedMessage] = useState<object>();

    const handleRowClick = (data: RowData) => {
        try {
            const json: EtsiJson = decode(data.payload, true);
            setSelectedMessage({
                geonetworking: JSON.parse(json.geonetworking ?? "null"),
                transport: JSON.parse(json.transport ?? "null"),
                its: JSON.parse(json.its ?? "null"),
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.log({ // Log unparsable messages to console for easy debugging
                ...data,
                error: e.toString(),
                hex: Buffer.from(data.payload),
                rsVec: data.payload.reduce((acc, curr) => acc += (curr.toString() + ", "), "vec![\n") + "\n]",
            });
            setError(e);
        }
    };

    return (
        <>
            <Box sx={boxContainer}>
                {selectedMessage !== undefined && (
                    <>
                        <IconButton
                            sx={closeIconBtn}
                            onClick={() => setSelectedMessage(undefined)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <ReactJson
                            src={selectedMessage}
                            name="JSON View"
                            style={jsonTreeView}
                            displayDataTypes={false}
                        />
                    </>
                )}
                <MessageTable onRowClick={handleRowClick} />
            </Box>
            <Snackbar
                open={!!error}
                autoHideDuration={4000}
                onClose={() => setError(undefined)}
                color="error"
                message={error?.substring(0, 500)}
            />
        </>
    );
};

export default EtsiView;
