import { SxProps } from "@mui/system";

export const indentation = (indent: number): SxProps => ({
    mx: `${indent * 8}px`,
    my: `${indent * 4}px`,
    "& p:first-of-type": {
        fontWeight: "bold",
    },
    padding: "8px"
});

export const flexAlignCenter: SxProps = {
    display: "flex",
    alignItems: "center",
};