import { SyntheticEvent, useContext, useState } from "react";
import { Alert, Box, Fab, Snackbar, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FileInput from "../FileInput/FileInput";
import { MessageType } from "../../../types/MessageType";
import SendIcon from "@mui/icons-material/Send";
import { AmqpContext } from "../../../context/AmqpContext";
import { Buffer } from "buffer/";
import RawMessageInput from "../MessageInput/RawMessageInput";
import { boxWrapper, fabSend, fabSendIcon } from "./sendDataStyles";
import MessageForm, { MessageFormat } from "../../forms/MessageForm/MessageForm";
import { DENM, IVIM, MAPEM, SPATEM, SSEM, SREM, CAM } from "../../forms/ItsMessageFormats";
import {
    EtsiJson,
    encodeCam,
    encodeDenm,
    encodeIvim,
    encodeMapem,
    encodeSpatem,
    encodeSrem,
} from "@consider-it/etsi-web";

type ItsMessage = {
    type: MessageType;
    body: EtsiJson;
};

type MessageFormPanel = {
    type: MessageType;
    format: MessageFormat<object>;
};

const messageFormPanels: MessageFormPanel[] = [
    { type: MessageType.DENM, format: DENM },
    { type: MessageType.CAM, format: CAM },
    { type: MessageType.MAPEM, format: MAPEM },
    { type: MessageType.SPATEM, format: SPATEM },
    { type: MessageType.IVIM, format: IVIM },
    { type: MessageType.SSEM, format: SSEM },
    { type: MessageType.SREM, format: SREM },
];

const SendData = () => {
    const [msgType, setMsgType] = useState(MessageType.RAW);
    const [itsMsg, setItsMsg] = useState<ItsMessage | string | undefined>();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const { client } = useContext(AmqpContext);

    const handleRawSend = (rawHex: string): void => {
        if (!/^[0-9a-fA-F]*$/.test(rawHex)) {
            return;
        } else if (rawHex.length % 2 !== 0) {
            setError("Hex input must have an even length.");
            setItsMsg(undefined);
            return;
        }

        setError("");
        rawHex &&
            client
                ?.send(Buffer.from(rawHex, "hex"))
                .then(() => setSuccess(true))
                .catch(e => setError(e));
        setItsMsg(undefined);
    };

    const handleMsgSend = (msg: ItsMessage): void => {
        try {
            let binary: Uint8Array;
            switch (msg.type) {
                case MessageType.CAM:
                    binary = encodeCam(msg.body, 141);
                    break;
                case MessageType.DENM:
                    binary = encodeDenm(msg.body, 131);
                    break;
                case MessageType.IVIM:
                    binary = encodeIvim(msg.body, 221);
                    break;
                case MessageType.MAPEM:
                    binary = encodeMapem(msg.body, 131);
                    break;
                case MessageType.SPATEM:
                    binary = encodeSpatem(msg.body, 131);
                    break;
                case MessageType.SREM:
                    binary = encodeSrem(msg.body, 131);
                    break;
                case MessageType.SSEM:
                    binary = encodeSrem(msg.body, 131);
                    break;
                default:
                    return;
            }
            client
                ?.send(Buffer.from(binary))
                .then(() => setSuccess(true))
                .catch(e => setError(e));
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            console.error(e); // log also to console for better tracing
            setError(e.toString());
        }
    };

    const handleSend = (): void => {
        switch (typeof itsMsg) {
            case "string":
                handleRawSend(itsMsg);
                break;
            case "object":
                handleMsgSend(itsMsg);
                break;
            default:
                break;
        }
    };

    const updateItsMsg =
        (type: MessageType) =>
        (update: EtsiJson): void => {
            if (update === undefined) {
                setItsMsg(undefined);
            } else {
                setItsMsg({
                    type,
                    body: update,
                });
            }
        };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSnackbarClose = (_: any, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccess(false);
        setError("");
    };

    return (
        <>
            <Box sx={boxWrapper}>
                <TabContext value={msgType}>
                    <TabList onChange={(_: SyntheticEvent<Element, Event>, v: MessageType) => setMsgType(v)} centered>
                        {messageFormPanels.map((msg, i) => (
                            <Tab key={i} value={msg.type} label={msg.type} />
                        ))}
                        <Tab value={MessageType.RAW} label={MessageType.RAW} />
                    </TabList>

                    <TabPanel value={MessageType.RAW}>
                        <RawMessageInput
                            error={error}
                            data={typeof itsMsg === "string" ? itsMsg : ""}
                            setData={setItsMsg}
                        />
                        <FileInput setData={setItsMsg} />
                    </TabPanel>
                    {messageFormPanels.map((msg, i) => (
                        <TabPanel key={i} value={msg.type}>
                            <MessageForm messageFormat={msg.format} onChange={updateItsMsg(msg.type)} />
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
            <Fab sx={fabSend} color="primary" variant="extended" onClick={handleSend}>
                <SendIcon sx={fabSendIcon} />
                Send
            </Fab>
            <Snackbar open={success || error.length !== 0} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: "100%" }}>
                    {success ? "AMQP message sent!" : error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default SendData;
