import { Box } from "@mui/material";
import ObjectInput from "../ObjectInput/ObjectInput";
import { wrapperBox } from "./messageFormStyles";
import { BasicTransportHeader, GeonetworkingHeader } from "../HeaderMessageFormats";
import { useEffect, useState } from "react";
import { EtsiJson } from "@consider-it/etsi-web";

export type MessageFormat<T> = {
    value: T;
    fieldName: string;
    optional?: boolean;
    defaultValue?: T;
    ty: AsnType;
    constraints?: { min: number; max: number };
};

export type AsnType =
    | ["null", null]
    | ["boolean", null]
    | ["number", null]
    | ["string", null]
    | ["bitstring", null]
    | ["hexstring", null]
    | ["object", null]
    | ["choice", null]
    | ["array", AsnType];

type MessageFormProps = {
    messageFormat: MessageFormat<object>;
    onChange: (message: EtsiJson) => void;
};

const MessageForm = ({ messageFormat, onChange }: MessageFormProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [geonetworking, setGeonetworking] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [transport, setTransport] = useState<any>();
    const [its, setIts] = useState<object>();

    useEffect(() => {
        const itsJson = its ? JSON.stringify(its) : undefined;
        const geonetworkingJson = formatGeonetworking({ ...geonetworking });
        const transportJson = transport?.btp ? JSON.stringify(Object.values(transport.btp).pop()) : undefined;
        onChange(new EtsiJson(itsJson, geonetworkingJson, transportJson));
    }, [its, geonetworking, transport]);

    return (
        <Box component="form" noValidate autoComplete="off" sx={wrapperBox}>
            <ObjectInput objectFormat={GeonetworkingHeader} onChange={setGeonetworking} indent={4} />
            <ObjectInput objectFormat={BasicTransportHeader} onChange={setTransport} indent={4} />
            <ObjectInput objectFormat={messageFormat} onChange={setIts} indent={4} />
        </Box>
    );
};

export default MessageForm;

// Formats the geonetworking object for use with the 'geonetworking'
// library, sets default values and stringifies the geonetworking JSON object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatGeonetworking(geonetworking: any): string | undefined {
    if (geonetworking === undefined) {
        return undefined;
    }
    if (geonetworking?.common !== undefined && geonetworking?.basic !== undefined) {
        replaceBitstrings(geonetworking);
        geonetworking.common.payload_length = 0;
        geonetworking.common.header_type_and_subtype = {
            GeoAnycast: "Circular",
        };
        geonetworking.basic.next_header = "CommonHeader";
        if (typeof geonetworking.common.next_header === "number") {
            geonetworking.common.next_header = geonetworking.common.next_header === 1 ? "BTPA" : "BTPB";
        }
        return JSON.stringify(geonetworking);
    }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function replaceBitstrings(input: { [key: string]: any }) {
    Object.entries(input).forEach(([k, v]) => {
        if (typeof v === "object") {
            replaceBitstrings(v);
        } else if (typeof v === "string" && /^[01]*$/.test(v)) {
            input[k] = [...Array(v.length).keys()].map((_, i) => v[i] !== "0");
        }
    });
}
