import { SxProps } from "@mui/material";

export const wrapperBox: SxProps = {
    width: "100%",
};

export const tableContainer: React.CSSProperties = {
    maxWidth: "60%",
    height: "75vh",
    margin: "0 auto",
    overflow: "auto",
};