import { CRoadsBIMessage } from "../services/AmqpClient";

export enum MessageType {
    DENM = "DENM",
    CAM = "CAM",
    POI = "POI",
    IVIM = "IVIM",
    MAPEM = "MAPEM",
    SPATEM = "SPATEM",
    SSEM = "SSEM",
    SREM = "SREM",
    RAW = "Raw Hex",
}

export enum FilterOption {
    CONTAINS = "Contains",
    EQUALS = "Equals",
    STARTS_WITH = "Starts with",
    ENDS_WITH = "Ends with",
    IS_EMPTY = "Is empty",
    IS_NOT_EMPTY = "Is not empty"
}

export enum LogicalOperations {
    AND = "AND",
    OR = "OR"
}

export type RowData = CRoadsBIMessage & {
    msgId: number;
    timestamp: string;
    length: number;
    pinned: boolean;
};

export type TableColumn = {
    label: string;
    key: keyof RowData;
    isNumeric: boolean;
};

export type FilterCondition = {
    column: string;
    option: FilterOption | string;
    text: string;
    logicalOperator: LogicalOperations;
};