import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export const tableRow: SxProps = {
    cursor: "pointer",
    whiteSpace: "nowrap",
};

export const tableCellPin: SxProps = {
    ...tableRow,
    textAlign: "right",
};

export const pinIconBtn: SxProps<Theme> = {
    color: (theme: Theme) => theme.palette.primary.main,

};
