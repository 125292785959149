/* eslint-disable @typescript-eslint/no-explicit-any */
export const uploadJsonFile = async (
    arrayState: any[] | undefined,
    setArrayState: React.Dispatch<React.SetStateAction<any[] | undefined>>,
    setUploadErr: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".json";
        fileInput.addEventListener("change", (e) => {
            handleFileSelect(e as any, arrayState, setArrayState, setUploadErr);
        });
        fileInput.click();
        setUploadErr(false);
    } catch (error) {
        setUploadErr(true);
    }
};

const handleFileSelect = async (
    e: React.ChangeEvent<HTMLInputElement>,
    arrayState: any[] | undefined,
    setArrayState: React.Dispatch<React.SetStateAction<any[] | undefined>>,
    setUploadErr: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        const file = e.target.files?.[0];
        if (file) {
            const content = await readFileContent(file);
            const importedArray = JSON.parse(content);
            setArrayState([...(arrayState ?? []), ...importedArray]);
        }
        setUploadErr(false);
    } catch (error) {
        setUploadErr(true);
    }
};

const readFileContent = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target) {
                resolve(e.target.result as string);
            } else {
                reject(new Error("Unable to read file content"));
            }
        };
        reader.readAsText(file);
    });
};
