import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { useEffect, useId, useState } from "react";
import {
    flexAlignCenter,
} from "../ObjectInput/objectInputStyles";
import OptionalCheckbox from "../OptionalCheckbox/OptionalCheckbox";

type BooleanInputProps = {
    booleanFormat: MessageFormat<boolean | undefined>;
    onChange: (instance: boolean | undefined) => void;
};

const BooleanInput = ({ booleanFormat, onChange }: BooleanInputProps) => {
    const id = useId();
    const [isChecked, setIsChecked] = useState(
        booleanFormat.defaultValue === true
    );
    const [isOptionalAndFilled, setIsOptionalAndFilled] = useState(
        booleanFormat.optional !== true
    );

    useEffect(() => {
        if (!isOptionalAndFilled) {
            onChange(booleanFormat.defaultValue);
        }
    }, [isOptionalAndFilled]);

    const handleUpdate = (value: boolean): void => {
        setIsChecked(value);
        onChange(value);
    };

    return (
        <Box sx={flexAlignCenter}>
            <OptionalCheckbox value={isOptionalAndFilled} onUpdate={setIsOptionalAndFilled} format={booleanFormat} />
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={`${booleanFormat.fieldName}-${id}`}
                            checked={isChecked}
                            onChange={(_, v) => handleUpdate(v)}
                        />
                    }
                    label={booleanFormat.fieldName}
                />
            </FormGroup>
        </Box>
    );
};

export default BooleanInput;
