import { useState } from "react";
import { Box, AppBar, Toolbar, Tabs, Tab, useTheme, Typography } from "@mui/material";
import EtsiView from "../../components/receive/EtsiView/EtsiView";
import SendData from "../../components/send/SendData/SendData";
import Login from "../../components/auth/Login/Login";
import { AmqpContext } from "../../context/AmqpContext";
import { AmqpClient } from "../../services/AmqpClient";
import { clientBox, clientTabs, croadsLogo, navbarLabel, navbarWrapper } from "./clientStyles";
import { RowData } from "../../types/MessageType";

const Client = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [client, setClient] = useState<AmqpClient>();
    const [rows, setRows] = useState<RowData[]>([]);
    const [pinned, setPinned] = useState<RowData[]>([]);
    const theme = useTheme();

    return (
        <Box sx={clientBox}>
            <AppBar position="static" sx={{ background: theme.palette.paper }}>
                <Toolbar variant="dense" sx={navbarWrapper}>
                    <Box
                        component="img"
                        sx={croadsLogo}
                        alt="C-Roads Logo"
                        src="/assets/c-roads-logo.svg"
                    />
                    <Typography variant="h4" sx={navbarLabel}>C-ITS Broker Austria</Typography>
                </Toolbar>
            </AppBar>

            <Tabs
                value={selectedTab}
                sx={clientTabs}
                centered
                onChange={(_, t: number) => setSelectedTab(t)}
            >
                <Tab label={"Send Data"} />
                <Tab label={"Receive Data"} />
            </Tabs>
            <AmqpContext.Provider value={{ client, setClient, rows, setRows, pinned, setPinned }}>
                {selectedTab === 0 ? <SendData /> : <EtsiView />}
                <Login />
            </AmqpContext.Provider>
        </Box>
    );
};

export default Client;