import { Fragment } from "react";
import { TABLE_COLUMNS } from "../constants/tableConstants";
import { RowData } from "../../../../types/MessageType";
import { IconButton, TableCell } from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import { pinIconBtn, tableCellPin, tableRow } from "./tableRowStyles";

const TableRow = (
    onPinned: (row: RowData) => void,
    onRowClick: (raw: RowData) => void
) => (_index: number, data: RowData) => {
    return (
        <Fragment key={_index}>
            <TableCell key="rowNum" sx={tableRow}>
                {data.msgId}
            </TableCell>
            <TableCell key="pinned" sx={tableCellPin}>
                <IconButton sx={pinIconBtn} onClick={_ => onPinned(data)}>
                    <PushPinIcon />
                </IconButton>
            </TableCell>
            {TABLE_COLUMNS?.map(column => (
                <TableCell
                    onClick={_ => onRowClick(data)}
                    key={column.key}
                    sx={tableRow}
                >
                    {data[column.key] ?? "-"}
                </TableCell>
            ))}
        </Fragment>
    );
};

export default TableRow;