import { Box, Paper, Typography } from "@mui/material";
import { MessageFormat } from "../MessageForm/MessageForm";
import { useEffect, useState } from "react";
import NumberInput from "../NumberInput/NumberInput";
import StringInput from "../StringInput/StringInput";
import ArrayInput from "../ArrayInput/ArrayInput";
import ChoiceInput from "../ChoiceInput/ChoiceInput";
import { indentation, flexAlignCenter } from "./objectInputStyles";
import BooleanInput from "../BooleanInput/BooleanInput";
import NullInput from "../NullInput/NullInput";
import OptionalCheckbox from "../OptionalCheckbox/OptionalCheckbox";

type ObjectInputProps = {
    objectFormat: MessageFormat<object>;
    onChange: (instance: object | undefined) => void;
    indent?: number;
};

const ObjectInput = ({
    objectFormat,
    onChange,
    indent = 0,
}: ObjectInputProps) => {
    const [lastUpdate, setLastUpdate] = useState<string>("");
    const [objectState, setObjectState] = useState<object | undefined>();

    useEffect(() => {
        const jsonState = JSON.stringify(objectState);
        if (jsonState !== lastUpdate) {
            onChange(objectState);
            setLastUpdate(jsonState);
        }
    }, [objectState, onChange]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateField = (key: string) => (val: any) => {
        if (objectState === undefined) {
            setObjectState({ [key]: val });
        } else if (
            objectState[key as keyof object] === undefined ||
            objectState[key as keyof object] !== val
        ) {
            setObjectState({ ...objectState, [key]: val });
        }
    };

    return (
        <Box sx={indentation(indent)} component={Paper} >
            <Box sx={flexAlignCenter}>
                <Typography>{objectFormat.fieldName}</Typography>
                <OptionalCheckbox
                    value={objectState !== undefined}
                    onUpdate={(v) => {setObjectState(v ? {} : undefined)}}
                    format={objectFormat}
                />
            </Box>
            {(objectFormat.optional !== true || objectState !== undefined) &&
                Object.entries(objectFormat.value).map(([key, value]) => {
                    const commonProps = {
                        key,
                        onChange: updateField(key),
                        indent: indent + 1,
                    };

                    switch (value.ty[0]) {
                        case "object":
                            return (
                                <ObjectInput
                                    objectFormat={
                                        value as MessageFormat<object>
                                    }
                                    {...commonProps}
                                />
                            );
                        case "array":
                            return (
                                <ArrayInput
                                    arrayFormat={value as MessageFormat<object>}
                                    {...commonProps}
                                />
                            );
                        case "choice":
                            return (
                                <ChoiceInput
                                    choiceFormat={
                                        value as MessageFormat<object>
                                    }
                                    {...commonProps}
                                />
                            );
                        case "number":
                            return (
                                <NumberInput
                                    numberFormat={
                                        value as MessageFormat<
                                            number | undefined
                                        >
                                    }
                                    {...commonProps}
                                />
                            );
                        case "boolean":
                            return (
                                <BooleanInput
                                    booleanFormat={
                                        value as MessageFormat<
                                            boolean | undefined
                                        >
                                    }
                                    {...commonProps}
                                />
                            );
                        case "null":
                            return (
                                <NullInput
                                    nullFormat={
                                        value as MessageFormat<null | undefined>
                                    }
                                    {...commonProps}
                                />
                            );
                        case "string":
                        case "hexstring":
                        case "bitstring":
                            return (
                                <StringInput
                                    stringType={value.ty}
                                    stringFormat={
                                        value as MessageFormat<
                                            string | undefined
                                        >
                                    }
                                    {...commonProps}
                                />
                            );
                        default:
                            return <div />;
                    }
                })}
        </Box>
    );
};

export default ObjectInput;
