import { TableCell, TableRow as MuiTableRow, IconButton, Typography, Tooltip } from "@mui/material";
import { TABLE_COLUMNS } from "../constants/tableConstants";
import { RowData } from "../../../../types/MessageType";
import PushPinIcon from "@mui/icons-material/PushPinOutlined";
import { tableHeader, pinnedRow, unpinnedRow, pinIconBtn } from "./tableHeaderStyles";
import { cRoadsDescriptions } from "../../../../types/cRoadsDescription";

const TableHeader = (
    pinned: RowData[],
    handlePinClick: (pinned: RowData) => void,
    onRowClick: (data: RowData) => void,
) => () => {
    return (
        <>
            <MuiTableRow>
                <TableCell sx={tableHeader} />
                <TableCell sx={tableHeader} />
                {TABLE_COLUMNS?.map(column => (
                    <TableCell key={column.key} variant="head" sx={tableHeader}>
                        <Tooltip
                            arrow
                            placement="top"
                            title={
                                <Typography fontSize={14}>
                                    {cRoadsDescriptions[column.key]}
                                </Typography>
                            }
                        >
                            <Typography>{column.label}</Typography>
                        </Tooltip>
                    </TableCell>
                )
                )}
            </MuiTableRow>
            {pinned.map(row => (
                <MuiTableRow onClick={_ => onRowClick(row)} key={row.msgId}>
                    <TableCell key="rowNum" sx={pinnedRow}>
                        {row.msgId}
                    </TableCell>
                    <TableCell key="pinned" sx={unpinnedRow}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePinClick(row);
                            }}
                            sx={pinIconBtn}
                        >
                            <PushPinIcon />
                        </IconButton>
                    </TableCell>
                    {TABLE_COLUMNS?.map(column => (
                        <TableCell key={column.key} sx={pinnedRow}>
                            {row[column.key] ?? "-"}
                        </TableCell>
                    ))}
                </MuiTableRow>
            ))}
        </>
    );
};

export default TableHeader;