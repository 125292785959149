import React from "react";
import { Typography } from "@mui/material";
import StringInput from "../../forms/StringInput/StringInput";
import { errorTypography } from "./rawMessageInputStyles";

type MessageInputProps = {
    error: string;
    data: string;
    setData: (data: string) => void;
};

const RawMessageInput = ({ error, data, setData }: MessageInputProps) => {
    return (
        <>
            <StringInput
                stringType={"hexstring"}
                stringFormat={{ value: "hexstring", fieldName: "Raw Message Input", ty: ["hexstring", null], constraints: { min: 0, max: Number.MAX_VALUE } }}
                onChange={e => e && setData(e)}
                multiline
                value={data}
            />
            {error &&
                <Typography sx={errorTypography}>
                    {error}
                </Typography>
            }
        </>
    );

};

export default RawMessageInput;